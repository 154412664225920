import _ from "lodash";
import React, { PureComponent } from "react";
import CreateCustomerOrder from "./CreateCustomerOrder";
import Tooltip from "../../common/Tooltip";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import CommodityPriceInformation from "./CommodityPriceInformation";
import { formatCurrency, formatUnit } from "../../../utils/baseUtils";
import {
  getAvailableAndIncomingStock,
  getAvailableStock,
  getCustomerOrderCalculation,
  getDeliveryTimeLabel,
  getEarliestDeliveryDate,
  getIncomingOrderableStock,
  getIncomingStockDeliveryDate,
} from "../../../utils/customerOrderUtils";
import { CO_TRANSPORT, T_AIRFREIGHT, T_SEAFREIGHT, T_WAREHOUSE } from "../../../model/customerOrder.types";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import { CustomerPriceInfo } from "../../../model/commonTypes";
import { CustomerArticleExtended, formatArticleUnit, isAnyFinishedProduct } from "../../../utils/productArticleUtils";

interface CommodityPageSummaryProps {
  article: CustomerArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageSummaryState {
  prices: {
    [T_SEAFREIGHT]: CustomerPriceInfo | null;
    [T_AIRFREIGHT]: CustomerPriceInfo | null;
    [T_WAREHOUSE]: CustomerPriceInfo | null;
  };
  deliveryDates: {
    [T_SEAFREIGHT]: Date | null;
    [T_AIRFREIGHT]: Date | null;
    [T_WAREHOUSE]: Date | null;
  };
  step: number;
  loading: boolean;
  stockAmount: number | null;
  incoming: { amount: number; date: Date } | null;
}

class CommodityPageSummary extends PureComponent<CommodityPageSummaryProps, CommodityPageSummaryState> {
  constructor(props: CommodityPageSummaryProps) {
    super(props);
    const steps = this.getOrderVolumeSteps();
    this.state = {
      step: steps[5],
      prices: { [T_SEAFREIGHT]: null, [T_AIRFREIGHT]: null, [T_WAREHOUSE]: null },
      deliveryDates: { [T_SEAFREIGHT]: null, [T_AIRFREIGHT]: null, [T_WAREHOUSE]: null },
      loading: true,
      stockAmount: null,
      incoming: null,
    };
  }

  async componentDidMount() {
    const { article, context } = this.props;
    const { step } = this.state;
    const prices = await this.getPrices(step);
    const deliveryDates = await this.getDeliveryDates(step);
    const smallestPackagingSize = article.packagingSizes ? article.packagingSizes[0].packagingSize : 25;
    const incomingOrderableStock = await getIncomingOrderableStock(
      article._id.toString(),
      smallestPackagingSize,
      context.currencies
    );
    const stock = await getAvailableAndIncomingStock(article._id, true);
    this.setState({
      loading: false,
      prices,
      deliveryDates,
      stockAmount: stock[0],
      incoming: incomingOrderableStock
        ? {
            amount: incomingOrderableStock.availableAmount,
            date: getIncomingStockDeliveryDate(incomingOrderableStock),
          }
        : null,
    });
  }

  handleClickStep = async (step: number) => {
    this.setState({ step, loading: true });
    const prices = await this.getPrices(step);
    const deliveryDates = await this.getDeliveryDates(step);
    this.setState({ loading: false, prices, deliveryDates });
  };

  handleClickBuyNow = async () => {
    const createCustomerOrder = this.props.context.refMap["CreateCustomerOrder"] as CreateCustomerOrder | undefined;
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    if (createCustomerOrder) {
      const { step, prices } = this.state;
      await createCustomerOrder.handleBlurAmount(step, true);
      const validMethod = prices.warehouse?.unitPrice
        ? T_WAREHOUSE
        : prices.seafreight?.unitPrice
        ? T_SEAFREIGHT
        : prices.airfreight?.unitPrice
        ? T_AIRFREIGHT
        : undefined;
      // Method switch has to be 2nd step since the handleBlurAmount might trigger a transport change itself
      if (validMethod) createCustomerOrder.handleChangeMethod(validMethod);
    }
  };

  getPrices = async (step: number) => {
    const { article, context } = this.props;
    const piSea = getCustomerOrderCalculation(article, step, T_SEAFREIGHT, context.currencies, CUSTOMER_BASE_CURRENCY);
    const piAir = getCustomerOrderCalculation(article, step, T_AIRFREIGHT, context.currencies, CUSTOMER_BASE_CURRENCY);
    const piWar = getCustomerOrderCalculation(article, step, T_WAREHOUSE, context.currencies, CUSTOMER_BASE_CURRENCY);
    return { [T_SEAFREIGHT]: await piSea, [T_AIRFREIGHT]: await piAir, [T_WAREHOUSE]: await piWar };
  };

  /**
   * Retrieves the approximate delivery dates for the commodity for airfreight, seafreight and warehouse transport
   * @param step how much of the commodity would be ordered
   * @returns { Promise<{seafreight: Date, airfreight: Date, warehouse: Date | null}> } Array with order volume steps
   */
  getDeliveryDates = async (step: number): Promise<{ seafreight: Date; airfreight: Date; warehouse: Date | null }> => {
    const { article, context } = this.props;
    const stock = await getAvailableStock(article);
    const incomingOrderableStock = await getIncomingOrderableStock(article._id.toString(), step, context.currencies);
    const eddWarehouse =
      step <= stock[0] + stock[3]
        ? await getEarliestDeliveryDate(T_WAREHOUSE, undefined, article, step)
        : incomingOrderableStock
        ? getIncomingStockDeliveryDate(incomingOrderableStock)
        : null;
    const eddAir = await getEarliestDeliveryDate(T_AIRFREIGHT, undefined, article, step);
    const eddSea = await getEarliestDeliveryDate(T_SEAFREIGHT, undefined, article, step);
    return { [T_SEAFREIGHT]: eddSea, [T_AIRFREIGHT]: eddAir, [T_WAREHOUSE]: eddWarehouse };
  };

  /**
   * Retrieve an array that contains common order volume steps. Vitamins got smaller steps since they are expensive.
   * @returns { Array<number> } Array with order volume steps
   */
  getOrderVolumeSteps = (): Array<number> => {
    const { article } = this.props;
    const category = getArticleProperty(article.properties, PropertyType.CATEGORY) as Property | null;
    if (category?.name.en === "Vitamin") {
      return [1, 5, 10, 50, 250, 1000];
    } else {
      return [25, 100, 500, 1000, 2500, 10000];
    }
  };

  /**
   * Resolves the color class for the price texts.
   * @param method Determines for which method the color should be resolved
   * @returns { string } CSS class for the text
   */
  resolveTextColor = (method: typeof T_SEAFREIGHT | typeof T_AIRFREIGHT | typeof T_WAREHOUSE): string => {
    const { prices, loading } = this.state;
    if (!loading && prices[method] && prices[method]?.unitPrice && isFinite(prices[method]?.unitPrice || 0)) {
      const values = Object.values(prices).map((p) => (p?.unitPrice && isFinite(p.unitPrice) ? p.unitPrice : null));
      const min = _.min(values);
      const max = _.max(values);
      if (prices[method]?.unitPrice === min) return "text-success";
      if (prices[method]?.unitPrice === max) return "text-danger";
      return "text-warning";
    }
    return "text-muted";
  };

  getMatrixButtonBorderRadius = (idx: number) => {
    const borderRadius = ["0", "0", "0", "0"];
    const roundedRadius = "5px";
    if (idx === 0) {
      borderRadius[0] = roundedRadius;
    } else if (idx === 2) {
      borderRadius[1] = roundedRadius;
    } else if (idx === 3) {
      borderRadius[3] = roundedRadius;
    } else if (idx === 5) {
      borderRadius[2] = roundedRadius;
    }
    return borderRadius.join(" ");
  };

  render() {
    const { article } = this.props;
    const { step, prices, deliveryDates, loading, incoming, stockAmount } = this.state;
    const noPrices =
      !loading &&
      !prices[T_SEAFREIGHT]?.unitPrice &&
      !prices[T_AIRFREIGHT]?.unitPrice &&
      (!prices[T_WAREHOUSE]?.unitPrice || prices[T_WAREHOUSE]?.unitPrice === Infinity);

    const isFP = isAnyFinishedProduct(article);

    return (
      <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 mt-5 mt-xl-0 bg-white d-none d-lg-flex">
        <div className="card-body">
          <div className="text-center">
            <CommodityPriceInformation
              price={prices[T_SEAFREIGHT]?.unitPrice || 0}
              loading={loading}
              amount={formatUnit(step.toLocaleString(), article.unit)}
            />
          </div>
          {!isFP && (
            <>
              <div className="d-flex flex-stack fs-4 py-3">
                <div className="fw-bolder rotate">Price Matrix</div>
                <Tooltip
                  tooltipText="Lowest prices available. Prices may vary depending on your individual purchase quantity."
                  delay={{ show: 125, hide: 0 }}
                >
                  <span className="text-muted opacity-04 fw-bold d-block fs-7 float-right">
                    <i className="fa fa-info-circle"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="bg-light2">
                <div className="d-grid h-100" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                  {this.getOrderVolumeSteps().map((s: number, idx) => (
                    <button
                      key={s}
                      className={"btn btn-light btn-sm " + (step === s && "active")}
                      style={{ borderRadius: this.getMatrixButtonBorderRadius(idx) }}
                      onClick={() => this.handleClickStep(s)}
                    >
                      {s.toLocaleString()} {formatArticleUnit(article.unit, article)}
                    </button>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-stack fs-4 py-3 pt-10">
                <div className="fw-bolder rotate">Price Details</div>
                <Tooltip
                  tooltipText="Lowest prices available. Prices may vary depending on your individual purchase quantity."
                  delay={{ show: 125, hide: 0 }}
                >
                  <span className="text-muted opacity-04 fw-bold d-block fs-7 float-right">
                    <i className="fa fa-info-circle"></i>
                  </span>
                </Tooltip>
              </div>
              <div className="bg-light2 px-5">
                <div className="border-bottom-dark-gray" />
                <SummaryPriceEntry
                  method={T_SEAFREIGHT}
                  price={prices[T_SEAFREIGHT]}
                  deliveryDate={deliveryDates[T_SEAFREIGHT]}
                  loading={loading}
                  textColor={this.resolveTextColor(T_SEAFREIGHT)}
                />
                <SummaryPriceEntry
                  method={T_AIRFREIGHT}
                  price={prices[T_AIRFREIGHT]}
                  deliveryDate={deliveryDates[T_AIRFREIGHT]}
                  loading={loading}
                  textColor={this.resolveTextColor(T_AIRFREIGHT)}
                />
                <SummaryPriceEntry
                  method={T_WAREHOUSE}
                  price={prices[T_WAREHOUSE]}
                  deliveryDate={deliveryDates[T_WAREHOUSE]}
                  loading={loading}
                  textColor={this.resolveTextColor(T_WAREHOUSE)}
                />
                <div className="d-flex flex-stack my-4">
                  <div className="flex-grow-1 me-2">
                    <span className="text-gray-800 fs-6 fw-bolder">Warehouse Amount</span>
                    <span className="text-muted fw-bold d-block fs-7">
                      {!incoming && (!stockAmount || stockAmount < 0) && "Not available"}
                    </span>
                  </div>
                  <span className="ml-2 text-muted">
                    {!loading && stockAmount && stockAmount > 0
                      ? formatUnit(stockAmount, article.unit ? article.unit : "kg")
                      : "-"}
                  </span>
                </div>
                <div className="d-flex flex-stack my-4">
                  <div className="flex-grow-1 me-2">
                    <span className="text-gray-800 fs-6 fw-bolder">Incoming Amount</span>
                    <span className="text-muted fw-bold d-block fs-7">
                      {incoming && incoming.amount > 0
                        ? `Delivery in ${getDeliveryTimeLabel(incoming.date)}`
                        : "Not available"}
                    </span>
                  </div>
                  <span className="ml-2 text-muted">
                    {!loading && incoming && incoming.amount > 0
                      ? formatUnit(incoming.amount, article.unit ? article.unit : "kg")
                      : "-"}
                  </span>
                </div>
              </div>
            </>
          )}
          <button
            className={
              "btn w-100 mt-10 " +
              (loading ? "disabled btn-secondary" : noPrices ? "btn-warning text-dark" : "btn-success")
            }
            onClick={loading ? undefined : this.handleClickBuyNow}
            disabled={loading}
          >
            {loading ? "Calculating..." : isFP ? "Request Prices" : noPrices ? "Request Price Update" : "Continue"}
          </button>
        </div>
      </div>
    );
  }
}

interface SummaryPriceEntryProps {
  method: CO_TRANSPORT;
  textColor: string;
  loading: boolean;
  price: CustomerPriceInfo | null;
  deliveryDate: Date | null;
}

class SummaryPriceEntry extends PureComponent<SummaryPriceEntryProps> {
  getTitle = () => {
    const { method } = this.props;
    switch (method) {
      case T_SEAFREIGHT:
        return "Seafreight";
      case T_AIRFREIGHT:
        return "Airfreight";
      case T_WAREHOUSE:
        return "Warehouse";
      default:
        return "";
    }
  };

  render() {
    const { method, textColor, loading, price, deliveryDate } = this.props;
    const validPrice = price?.unitPrice && price.unitPrice !== 0 && isFinite(price.unitPrice);

    return (
      <div className="d-flex flex-stack my-4">
        <div className="flex-grow-1 me-2">
          <span className="text-gray-800 fs-6 fw-bolder">{this.getTitle()}</span>
          <span className="text-muted fw-bold d-block fs-7">
            {validPrice && deliveryDate ? `Delivery in ${getDeliveryTimeLabel(deliveryDate)}` : "Not available"}
          </span>
        </div>
        <span className={"ml-2 " + textColor}>
          {loading
            ? "-"
            : validPrice && deliveryDate
            ? formatCurrency(price.unitPrice, CUSTOMER_BASE_CURRENCY)
            : method === T_WAREHOUSE
            ? "Not available"
            : "Price outdated"}
        </span>
      </div>
    );
  }
}

export default CommodityPageSummary;
