import _ from "lodash";
import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "./CustomSelect";
import { DataContextAnonymousType, DataContextCustomerType, DataContextInternalType } from "../../context/dataContext";
import { getDocFromCollection } from "../../utils/baseUtils";
import userService from "../../services/userService";
import { Company } from "../../model/company.types";
import { AddressSelectOption, formatAddress } from "../../utils/addressUtils";

interface OwnAddressSelectorProps {
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
  onChange: (e: AddressSelectOption) => void;
  company?: Company;
  value?: SelectOption;
  placeholder?: string;
  matchFormControl?: boolean;
  additionalClasses?: string;
  isClearable?: boolean;
  disabled?: boolean;
}

interface OwnAddressSelectorState {
  company?: Company;
}

class OwnAddressSelector extends PureComponent<OwnAddressSelectorProps, OwnAddressSelectorState> {
  constructor(props: OwnAddressSelectorProps) {
    super(props);
    this.state = { company: this.getCompany(props) };
  }

  componentDidUpdate(prevProps: Readonly<OwnAddressSelectorProps>) {
    if (
      !_.isEqual(prevProps.context.company, this.props.context.company) ||
      !_.isEqual(prevProps.company, this.props.company)
    ) {
      this.setState({ company: this.getCompany(this.props) });
    }
  }

  getCompany = (props: OwnAddressSelectorProps) => {
    return props.company ?? getDocFromCollection(props.context.company, userService.getCompany() || "");
  };

  render() {
    const { onChange, value, isClearable, disabled, placeholder, matchFormControl, additionalClasses } = this.props;
    const { company } = this.state;
    return (
      <CustomSelect
        value={value}
        isClearable={isClearable}
        disabled={disabled}
        options={
          company?.address.map((a) => {
            return { label: formatAddress(a, { withoutBreak: true }), value: formatAddress(a), address: a };
          }) ?? []
        }
        matchFormControl={matchFormControl}
        onChange={onChange}
        placeholder={placeholder ?? "Address..."}
        additionalClasses={additionalClasses}
      />
    );
  }
}

export default OwnAddressSelector;
