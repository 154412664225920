import React, { useCallback, useMemo } from "react";
import { DataContextCustomerType } from "../../context/dataContext";
import { DateType } from "../../utils/orderUtils";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { Supplier } from "../../model/supplier.types";
import { AddressSelectOption } from "../../utils/addressUtils";
import { TRANSPORT_SELECT, TransportSelectOption } from "../../utils/logisticsUtils";
import { validateAmount } from "../../utils/customerOrderUtils";
import { Input } from "../common/Input";
import { formatArticleUnit } from "../../utils/productArticleUtils";
import CalendarWeekSelector from "../common/CalendarWeekSelector";
import DateInput from "../common/DateInput";
import { T_SEAFREIGHT, T_WAREHOUSE } from "../../model/customerOrder.types";
import OwnAddressSelector from "../common/OwnAddressSelector";
import { CreateCustomerOrderSupplierSelection } from "../commodities/customer/CreateCustomerOrderSettings";
import { formatCurrency } from "../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../utils/currencyUtils";
import { GlobalShoppingCartInfo, ShoppingCartOrderInfo } from "./ShoppingCartCheckout";

interface ShoppingCartCheckoutCardProps {
  context: DataContextCustomerType;
  globalInfo: GlobalShoppingCartInfo;
  priceLoading: boolean;
  orderInfo: ShoppingCartOrderInfo;
  onChangeAmount: (e: React.ChangeEvent<HTMLInputElement>, orderInfoId: string) => Promise<void>;
  onChangeTargetDate: (e: React.ChangeEvent<HTMLInputElement> | Date, orderInfoId: string, resetStep?: boolean) => void;
  onChangeTargetDateType: (newType: DateType, orderInfoId: string) => void;
  onSelectSupplier: (e: SelectOption<Supplier>, orderInfoId: string) => void;
  onChangeAddress: (e: AddressSelectOption, orderInfoId: string) => void;
  onChangeMethod: (method: TransportSelectOption, orderInfoId: string) => void;
  onChangeRef: (e: React.ChangeEvent<HTMLInputElement>, orderInfoId: string) => void;
  onRemoveFromShoppingCart: (orderInfo: string) => void;
}

const ShoppingCartCheckoutCard: React.FunctionComponent<ShoppingCartCheckoutCardProps> = (props) => {
  const {
    context,
    globalInfo,
    priceLoading,
    orderInfo,
    onChangeAmount,
    onChangeTargetDate,
    onChangeTargetDateType,
    onChangeMethod,
    onChangeAddress,
    onSelectSupplier,
    onChangeRef,
    onRemoveFromShoppingCart,
  } = props;

  const {
    _id,
    article,
    amount,
    method,
    priceInfo,
    earliestDeliveryDate,
    targetDate,
    targetDateType,
    incomingOrderableStock,
    availableStock,
    shippingAddress,
    reference,
    supplier,
  } = orderInfo;

  const suitableAmount = useMemo(() => {
    return validateAmount(article, amount, method.value);
  }, [article, amount, method]);

  const handleSelectSupplier = useCallback(
    (e: SelectOption<Supplier>) => {
      onSelectSupplier(e, _id.toString());
    },
    [onSelectSupplier, _id]
  );

  return (
    <tr className="text-white">
      <td className="align-content-start align-middle">
        <button id="trashButton" className="btn m-0 pl-0" onClick={() => onRemoveFromShoppingCart(_id.toString())}>
          <i className="fa fa-trash" />
        </button>
      </td>
      <td className="align-middle">
        <a className="text-white mb-2" href={`/commodity/${article._id.toString()}`}>
          {article.title.en} {article.subtitle.en ? <span className="text-muted">- {article.subtitle.en}`</span> : ""}
        </a>
      </td>
      <td>
        <Input
          type="text"
          className="form-control custom-form-control"
          placeholder="Your reference..."
          disabled={Boolean(globalInfo.globalRef)}
          name="reference"
          value={globalInfo.globalRef ?? reference}
          onBlur={(e) => onChangeRef(e, _id.toString())}
        />
        <div className="input-group mt-2">
          <Input
            className="form-control custom-form-control"
            type="number"
            name="amount"
            min={0}
            value={amount}
            onBlur={(e) => onChangeAmount(e, _id.toString())}
          />
          <div className="input-group-append rounded-end bg-custom-light-gray">
            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
              {formatArticleUnit(article.unit)}
            </div>
          </div>
        </div>
        <div className="text-danger float-right " style={{ height: "1rem" }}>
          {suitableAmount && `Recommended amount: ${suitableAmount.upper}${formatArticleUnit(article.unit)}`}
        </div>
      </td>
      <td>
        <div>
          {targetDateType === DateType.CW ? (
            <CalendarWeekSelector
              inputClassNames={targetDate < earliestDeliveryDate ? "custom-form-control-invalid" : ""}
              value={targetDate}
              min={new Date()}
              onSelectCalendarWeek={(e) => onChangeTargetDate(e, _id.toString())}
            />
          ) : (
            <DateInput
              classes={
                "form-control custom-form-control " +
                ([0, 6].includes(targetDate.getDay()) || targetDate < earliestDeliveryDate
                  ? "custom-form-control-invalid"
                  : "")
              }
              value={targetDate}
              onBlur={(e) => onChangeTargetDate(e, _id.toString())}
              name={"targetDate"}
              min={new Date()}
            />
          )}
        </div>
        <div className="text-danger float-right mb-2" style={{ height: "1rem" }}>
          {[0, 6].includes(targetDate.getDay())
            ? "Weekends can't be selected as target date"
            : targetDate < earliestDeliveryDate && `Earliest arrival on ${earliestDeliveryDate.toLocaleDateString()}`}
        </div>
        <div
          className={
            "form-check form-check-sm form-check-custom form-check-solid " +
            ([0, 6].includes(targetDate.getDay()) || targetDate < earliestDeliveryDate ? "mt-12" : "")
          }
        >
          <input
            className="form-check-input position-static"
            checked={targetDateType === DateType.CW}
            type="checkbox"
            onChange={() => onChangeTargetDateType(DateType.CW, _id.toString())}
          />
          <label className="form-check-label fs-6">Calendar Week</label>
        </div>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            className="form-check-input position-static"
            checked={targetDateType === DateType.FIX}
            disabled={method.value === T_SEAFREIGHT}
            type="checkbox"
            onChange={() => onChangeTargetDateType(DateType.FIX, _id.toString())}
          />
          <label className={`form-check-label fs-6 ${method.value === T_SEAFREIGHT ? "text-muted" : ""}`}>
            Fixed Date
          </label>
        </div>
      </td>
      <td>
        <OwnAddressSelector
          context={context}
          onChange={(e) => onChangeAddress(e, _id.toString())}
          value={globalInfo.globalAddress ?? shippingAddress}
          disabled={Boolean(globalInfo.globalAddress)}
          matchFormControl={true}
          placeholder="Shipping Address..."
        />
      </td>
      <td>
        <CreateCustomerOrderSupplierSelection
          amount={amount}
          article={article}
          context={context}
          supplier={supplier}
          labelDesign={null}
          onSelectSupplier={handleSelectSupplier}
          method={method.value}
          forShoppingCart={true}
        />
      </td>
      <td>
        <CustomSelect options={TRANSPORT_SELECT} value={method} onChange={(e) => onChangeMethod(e, _id.toString())} />
        {method.value === T_WAREHOUSE && availableStock > 0 && (
          <div>{`${availableStock} ${formatArticleUnit(article.unit)} on stock`}</div>
        )}
        {method.value === T_WAREHOUSE &&
          (!availableStock || amount > availableStock) &&
          incomingOrderableStock &&
          `${incomingOrderableStock.availableAmount} ${formatArticleUnit(article.unit)} available in ${
            incomingOrderableStock.inWeeks
          } week(s)`}
        {method.value === T_WAREHOUSE && !availableStock && !incomingOrderableStock && (
          <div className="text-danger float-right mb-2" style={{ height: "1rem" }}>
            {`Method warehouse not available.`}
          </div>
        )}
      </td>
      <td>
        {priceLoading ? (
          "Loading"
        ) : method.value === T_WAREHOUSE &&
          (!availableStock || availableStock < amount) &&
          incomingOrderableStock &&
          incomingOrderableStock.availableAmount > 0 ? (
          <div>
            {incomingOrderableStock.availableAmount >= amount
              ? formatCurrency(incomingOrderableStock.price, incomingOrderableStock.currency)
              : "-"}{" "}
            per {formatArticleUnit(article.unit)}
            <br />
            {incomingOrderableStock.availableAmount >= amount
              ? formatCurrency(incomingOrderableStock.price * amount, CUSTOMER_BASE_CURRENCY)
              : "-"}{" "}
            in total
          </div>
        ) : (
          <div>
            {priceInfo && !priceInfo.isFallback && priceInfo.unitPrice > 0
              ? formatCurrency(priceInfo.unitPrice, CUSTOMER_BASE_CURRENCY)
              : "-"}{" "}
            per {formatArticleUnit(article.unit)}
            <br />
            {priceInfo && !priceInfo.isFallback && priceInfo.totalPrice > 0
              ? formatCurrency(priceInfo.totalPrice, CUSTOMER_BASE_CURRENCY)
              : "-"}{" "}
            in total
            {method.value !== T_WAREHOUSE && (priceInfo?.isFallback || supplier?.expired) ? (
              <>
                <br />
                <span className="text-danger">Price is outdated!</span>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default ShoppingCartCheckoutCard;
