import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import userService from "../../services/userService";
import { ADMIN, ANONYMOUS, CUSTOMER, INTERNAL, SCM, SUPPLIER } from "../../utils/userUtils";
import {
  DataContextType,
  isAnonymousContext,
  isCustomerContext,
  isInternalContext,
  isSupplierContext,
} from "../../context/dataContext";
import CreateCustomerAccountModal from "../userData/anonymous/CreateCustomerAccountModal";
import PriceTicker from "./headerContent/PriceTicker";
import SearchBar from "./SearchBar";
import AsideDropdown from "../common/AsideDropdown";
import Tooltip from "../common/Tooltip";
import { formatArticleUnit } from "../../utils/productArticleUtils";
import { getShoppingCart, ShoppingCart, ShoppingCartEntry } from "../../utils/shoppingCartUtils";

interface HeaderProps extends RouteComponentProps {
  context: DataContextType;
  view: typeof INTERNAL | typeof CUSTOMER | typeof SUPPLIER | typeof ANONYMOUS;
  onChangeToggleView?: () => void;
}

interface HeaderState {
  seconds: number;
  badgeCount: number;
  shoppingCart: ShoppingCart;
  isMobileAsideActive: boolean;
}

class Header extends PureComponent<HeaderProps, HeaderState> {
  cartUpdateTimer: NodeJS.Timeout | null;
  constructor(props: HeaderProps) {
    super(props);
    const shoppingCartInfo = getShoppingCart();
    const shoppingCart = shoppingCartInfo ? shoppingCartInfo : { articlesToOrder: [] };
    this.cartUpdateTimer = null;
    this.state = {
      seconds: 3,
      badgeCount: shoppingCart.articlesToOrder.length,
      shoppingCart,
      isMobileAsideActive: false,
    };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState({ seconds: this.state.seconds - 1 });
      } else clearInterval(interval);
    }, 1000);
    this.cartUpdateTimer = setInterval(() => {
      // here we do not convert everything in the function to get a correct shopping cart since we only need number of articles
      const shoppingCartString = window.localStorage.getItem("shoppingCart");
      let shoppingCartInfo;
      if (shoppingCartString) {
        shoppingCartInfo = JSON.parse(shoppingCartString) as ShoppingCart;
      }
      const shoppingCart = shoppingCartInfo ? shoppingCartInfo : { articlesToOrder: [] };
      if (shoppingCart.articlesToOrder.length !== this.state.shoppingCart.articlesToOrder.length) {
        this.setState({ badgeCount: shoppingCart.articlesToOrder.length });
      }
    }, 2000);
  }

  componentWillUnmount() {
    if (this.cartUpdateTimer) {
      clearInterval(this.cartUpdateTimer);
      this.cartUpdateTimer = null;
    }
  }

  updateShoppingCartOnHover = () => {
    const shoppingCartInfo = getShoppingCart();
    const shoppingCart = shoppingCartInfo ? shoppingCartInfo : { articlesToOrder: [] };
    if (shoppingCart.articlesToOrder.length !== this.state.shoppingCart.articlesToOrder.length) {
      this.setState({ shoppingCart, badgeCount: shoppingCart.articlesToOrder.length });
    }
  };

  render() {
    const { history, context, view, onChangeToggleView } = this.props;
    const { seconds, shoppingCart, badgeCount } = this.state;
    const roles = userService.getRoles();
    const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);
    const disabled = !userService.getUserData()?.onboardingDone || userService.getUserType() === SUPPLIER;
    const isSC = isSupplierContext(context);
    return (
      <>
        <div className="indeterminate-progress-bar" style={{ opacity: seconds ? 1 : 0 }}>
          {seconds && <div className="indeterminate-progress-bar__progress" />}
        </div>
        <div className="header price-ticker align-items-stretch" style={{ height: isSC ? 0 : 40 }}>
          {!isSC && <PriceTicker context={context} />}
        </div>
        <div
          className="header"
          style={{ animationDuration: "0.3s", height: 75, boxShadow: "none", marginTop: isSC ? 0 : 40 }}
        >
          <div className="container header-container">
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3 d-none d-lg-block" />
            <div className="d-flex align-items-center justify-content-between flex-wrap mobile-general-header">
              <AsideDropdown view={view} />
              <Link to={isInternalContext(context) && context.currentView === SCM ? "/scm" : "/dashboard"}>
                <div className="logo h-40px h-lg-40px w-150px w-lg-150px" />
              </Link>
              <SearchBar isForMobileUse={false} />
              <button
                className={"btn btn-outline btn-outline-light d-none d-lg-block " + (disabled ? " disabled" : "")}
                disabled={disabled}
                onClick={disabled ? undefined : () => history.push("/orders")}
              >
                Orders
              </button>
              {isAnonymousContext(context) && <CreateCustomerAccountModal context={context} />}
              {isSCMOrAdmin && (
                <div className="d-flex d-none d-lg-block" style={{ minWidth: "120px" }}>
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input position-static"
                      type="checkbox"
                      checked={isInternalContext(context) && context.currentView === INTERNAL}
                      onChange={onChangeToggleView}
                    />
                    <span className="form-check-label fw-bold text-muted">
                      {isInternalContext(context) && context.currentView === INTERNAL ? "Internal" : "SCM"}
                    </span>
                  </label>
                </div>
              )}
              {isCustomerContext(context) && (
                <Tooltip
                  placement="bottom-end"
                  tooltipText={
                    <>
                      {shoppingCart.articlesToOrder.map((ato: ShoppingCartEntry) => {
                        return (
                          <div className="card bg-white mx-auto mt-1" key={ato._id.toString()}>
                            <div className="card-body">
                              <div className="text-white">
                                {ato.article.title.en}
                                <br />
                                Amount: {ato.amount} {formatArticleUnit(ato.article.unit)}
                                <br />
                                Order Reference: {ato.reference ?? "-"}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  }
                  show={badgeCount ? undefined : false}
                >
                  <div className="d-none">
                    {/** TODO: RB-912 Reactivate after checkup with Julian **/}
                    <Link className="menu-item py-2" to="/shoppingCart" onMouseEnter={this.updateShoppingCartOnHover}>
                      <span className="menu-link menu-center ">
                        <span
                          className="menu-icon svg-icon justify-content-center me-0 py-2"
                          style={{ position: "relative" }}
                        >
                          {badgeCount !== undefined && (
                            <span
                              className="badge badge-circle badge-light-dark badge-sm fw-boldest"
                              style={{ position: "absolute", top: -5, right: -15 }}
                            >
                              {badgeCount}
                            </span>
                          )}
                          <span className="svg-icon svg-icon-primary svg-icon-2x ">
                            <i className="fas fa-shopping-cart text-muted" style={{ fontSize: "2rem" }} />
                          </span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </Tooltip>
              )}
            </div>
            <SearchBar isForMobileUse={true} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
