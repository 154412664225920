import _ from "lodash";
import React, { PureComponent, useMemo } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { pluralize, round, toAbsoluteUrl, truncateString } from "../../../utils/baseUtils";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { TrendData } from "../../../model/commonTypes";
import { getCommoditiesStatistics } from "../../../utils/commodityUtils";
import { ArticleType } from "../../../model/article.types";
import StringTruncateOverlay from "../../common/StringTruncateOverlay";

interface CustomerDashboardPriceTrendsProps {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerDashboardPriceTrendsState {
  trendData: Array<TrendData>;
  amountChanges: number;
  showMore: boolean;
  loading: boolean;
}

class CustomerDashboardPriceTrends extends PureComponent<
  CustomerDashboardPriceTrendsProps,
  CustomerDashboardPriceTrendsState
> {
  constructor(props: CustomerDashboardPriceTrendsProps) {
    super(props);
    this.state = {
      trendData: [],
      amountChanges: 0,
      showMore: false,
      loading: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { trendData, amountChanges } = await this.prepareData();
    this.setState({ trendData, amountChanges, loading: false });
  }

  async componentDidUpdate(prevProps: Readonly<CustomerDashboardPriceTrendsProps>) {
    if (!_.isEqual(prevProps.context.commodity, this.props.context.commodity)) {
      const { trendData, amountChanges } = await this.prepareData();
      this.setState({ trendData, amountChanges });
    }
  }

  handleToggleShowMore = () => this.setState({ showMore: !this.state.showMore });

  prepareData = async () => {
    const { commodity, property } = this.props.context;
    const categories = property.filter((p) => p.type === PropertyType.CATEGORY);
    const categoryMap: { [key: string]: Array<string> } = {};
    // Collect all categories
    for (let i = 0; i < categories.length; i++) {
      const c = categories[i];
      categoryMap[c._id.toString()] = [];
    }
    const relevantCommodities: Array<string> = [];
    // Sort commodities by categories
    for (let i = 0; i < commodity.length; i++) {
      const c = commodity[i];
      // Customer show not see those but a little sanity is fine
      if (!c.approved || c.disabled) continue;
      const properties = resolveProperties(c.properties, this.props.context.property);
      const cat = properties.find((p) => p.type === PropertyType.CATEGORY);
      if (!cat) continue;
      relevantCommodities.push(c._id.toString());
      categoryMap[cat._id.toString()].push(c._id.toString());
    }
    const trendData: Array<TrendData> = [];
    let amountChanges = 0;
    const totalStatistics = await getCommoditiesStatistics(relevantCommodities, ["priceDevelopment", "commodity"]);
    // Get the data for the commodities
    for (const key in categoryMap) {
      const data = categoryMap[key];
      const statistics = totalStatistics?.filter((s) => s.commodity && data.includes(s.commodity));
      if (!statistics || statistics.length === 0) continue;
      let amount = statistics.length;
      const change =
        statistics.reduce((sum, s) => {
          if (s.priceDevelopment) {
            return s.priceDevelopment.percentage + sum;
          }
          amount--;
          return sum;
        }, 0) / amount;
      if (!isNaN(change)) {
        amountChanges += amount;
        const trendDataEntry: TrendData = { category: key, change };
        trendData.push(trendDataEntry);
      }
    }
    return { trendData: trendData.sort((t1, t2) => Math.abs(t2.change) - Math.abs(t1.change)), amountChanges };
  };

  getMarketReports = () => {
    const { context } = this.props;
    const marketReports = context.news.filter((n) => n.type === ArticleType.MARKETREPORT);
    return marketReports.sort((m1, m2) => m2.creationTime.getTime() - m1.creationTime.getTime());
  };

  /**
   * Shortens the title of the market report based upon current innerWidth.
   * @param title Title that should be shortened
   * @returns {string} Shortened title
   */
  shortenMarketReportTitle = (title: string): string => {
    const { innerWidth } = this.props.context;
    if (innerWidth > 2200) return title;
    if (innerWidth > 2100) return truncateString(title, 80);
    if (innerWidth > 2000) return truncateString(title, 65);
    if (innerWidth > 1900) return truncateString(title, 60);
    if (innerWidth > 1800) return truncateString(title, 55);
    if (innerWidth > 1700) return truncateString(title, 45);
    return truncateString(title, 35);
  };

  render() {
    const { context } = this.props;
    const { trendData, loading, amountChanges, showMore } = this.state;

    const trendDataList = showMore ? trendData : trendData.slice(0, 6);
    const marketReports = this.getMarketReports();

    return (
      <div className="card h-100 bg-white scroll-y">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Price Trends</span>
            <span className="text-muted fw-bold fs-7">
              {loading ? "Loading..." : pluralize(amountChanges, "Change") + " within 30 days"}
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 mt-5">
          {!loading && (
            <>
              <div className="row">
                {trendDataList.map((t) => (
                  <CustomerDashboardPriceTrendEntry key={t.category} context={context} trendData={t} />
                ))}
                <div className="col-12 px-1">
                  <button type="button" className="btn btn-light w-100 btn-sm mt-2" onClick={this.handleToggleShowMore}>
                    {showMore ? "Show Less" : "Show More"}
                  </button>
                </div>
              </div>
              <h3 className="card-title align-items-start flex-column mt-8">
                <span className="card-label fw-bolder fs-3 mb-1">Market Reports</span>
              </h3>
              <div className="row mt-5">
                {marketReports.length > 0 ? (
                  marketReports.map((m) => (
                    <Link
                      key={m._id.toString()}
                      to={`/article/${m._id.toString()}`}
                      className="cursor-pointer col-12 p-1"
                    >
                      <div className="p-5 py-3 mb-2 bg-light">
                        <div className="d-flex align-items-center">
                          <div className="fs-1 mr-4">
                            <span className="svg-icon svg-icon-1 svg-icon-danger">
                              <SVG src={toAbsoluteUrl("/assets/media/svg/custom/arrow-no.svg")} />
                            </span>
                          </div>
                          <div className="flex-grow-1">
                            <div className="d-flex justify-content-between">
                              <div className="text-dark fw-bolder fs-6">{this.shortenMarketReportTitle(m.title)}</div>
                              <div className="text-muted fw-normal text-right" style={{ minWidth: "85px" }}>
                                <small>
                                  {m.creationTime.toLocaleDateString("en-GB", { month: "long", year: "numeric" })}
                                </small>
                              </div>
                            </div>
                            <span className="text-muted d-block fw-bold">{m.newsText.slice(0, 130) + "..."}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className="p-7 text-center text-muted h6">No market reports found.</div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

interface CustomerDashboardPriceTrendEntryProps {
  context: DataContextCustomerType | DataContextAnonymousType;
  trendData: TrendData;
}

const CustomerDashboardPriceTrendEntry: React.FC<CustomerDashboardPriceTrendEntryProps> = ({ context, trendData }) => {
  const { property } = context;
  const change = trendData.change;
  const category = useMemo(() => property.find((p) => p._id.toString() === trendData.category), [property, trendData]);
  if (!category) return null;
  return (
    <Link to={"/articles/" + category._id.toString()} className="col-4 col-xl-6 col-xxl-4 p-1">
      <div
        className="card bg-light"
        style={{ height: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <div>
          <div className="text-center">
            <span className="text-gray-800 fw-bolder fs-6">
              <StringTruncateOverlay text={category.name.en} length={20} tooltipId={category._id.toString()} />
            </span>
          </div>
          <div className="text-center fs-6 fw-boldest">
            <div className="d-flex flex-center">
              <span
                className={
                  "svg-icon svg-icon-2 " +
                  (change > 0 ? "svg-icon-danger" : change < 0 ? "svg-icon-success" : "svg-icon-muted")
                }
              >
                <SVG
                  src={toAbsoluteUrl(
                    "/assets/media/svg/custom/" +
                      (change > 0 ? "arrow-no.svg" : change < 0 ? "arrow-so.svg" : "arrow-e.svg")
                  )}
                />
              </span>
              <span className={change > 0 ? "text-danger" : change < 0 ? "text-success" : "text-muted"}>
                {change > 0 && "+"}
                {round(change, 2)} %
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CustomerDashboardPriceTrends;
