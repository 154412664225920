import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import userService from "../../services/userService";

export enum ToolTypes {
  COMMODITY_PARSING_TOOL = "commodityParsingTool",
  SUPPLIER_PARSING_TOOL = "supplierParsingTool",
  CUSTOMER_PARSING_TOOL = "customerParsingTool",
  SEAPORT_PARSING_TOOL = "seaportParsingTool",
  MASTERSPEC_GENERATION_TOOL = "masterSpecGenerationTool",
  PRICE_CALCULATOR = "priceCalculator",
  CUSTOMER_MAILS_TOOL = "customerMailsTool",
}

interface ToolProps extends RouteComponentProps {
  type: ToolTypes;
  disabled?: boolean;
  requiredRoles?: Array<string>;
}

class Tool extends PureComponent<ToolProps> {
  resolveTitle = () => {
    switch (this.props.type) {
      case ToolTypes.COMMODITY_PARSING_TOOL:
        return "Commodity Parsing Tool";
      case ToolTypes.CUSTOMER_PARSING_TOOL:
        return "Customer Parsing Tool";
      case ToolTypes.SUPPLIER_PARSING_TOOL:
        return "Supplier Parsing Tool";
      case ToolTypes.SEAPORT_PARSING_TOOL:
        return "Seaport Parsing Tool";
      case ToolTypes.MASTERSPEC_GENERATION_TOOL:
        return "Master Specification Generation Tool";
      case ToolTypes.PRICE_CALCULATOR:
        return "Price Calculator";
      case ToolTypes.CUSTOMER_MAILS_TOOL:
        return "Customer Mails";
    }
  };

  resolveDescriptions = () => {
    switch (this.props.type) {
      case ToolTypes.COMMODITY_PARSING_TOOL:
        return "Parses a CSV that contains and adds all commodities that can be parsed correctly to the database.";
      case ToolTypes.CUSTOMER_PARSING_TOOL:
        return "Parses a CSV that contains and adds all customers that can be parsed correctly to the database.";
      case ToolTypes.SUPPLIER_PARSING_TOOL:
        return "Parses a CSV that contains and adds all suppliers that can be parsed correctly to the database.";
      case ToolTypes.SEAPORT_PARSING_TOOL:
        return "Parses a CSV that contains and adds all seaports that can be parsed correctly to the database.";
      case ToolTypes.MASTERSPEC_GENERATION_TOOL:
        return "Generates the master specification with signatures of originator and approver for all commodities.";
      case ToolTypes.PRICE_CALCULATOR:
        return "Calculate the DDP price based on the FOB price or the FOB price based on the DDP price.";
      case ToolTypes.CUSTOMER_MAILS_TOOL:
        return "Get a string with all customer mails separated by comma";
    }
  };

  render() {
    const { history, type, disabled, requiredRoles } = this.props;
    const toolDisabled = disabled || (requiredRoles && !userService.getRoles().some((r) => requiredRoles.includes(r)));

    return (
      <div className="card card-view card-container">
        <div className="card-body">
          <h1>{this.resolveTitle()}</h1>
          <p className="fs-5 text-white">{this.resolveDescriptions()}</p>
          <ErrorOverlayButton
            errors={toolDisabled ? ["Additional privileges required."] : []}
            className={"btn btn-outline btn-outline-light float-right"}
            buttonText={"Start"}
            onClick={() => history.push("/" + type)}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Tool);
