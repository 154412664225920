import React, { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CustomerCommodity } from "../../../model/customer/customerCommodity.types";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import CountryWidget from "../../common/CountryWidget";
import FavoriteStar from "../../common/FavoriteStar";
import { formatCurrency } from "../../../utils/baseUtils";
import Tooltip from "../../common/Tooltip";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import AddToShoppingCartModal from "../../shoppingCart/modals/AddToShoppingCartModal";

interface CustomerCommodityListingRowProps extends RouteComponentProps {
  commodity: CustomerCommodity;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CustomerCommodityListingRow: React.FunctionComponent<CustomerCommodityListingRowProps> = ({
  commodity,
  context,
  history,
}) => {
  const properties = useMemo(() => {
    return resolveProperties(commodity.properties, context.property);
  }, [commodity.properties, context.property]);
  const category = getArticleProperty(properties, PropertyType.CATEGORY) as Property | null;
  const forwardCommodity = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`/commodity/${commodity._id.toString()}`);
  };

  const isAC = isAnonymousContext(context);
  const blurPrice = isAC && !hasPricesInAnonymousView(commodity._id.toString(), context.configuration);

  return (
    <tr
      onClick={forwardCommodity}
      className="cursor-pointer-row border-bottom-dark-gray"
      style={{ borderWidth: "20px", opacity: commodity.fromPrice ? 1 : 0.5 }}
    >
      <td className="align-middle">
        {!isAC && (
          <Tooltip tooltipText="Mark as Favorite" delay={{ show: 500, hide: 0 }}>
            <span>
              <FavoriteStar favorite={commodity._id.toString()} context={context} />
            </span>
          </Tooltip>
        )}
      </td>
      <td className="align-middle">
        <span className="text-white fs-5 d-inline-block">{commodity.title.en}</span>
        {commodity.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {commodity.subtitle.en}
        </span>
      </td>
      <td className="align-middle">
        {category && <span className="badge badge-gray fw-bolder px-2 py-2 text-white">{category.name.en}</span>}
      </td>
      <td className="align-middle text-white">
        {commodity.casNumber.some((c) => c.trim().length > 0) ? commodity.casNumber : "Not Applicable"}
      </td>
      <td className="align-middle text-white text-center">
        <CountryWidget countryCode={commodity.country.code} />
      </td>
      <td className="align-middle text-center">
        <span className="fs-5 text-white" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
          {blurPrice ? (
            <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
          ) : commodity.fromPrice?.seafreight.price && commodity.fromPrice?.outdated ? (
            <Tooltip tooltipText="Price outdated">
              <span className="text-muted">
                {formatCurrency(commodity.fromPrice?.seafreight.price, commodity.fromPrice?.seafreight.currency)}
              </span>
            </Tooltip>
          ) : commodity.fromPrice?.seafreight.price ? (
            formatCurrency(commodity.fromPrice?.seafreight.price, commodity.fromPrice?.seafreight.currency)
          ) : (
            <p className="text-muted fs-7 mb-0">On Request</p>
          )}
        </span>
        <small className="text-muted d-block">Seafreight</small>
      </td>
      <td className="align-middle text-center">
        <span className="fs-5 text-white" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
          {blurPrice ? (
            <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
          ) : commodity.fromPrice?.airfreight.price && commodity.fromPrice?.outdated ? (
            <Tooltip tooltipText="Price outdated">
              <span className="text-muted">
                {formatCurrency(commodity.fromPrice?.airfreight.price, commodity.fromPrice?.airfreight.currency)}
              </span>
            </Tooltip>
          ) : commodity.fromPrice?.airfreight.price ? (
            formatCurrency(commodity.fromPrice?.airfreight.price, commodity.fromPrice?.airfreight.currency)
          ) : (
            <p className="text-muted fs-7 mb-0">On Request</p>
          )}
        </span>
        <small className="text-muted d-block">Airfreight</small>
      </td>
      <td className="align-middle text-center">
        <span className="fs-5 text-white" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
          {blurPrice ? (
            <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
          ) : commodity.fromPrice?.warehouse.price && commodity.fromPrice?.outdated ? (
            <Tooltip tooltipText="Price outdated">
              <span className="text-muted">
                {formatCurrency(commodity.fromPrice?.warehouse.price, commodity.fromPrice?.warehouse.currency)}
              </span>
            </Tooltip>
          ) : commodity.fromPrice?.warehouse.price ? (
            formatCurrency(commodity.fromPrice?.warehouse.price, commodity.fromPrice?.warehouse.currency)
          ) : (
            <p className="text-muted fs-7 mb-0">Not Available</p>
          )}
        </span>
        <small className="text-muted d-block">Warehouse</small>
      </td>
      {/** <td onClick={(e) => e.stopPropagation()}>
        <AddToShoppingCartModal article={commodity} />
      </td> TODO: RB-912 Reactivate **/}
    </tr>
  );
};

export default withRouter(CustomerCommodityListingRow);
