import React, { useCallback, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isAnonymousContext,
} from "../../../context/dataContext";
import { I_CREDITNOTECUSTOMER, I_CREDITNOTESAMPLE, Invoice } from "../../../model/invoice.types";
import { formatCurrency, formatDate, round, toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { I_STATEDESCRIPTION } from "../../../utils/invoiceUtils";
import BaseListing from "../../common/BaseListing";
import userService from "../../../services/userService";
import { INTERNAL } from "../../../utils/userUtils";
import { EXTENDED_ORDER_TYPES, getOrderNumber, isSupplierOrder } from "../../../utils/orderUtils";
import { isCustomerContract } from "../../../utils/customerContractUtils";
import { EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";
import { CO_CANCELED } from "../../../model/customerOrder.types";
import Tooltip from "../../common/Tooltip";
import {
  SO_T_MARKED_AS_PAID,
  SO_T_MARKED_AS_PARTIALLY_PAID,
  SupplierOrderExtended,
} from "../../../model/supplierOrder.types";
import { SUPPLIERORDER, transaction, UpdateAction } from "../../../services/dbService";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { getSupplierOrderTimelineEntry } from "../../../utils/supplierOrderUtils";
import { Input } from "../../common/Input";
import { Currencies } from "../../../utils/currencyUtils";

interface OrderInvoicesProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

const OrderInvoices: React.FC<OrderInvoicesProps> = ({ order, context }) => {
  const headerDefinition = useMemo(
    () => [
      { title: "Invoice", style: { width: "30%" } },
      { title: "Customer", style: { width: "20%" } },
      { title: "Date", style: { width: "15%" } },
      { title: "Payment", style: { width: "10%" } },
      { title: "State", style: { width: "10%" } },
      { title: "File", className: "text-right", style: { width: "15%" } },
    ],
    []
  );

  const invoices = useMemo(() => {
    if (isAnonymousContext(context)) return [];
    return context.invoice.filter(
      (i) =>
        i.relatedOrder &&
        ![I_CREDITNOTESAMPLE, I_CREDITNOTECUSTOMER].includes(i.type) &&
        (i.relatedOrder === order._id.toString() ||
          (isCustomerContract(order) && order.calls.some((c) => c._id.toString() === i.relatedOrder)))
    );
  }, [order, context.invoice]);

  const view = useMemo(() => userService.getUserType(), []);

  return (
    <div className="card bg-white">
      <div className="card-header border-0 mt-5">
        <div className="card-title flex-column">
          <h2 className="mb-1">Invoices</h2>
        </div>
        {view === INTERNAL && order.state !== CO_CANCELED && (
          <div className="card-toolbar">
            <Link to={"/createInvoice/" + order._id.toString()}>
              <button type="button" className="btn btn-outline btn-outline-light btn-sm ml-2 mr-3">
                Create Invoice
              </button>
            </Link>
            <Link to={"/createCreditNote/" + order._id.toString()}>
              <button type="button" className="btn btn-outline btn-outline-light btn-sm">
                Create Credit Note
              </button>
            </Link>
          </div>
        )}
      </div>
      <div className="card-body p-9 pt-0">
        <div className="table-responsive mt-5 pt-2">
          <BaseListing
            headerDefinition={headerDefinition}
            bodyContent={
              <>
                {invoices.length > 0 ? (
                  invoices.map((i) => <OrderInvoicesRow key={i._id.toString()} invoice={i} />)
                ) : (
                  <tr>
                    <td className="text-center" colSpan={6}>
                      <span className="fw-bold text-white">No invoices available</span>
                    </td>
                  </tr>
                )}
              </>
            }
          />
        </div>
      </div>
      {isSupplierOrder(order) && (
        <div className="card-footer text-right border-0">
          {!order.paymentInformation?.paid && (
            <MarkAsPartiallyPaidModal order={order} currencies={context.currencies} />
          )}
          <MarkAsPaidButton order={order} currencies={context.currencies} />
        </div>
      )}
    </div>
  );
};

interface OrderInvoicesRowProps {
  invoice: Invoice;
}

const OrderInvoicesRow: React.FC<OrderInvoicesRowProps> = ({ invoice }) => {
  const stateDescriptionLabel = useMemo(() => {
    const stateDescription = I_STATEDESCRIPTION.find((s) => s.value === invoice.state);
    if (stateDescription) return stateDescription.label;
    return "Unknown";
  }, [invoice]);

  return (
    <tr>
      <td className="align-middle">
        <Tooltip
          tooltipText={
            "Sent to customer on " +
            (invoice.sentToCustomer
              ? invoice.sentToCustomer
                  .map((stc) => {
                    return formatDate(stc);
                  })
                  .join(", ")
              : "")
          }
          show={invoice.sentToCustomer ? undefined : false}
        >
          <div className="fw-bold text-white">INV-{invoice.invoiceNumber}</div>
        </Tooltip>
        <div className="text-muted">{formatCurrency(invoice.total, invoice.currency)}</div>
      </td>
      <td className="align-middle">
        <span className="fw-bold text-white">{invoice.company.name}</span>
      </td>
      <td className="align-middle">
        <span className="fw-bold text-success">{formatDate(invoice.invoiceDate)}</span>
      </td>
      <td className="align-middle">
        <span className="fw-bold text-white">
          {invoice.paymentTarget === -1 ? "In Advance" : invoice.paymentTarget + " days"}
        </span>
      </td>
      <td className="align-middle">
        <span className="fw-bold text-white">{stateDescriptionLabel}</span>
      </td>
      <td className="align-middle text-right">
        <a href={resolveFilePath(invoice.file)} target="_blank" rel="noopener noreferrer">
          <img alt="pdf" className="w-30px me-3" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
        </a>
      </td>
    </tr>
  );
};

interface MarkAsPartiallyPaidModalProps {
  order: SupplierOrderExtended;
  currencies: Currencies;
}

const MarkAsPartiallyPaidModal: React.FC<MarkAsPartiallyPaidModalProps> = ({ order, currencies }) => {
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(order.paymentInformation?.partiallyPaidAmount ?? order.totalPrice);

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  const handleChangeAmount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(round(Number(e.target.value), 2));
  }, []);

  const handleMarkAsPartiallyPaid = useCallback(async () => {
    try {
      setSaving(true);
      const action: UpdateAction = { collection: SUPPLIERORDER, filter: { _id: order._id } };
      if (round(amount, 2) === round(order.totalPrice, 2)) {
        const exchangeRates = { ...order.exchangeRates };
        for (const eR in exchangeRates) {
          exchangeRates[eR] = currencies[eR];
        }
        action.update = { "paymentInformation.paid": true, exchangeRates };
        action.unset = { "paymentInformation.partiallyPaidAmount": "" };
        action.push = { timeline: getSupplierOrderTimelineEntry(SO_T_MARKED_AS_PAID) };
      } else {
        action.update = { "paymentInformation.partiallyPaidAmount": amount };
        action.push = { timeline: getSupplierOrderTimelineEntry(SO_T_MARKED_AS_PARTIALLY_PAID) };
      }
      const res = await transaction(action);
      if (res) {
        toast.success("Successfully marked as partially paid");
        handleHide();
      } else {
        toast.error("Error marking as partially paid");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error marking as partially paid");
    } finally {
      setSaving(false);
    }
  }, [order, amount, currencies]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    // Add a minor set-off the ensure that rounding issue pose no threat
    if (amount > order.totalPrice + 0.01) errors.push("Entered amount exceeds the total price of the order");
    if (amount <= 0) errors.push("Amount has to be positive");
    return errors;
  }, [order, amount]);

  return (
    <>
      <button className="btn btn-outline btn-outline-light btn-sm mr-2" onClick={handleShow}>
        Mark as partial Paid
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Mark {getOrderNumber(order)} as partially paid
            </h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className=" fs-6 mb-5">Add the amount that was paid to the supplier</div>
            </div>
            <div className="col-12 mt-5">
              <label className="fs-5 fw-bold mb-2">Amount</label>
              <div className="input-group">
                <Input
                  className="form-control custom-form-control"
                  type="number"
                  value={amount}
                  onChange={handleChangeAmount}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {order.currency}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-light" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={errors}
            saving={saving}
            className="btn btn-sm btn-light"
            buttonText="Confirm"
            onClick={handleMarkAsPartiallyPaid}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface MarkAsPaidButtonProps {
  order: SupplierOrderExtended;
  currencies: Currencies;
}

const MarkAsPaidButton: React.FC<MarkAsPaidButtonProps> = ({ order, currencies }) => {
  const [saving, setSaving] = useState<boolean>(false);

  const handleMarkAsPaid = useCallback(async () => {
    try {
      setSaving(true);
      const exchangeRates = { ...order.exchangeRates };
      for (const eR in exchangeRates) {
        exchangeRates[eR] = currencies[eR];
      }
      const action: UpdateAction = {
        collection: SUPPLIERORDER,
        filter: { _id: order._id },
        update: { "paymentInformation.paid": true, exchangeRates },
        push: { timeline: getSupplierOrderTimelineEntry(SO_T_MARKED_AS_PAID) },
      };
      const res = await transaction(action);
      if (res) {
        toast.success("Order successfully marked as paid");
      } else {
        toast.error("Error marking order as paid");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error marking order as paid");
    } finally {
      setSaving(false);
    }
  }, [order, currencies]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (order.paymentInformation?.paid) errors.push("Order is already marked as paid");
    return errors;
  }, [order]);

  return (
    <ErrorOverlayButton
      className="btn btn-outline btn-outline-light btn-sm"
      errors={errors}
      saving={saving}
      onClick={handleMarkAsPaid}
    >
      Mark as Paid
    </ErrorOverlayButton>
  );
};

export default OrderInvoices;
