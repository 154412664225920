import _ from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { DataContextCustomerType } from "../../context/dataContext";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { ArticleExtended, CustomerArticleExtended, getArticleSnapshot } from "../../utils/productArticleUtils";
import { AddressSelectOption, formatAddress } from "../../utils/addressUtils";
import { SelectOption } from "../common/CustomSelect";
import { Supplier } from "../../model/supplier.types";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_REQUESTEDSTOCK,
  CO_T_CREATED,
  CO_T_REQUESTEDSTOCK,
  CO_TRANSPORT,
  CO_TYPES,
  T_AIRFREIGHT,
  T_SEAFREIGHT,
  T_WAREHOUSE,
} from "../../model/customerOrder.types";
import { CustomerPriceInfo } from "../../model/commonTypes";
import {
  AlternativePrice,
  CO_REQUESTCONFIRMATION,
  getAlternativePrices,
  getAvailableStock,
  getCustomerOrderCalculation,
  getCustomerOrderTimelineEntry,
  getEarliestDeliveryDateAsWeekday,
  getIncomingOrderableStock,
  getPaymentTermsFromCompany,
  PLACEMULTIPLECUSTOMERORDERS,
  sendSlackNotification,
  validateAmount,
} from "../../utils/customerOrderUtils";
import { AIR_MAX_ORDER_QUANTITY, DateType, SEA_MIN_ORDER_QUANTITY } from "../../utils/orderUtils";
import { MatchingIncomingOrderableStock } from "../common/CustomTypes";
import {
  callPushToArray,
  formatCurrency,
  formatUnit,
  getDocFromCollection,
  getNumericValue,
} from "../../utils/baseUtils";
import userService from "../../services/userService";
import BaseListing, { BaseListingHeaderDefinition } from "../common/BaseListing";
import { extendCustomerCommodity } from "../../utils/dataTransformationUtils";
import { CustomerCustomerOrder } from "../../model/customer/customerCustomerOrder.types";
import { CUSTOMER_BASE_CURRENCY } from "../../utils/currencyUtils";
import { CO_FOOTER_HTML, createPDF } from "../../utils/pdfUtils";
import { createRequestConfirmationHTML } from "../../utils/pdf/requestConfirmationGenerationUtils";
import { REQUESTTEXTSHORT } from "../../utils/pdf/templateUtils";
import { callFunction, CUSTOMERORDER } from "../../services/dbService";
import ListPlaceholder from "../common/ListPlaceholder";
import { TRANSPORT_SELECT, TransportSelectOption } from "../../utils/logisticsUtils";
import { getShoppingCart } from "../../utils/shoppingCartUtils";
import ShoppingCartCheckoutCard from "./ShoppingCartCheckoutCard";
import ShoppingCartCheckoutOverview from "./ShoppingCartCheckoutOverview";
import { Input } from "../common/Input";
import OwnAddressSelector from "../common/OwnAddressSelector";

enum VIEW_STEPS {
  INPUT,
  CHECKOUT_OVERVIEW,
}

export interface ShoppingCartOrderInfo {
  _id: BSON.ObjectId;
  article: ArticleExtended;
  amount: number;
  shippingAddress?: AddressSelectOption;
  supplier?: SelectOption<Supplier>;
  reference: string;
  method: TransportSelectOption;
  priceInfo: CustomerPriceInfo | null;
  alternativePrices?: Array<AlternativePrice>;
  targetDate: Date;
  targetDateType?: DateType;
  userSetTargetDate: boolean;
  availableStock: number;
  incomingOrderableStock?: MatchingIncomingOrderableStock;
  earliestDeliveryDate: Date;
}

export interface GlobalShoppingCartInfo {
  globalAddress?: AddressSelectOption;
  globalRef?: string;
}

interface ShoppingCartCheckoutProps extends RouteComponentProps {
  context: DataContextCustomerType;
}

interface ShoppingCartCheckoutState {
  step: VIEW_STEPS;
  tosAgreed: boolean;
}

const getOrderInformation = async (props: ShoppingCartCheckoutProps) => {
  const { context } = props;
  const shoppingCart = getShoppingCart();
  if (!shoppingCart) return [];
  const orderInfos: Array<ShoppingCartOrderInfo> = [];
  for (let i = 0; i < shoppingCart.articlesToOrder.length; i++) {
    const cartInfo = shoppingCart.articlesToOrder[i];
    const article = context.commodity.find((c) => c._id.toString() === cartInfo.article._id.toString());
    if (article) {
      const articleExtended = extendCustomerCommodity(article, context);
      const method = cartInfo.method ?? TRANSPORT_SELECT[0]; // Seafreight

      const priceInfo = await getCustomerOrderCalculation(
        articleExtended as CustomerArticleExtended,
        cartInfo.amount,
        method.value,
        context.currencies,
        undefined,
        undefined,
        method.value === T_WAREHOUSE || !cartInfo.supplier ? undefined : cartInfo.supplier.value,
        true
      );
      const stock = await getAvailableStock(article);
      const incomingStock = await getIncomingOrderableStock(
        article._id.toString(),
        cartInfo.amount,
        context.currencies
      );
      const earliestDeliveryDate = await getEarliestDeliveryDateAsWeekday(
        method.value,
        cartInfo.amount,
        incomingStock,
        articleExtended
      );
      const company = getDocFromCollection(context.company, userService.getCompany() || "");
      const address =
        company && company.address.length > 0 ? formatAddress(company.address[0], { withoutBreak: true }) : "";
      if (articleExtended)
        orderInfos.push({
          _id: cartInfo._id,
          article: articleExtended,
          amount: cartInfo.amount,
          method,
          shippingAddress:
            cartInfo.shippingAddress ??
            ({ label: address, value: address, address: company?.address[0] } as AddressSelectOption),
          reference: cartInfo.reference ?? "",
          priceInfo: priceInfo,
          availableStock: stock[0],
          incomingOrderableStock: incomingStock,
          targetDate: cartInfo.targetDate ? new Date(cartInfo.targetDate) : earliestDeliveryDate,
          targetDateType: cartInfo.targetDateType,
          userSetTargetDate: false,
          supplier: cartInfo.supplier,
          earliestDeliveryDate,
        });
    }
  }
  return orderInfos;
};

const getGlobalValues = () => {
  const shoppingCart = getShoppingCart();
  if (shoppingCart) {
    return {
      globalAddress: shoppingCart.globalShippingAddress,
      globalRef: shoppingCart.globalReference,
    };
  }
  return {};
};

const ShoppingCartCheckout: React.FunctionComponent<ShoppingCartCheckoutProps> = (props) => {
  const isMounted = useRef(true); // component is mounted
  const [loading, setLoading] = useState<boolean>(false);
  const [priceLoading, setPriceLoading] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [orderInformation, setOrderInformation] = useState<Array<ShoppingCartOrderInfo>>([]);
  const [globalValues, setGlobalValues] = useState<GlobalShoppingCartInfo>(getGlobalValues());
  const [state, setState] = useState<ShoppingCartCheckoutState>({ step: VIEW_STEPS.INPUT, tosAgreed: false });

  useEffect(() => {
    setLoading(true);
    getOrderInformation(props)
      .then((orderInfos) => {
        if (isMounted.current) setOrderInformation(orderInfos);
      })
      .finally(() => setLoading(false));
    return () => {
      // handle unmounted component
      isMounted.current = false;
    };
  }, []);

  const handleChangeAmount = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, orderInfoId: string) => {
      const orderInfos = _.cloneDeep(orderInformation);
      const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
      if (orderInfo) {
        const { context } = props;
        const { article, method, supplier, availableStock, userSetTargetDate, targetDate } = orderInfo;
        if (!article) return;
        const amount = Number(getNumericValue(e));
        let transport: CO_TYPES = method.value;
        setPriceLoading(true);
        try {
          orderInfo.amount = amount;
          orderInfo.priceInfo = null;
          orderInfo.incomingOrderableStock = undefined;
          if (transport === T_AIRFREIGHT && amount > AIR_MAX_ORDER_QUANTITY) transport = T_SEAFREIGHT;
          else if (transport === T_SEAFREIGHT && amount < SEA_MIN_ORDER_QUANTITY) transport = T_AIRFREIGHT;
          const invalidAmount = validateAmount(article, amount, transport);
          const priceInfo =
            !invalidAmount &&
            (await getCustomerOrderCalculation(
              article,
              amount,
              transport,
              context.currencies,
              undefined,
              undefined,
              method.value === T_WAREHOUSE || !supplier ? undefined : supplier.value
            ));

          // If no price was found, we reset the supplier
          const hasPrice = priceInfo && priceInfo.unitPrice && isFinite(priceInfo.unitPrice);
          const incomingOrderableStock =
            amount > availableStock
              ? await getIncomingOrderableStock(article._id.toString(), amount, context.currencies)
              : undefined;
          const earliestDeliveryDate = await getEarliestDeliveryDateAsWeekday(
            method.value,
            amount,
            incomingOrderableStock,
            article,
            supplier
          );
          orderInfo.priceInfo = hasPrice
            ? priceInfo
            : !invalidAmount
            ? await getCustomerOrderCalculation(
                article,
                amount,
                transport,
                context.currencies,
                undefined,
                undefined,
                method.value === T_WAREHOUSE || !supplier ? undefined : supplier.value,
                true
              )
            : null;
          orderInfo.supplier = hasPrice ? supplier : undefined;
          orderInfo.incomingOrderableStock = incomingOrderableStock;
          orderInfo.earliestDeliveryDate = earliestDeliveryDate;
          orderInfo.targetDate = userSetTargetDate ? targetDate : earliestDeliveryDate;
          orderInfo.alternativePrices =
            (!invalidAmount &&
              (await getAlternativePrices(
                article,
                amount,
                context.currencies,
                undefined,
                undefined,
                hasPrice ? supplier?.value : undefined
              ))) ||
            undefined;
          setOrderInformation(orderInfos);
          const shoppingCart = getShoppingCart();
          if (shoppingCart) {
            const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
            if (shoppingCartEntry) {
              shoppingCartEntry.amount = amount;
              shoppingCartEntry.method = method ?? TRANSPORT_SELECT[0];
              shoppingCartEntry.supplier = hasPrice ? supplier : undefined;
              shoppingCartEntry.targetDate = userSetTargetDate ? targetDate : earliestDeliveryDate;
            }
            window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
          }
        } finally {
          setPriceLoading(false);
        }
      }
    },
    [orderInformation, props.context.currencies]
  );

  const handleChangeAddress = (e: AddressSelectOption, orderInfoId: string) => {
    const orderInfos = _.clone(orderInformation);
    const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
    if (orderInfo) {
      orderInfo.shippingAddress = e;
      setOrderInformation(orderInfos);
      const shoppingCart = getShoppingCart();
      if (shoppingCart) {
        const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
        if (shoppingCartEntry) {
          shoppingCartEntry.shippingAddress = e;
        }
        window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
      }
    }
  };

  const handleChangeTargetDateType = (newType: DateType, orderInfoId: string) => {
    const orderInfos = _.clone(orderInformation);
    const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
    if (orderInfo) {
      orderInfo.targetDateType = orderInfo.targetDateType === newType ? undefined : newType;
      setOrderInformation(orderInfos);
      const shoppingCart = getShoppingCart();
      if (shoppingCart) {
        const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
        if (shoppingCartEntry) {
          shoppingCartEntry.targetDateType = orderInfo.targetDateType === newType ? undefined : newType;
        }
        window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
      }
    }
  };

  const handleChangeTargetDate = (e: React.ChangeEvent<HTMLInputElement> | Date, orderInfoId: string) => {
    const orderInfos = _.clone(orderInformation);
    const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
    if (orderInfo) {
      const targetDate = e instanceof Date ? e : new Date(e.target.value);
      if (!targetDate) return;
      targetDate.setHours(12, 0, 0, 0);
      orderInfo.targetDate = targetDate;
      orderInfo.userSetTargetDate = true;
      setOrderInformation(orderInfos);
      const shoppingCart = getShoppingCart();
      if (shoppingCart) {
        const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
        if (shoppingCartEntry) {
          shoppingCartEntry.targetDate = targetDate;
          shoppingCartEntry.userSetTargetDate = true;
        }
        window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
      }
    }
  };

  const handleSelectSupplier = async (e: SelectOption<Supplier>, orderInfoId: string) => {
    const { context } = props;
    const orderInfos = _.clone(orderInformation);
    const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
    if (orderInfo) {
      const { amount, article, method, incomingOrderableStock, targetDate, userSetTargetDate } = orderInfo;
      setPriceLoading(true);
      try {
        const earliestDeliveryDate = await getEarliestDeliveryDateAsWeekday(
          method.value as CO_TRANSPORT,
          amount,
          incomingOrderableStock,
          article,
          e
        );
        // When a supplier is selected or unselected no need to use fallback since suppliers exist then
        orderInfo.supplier = e;
        orderInfo.priceInfo = await getCustomerOrderCalculation(
          article,
          amount,
          method.value,
          context.currencies,
          undefined,
          undefined,
          e?.value
        );
        orderInfo.earliestDeliveryDate = earliestDeliveryDate;
        orderInfo.targetDate = userSetTargetDate ? targetDate : earliestDeliveryDate;
        orderInfo.alternativePrices = await getAlternativePrices(
          article,
          amount,
          context.currencies,
          undefined,
          undefined,
          e?.value
        );
        setOrderInformation(orderInfos);
        const shoppingCart = getShoppingCart();
        if (shoppingCart) {
          const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
          if (shoppingCartEntry) {
            shoppingCartEntry.supplier = e;
            shoppingCartEntry.targetDate = userSetTargetDate ? targetDate : earliestDeliveryDate;
          }
          window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
        }
      } finally {
        setPriceLoading(false);
      }
    }
  };

  const handleChangeMethod = async (method: TransportSelectOption, orderInfoId: string) => {
    const orderInfos = _.clone(orderInformation);
    const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
    if (orderInfo) {
      const { context } = props;
      const {
        amount,
        article,
        supplier,
        incomingOrderableStock,
        targetDate,
        targetDateType,
        userSetTargetDate,
        alternativePrices,
      } = orderInfo;
      if (method.value === orderInfo.method.value) return;
      const invalidAmount = validateAmount(article, amount, method.value);
      setPriceLoading(true);
      try {
        orderInfo.method = method;
        orderInfo.priceInfo = null;
        orderInfo.targetDateType =
          method.value === T_SEAFREIGHT && targetDateType === DateType.FIX ? undefined : targetDateType;
        setState((prevState) => {
          return { ...prevState, orderInfos };
        });
        const earliestDeliveryDate = await getEarliestDeliveryDateAsWeekday(
          method.value as CO_TRANSPORT,
          amount,
          incomingOrderableStock,
          article,
          method.value === T_WAREHOUSE || !supplier ? undefined : supplier
        );
        const priceInfo = invalidAmount
          ? null
          : alternativePrices?.find((price) => price.method === method.value && price.priceInfo?.unitPrice)
              ?.priceInfo ||
            (await getCustomerOrderCalculation(
              article,
              amount,
              method.value,
              context.currencies,
              undefined,
              undefined,
              method.value === T_WAREHOUSE || !supplier ? undefined : supplier.value,
              !supplier // Only allow fallback if no supplier is selected
            ));
        // In case of method switch in the meantime don't update
        if (orderInfo.method.value === method.value) {
          orderInfo.priceInfo = priceInfo;
          orderInfo.targetDate = userSetTargetDate ? targetDate : earliestDeliveryDate;
          orderInfo.earliestDeliveryDate = earliestDeliveryDate;
          setOrderInformation(orderInfos);
        }
        const shoppingCart = getShoppingCart();
        if (shoppingCart) {
          const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
          if (shoppingCartEntry) {
            shoppingCartEntry.method = method;
            shoppingCartEntry.targetDate = userSetTargetDate ? targetDate : earliestDeliveryDate;
            shoppingCartEntry.targetDateType =
              method.value === T_SEAFREIGHT && targetDateType === DateType.FIX ? undefined : targetDateType;
          }
          window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
        }
      } finally {
        setPriceLoading(false);
      }
    }
  };

  const handleChangeRef = (e: ChangeEvent<HTMLInputElement>, orderInfoId: string) => {
    const orderInfos = _.clone(orderInformation);
    const orderInfo = orderInfos.find((oi) => oi._id.toString() === orderInfoId);
    if (orderInfo) {
      orderInfo.reference = e.currentTarget.value.trim();
      setOrderInformation(orderInfos);
      const shoppingCart = getShoppingCart();
      if (shoppingCart) {
        const shoppingCartEntry = shoppingCart.articlesToOrder.find((ato) => ato._id.toString() === orderInfoId);
        if (shoppingCartEntry) shoppingCartEntry.reference = e.currentTarget.value;
        window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
      }
    }
  };

  const handleRemove = (orderInfoId: string) => {
    const orderInfos = _.clone(orderInformation);
    const orderInfoIndex = orderInfos.findIndex((oi) => oi._id.toString() === orderInfoId);
    if (orderInfoIndex !== -1) {
      orderInfos.splice(orderInfoIndex, 1);
      setOrderInformation(orderInfos);
      const shoppingCart = getShoppingCart();
      if (shoppingCart) {
        const index = shoppingCart.articlesToOrder.findIndex((ato) => ato._id.toString() === orderInfoId);
        if (shoppingCart.articlesToOrder.length !== 1) {
          shoppingCart.articlesToOrder.splice(index, 1);
        } else {
          shoppingCart.articlesToOrder = [];
        }
        window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
      }
    }
  };

  const handleChangeGlobalRef = (e: ChangeEvent<HTMLInputElement>) => {
    const newRef = e.currentTarget.value.trim();
    setGlobalValues((prevState) => {
      return { ...prevState, globalRef: newRef };
    });
    const shoppingCart = getShoppingCart();
    if (shoppingCart) {
      shoppingCart.globalReference = newRef;
      window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
    }
  };

  const handleChangeGlobalAddress = (e: AddressSelectOption) => {
    const newAddress = e;
    setGlobalValues((prevState) => {
      return { ...prevState, globalAddress: newAddress };
    });
    const shoppingCart = getShoppingCart();
    if (shoppingCart) {
      shoppingCart.globalShippingAddress = newAddress;
      window.localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart));
    }
  };

  const handleChangeStep = (step: VIEW_STEPS) => {
    setState({ tosAgreed: false, step });
  };

  const handleChangeToSAgree = () => {
    setState((prevState) => {
      return { ...prevState, tosAgreed: !state.tosAgreed };
    });
  };

  const handleConfirm = useCallback(async () => {
    const { context, history } = props;
    setGenerating(true);
    try {
      const customerOrders = [];
      const company = getDocFromCollection(context.company, userService.getCompany());
      for (let i = 0; i < orderInformation.length; i++) {
        const {
          _id,
          article,
          amount,
          priceInfo,
          shippingAddress,
          reference,
          method,
          supplier,
          targetDate,
          targetDateType,
          incomingOrderableStock,
        } = orderInformation[i];
        if (!company || !priceInfo || !shippingAddress) {
          toast.error("Error creating order for " + article.title.en);
          return;
        }

        // Already checked above but double makes the type check work
        if (!priceInfo) return;
        const snapshot = getArticleSnapshot(article);

        const priceServices = 0;
        const priceCommodities =
          priceInfo && isFinite(priceInfo.totalPrice)
            ? priceInfo.totalPrice
            : incomingOrderableStock && method.value === T_WAREHOUSE
            ? incomingOrderableStock.price * amount
            : Infinity;
        if (priceCommodities === Infinity) return;
        // Create a wrapper object with the customer order information
        const customerOrder: CustomerCustomerOrder = {
          _id: new BSON.ObjectId(_id),
          commodity: snapshot,
          amount: +amount,
          unit: article.unit as "kg" | "ltr",
          priceCommodities,
          currency: CUSTOMER_BASE_CURRENCY,
          targetDate,
          targetDateType,
          destination: globalValues.globalAddress ? globalValues.globalAddress.address : shippingAddress.address,
          customerReference: globalValues.globalRef ? globalValues.globalRef : reference,
          transport: method.value as CO_TRANSPORT,
          company: company._id.toString(),
          person: userService.getUserId(),
          timeline: [getCustomerOrderTimelineEntry(method.value === T_WAREHOUSE ? CO_T_REQUESTEDSTOCK : CO_T_CREATED)],
          services: [],
          priceServices,
          files: [],
          orderNo: "-1", // Will be replaced in backend
          noteCustomer: "",
          state: method.value === T_WAREHOUSE ? CO_REQUESTEDSTOCK : CO_ORDEREDBYCUSTOMER,
          totalPrice: priceCommodities + priceServices,
          createdAt: new Date(),
          terms: getPaymentTermsFromCompany(company, shippingAddress.address),
        };
        if (supplier) customerOrder.supplier = supplier.object?._id.toString();
        customerOrders.push({
          customerOrder,
          supplierOrderId:
            method.value === T_WAREHOUSE && incomingOrderableStock ? incomingOrderableStock.order : undefined,
          currencies: method.value === T_WAREHOUSE && incomingOrderableStock ? context.currencies : undefined,
        });
      }

      const results: Array<
        | {
            res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
            orderNumber: string;
            error?: string;
          }
        | undefined
      > = await callFunction(PLACEMULTIPLECUSTOMERORDERS, [customerOrders]);
      if (results.length > 0 && results.every((r) => r?.res)) {
        toast.success("Orders successfully placed");
      } else {
        toast.error("Error placing orders. Please try again later.");
      }

      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        if (result && result.res && result.res.insertedId) {
          const customerOrder = customerOrders.find(
            (c) => c.customerOrder._id.toString() === result.res.insertedId.toString()
          )?.customerOrder;
          if (customerOrder && company) {
            customerOrder.orderNo = result.orderNumber;
            const path = await createPDF(
              createRequestConfirmationHTML(customerOrder, REQUESTTEXTSHORT, context),
              "Request-Confirmation-" + result.orderNumber,
              company._id.toString(),
              {
                marginLeft: "2cm",
                marginBottom: "4.2cm",
                footerHtml: CO_FOOTER_HTML,
              }
            );
            if (path) {
              await callPushToArray(CUSTOMERORDER, result.res.insertedId, "files", {
                _id: new BSON.ObjectId(),
                date: new Date(),
                path,
                type: CO_REQUESTCONFIRMATION,
              });
            }
            sendSlackNotification(customerOrder, result.res.insertedId.toString(), company);
          }
        } else {
          toast.error(
            `Request confirmation for an article could not be placed: ${
              result && result.error ? result.error : "Unknown error"
            }`
          );
          if (result && result.error) console.error(result.error);
        }
      }
    } catch (e) {
      toast.error(`Orders could not be placed: ${e as string}`);
    } finally {
      setGenerating(false);
      setTimeout(() => {
        history.push("/orders");
      });
      window.localStorage.removeItem("shoppingCart");
    }
  }, [props, orderInformation]);

  const header: Array<BaseListingHeaderDefinition> = [
    { title: <i className="fa fa-trash  " />, style: { width: "1%" } },
    { title: "Article ", style: { width: "12%" } },
    { title: "Reference, Amount", className: "required", style: { width: "13%" } },
    { title: "Target Date", className: "required", style: { width: "10%" } },
    { title: "Destination", className: "required", style: { width: "15%" } },
    { title: "Preferred Supplier", style: { width: "20%" } },
    { title: "Transport Type", className: "required", style: { width: "15%" } },
    { title: "Prices", style: { width: "14%" } },
  ];

  const totalPrice = useMemo(() => {
    return orderInformation.reduce((sum, oi) => {
      const toAdd =
        oi.method.value === T_WAREHOUSE &&
        (!oi.availableStock || oi.availableStock < oi.amount) &&
        oi.incomingOrderableStock &&
        oi.incomingOrderableStock.availableAmount > 0 &&
        oi.incomingOrderableStock?.availableAmount >= oi.amount
          ? oi.incomingOrderableStock.price * oi.amount
          : oi.priceInfo
          ? oi.priceInfo.totalPrice
          : 0;
      return toAdd + sum;
    }, 0);
  }, [orderInformation]);

  const errors: Array<string> = useMemo(() => {
    const errorSet = new Set<string>();
    for (let i = 0; i < orderInformation.length; i++) {
      const orderInfo = orderInformation[i];
      if (orderInfo) {
        const {
          article,
          amount,
          shippingAddress,
          method,
          availableStock,
          priceInfo,
          targetDate,
          incomingOrderableStock,
          earliestDeliveryDate,
        } = orderInfo;

        const suitableAmount = validateAmount(article, amount, method.value);
        if (article && (article.disabled || !article.approved)) {
          errorSet.add(`Article ${article.title.en} can currently not be ordered`);
          return Array.from(errorSet);
        }
        if (method.value === T_AIRFREIGHT && amount > AIR_MAX_ORDER_QUANTITY) {
          errorSet.add(`Air freight only available up to ${formatUnit(AIR_MAX_ORDER_QUANTITY, "kg")}`);
        }
        if (method.value === T_SEAFREIGHT && amount < SEA_MIN_ORDER_QUANTITY) {
          errorSet.add(`Sea freight only available from ${formatUnit(SEA_MIN_ORDER_QUANTITY, "kg")}`);
        }
        if (!amount) errorSet.add(`Select an amount to order for article ${article.title.en}`);
        if (suitableAmount) errorSet.add(`Amount cannot be ordered for article ${article.title.en}`);
        if (!shippingAddress) errorSet.add(`Select a shipping address for article ${article.title.en}`);
        if (
          method.value === T_WAREHOUSE &&
          amount > availableStock &&
          (!incomingOrderableStock || (incomingOrderableStock && incomingOrderableStock.availableAmount < amount))
        ) {
          errorSet.add(`Not enough stock currently available for article ${article.title.en}.`);
        }
        if (!priceInfo || priceInfo.unitPrice === 0 || !isFinite(priceInfo.unitPrice)) {
          if (method.value !== T_WAREHOUSE) errorSet.add(`Article ${article.title.en} currently not available`);
        }
        if ([0, 6].includes(targetDate.getDay())) {
          errorSet.add("Weekends can't be selected as target date");
        } else if (targetDate < earliestDeliveryDate) errorSet.add("Target date is too early and cannot be guaranteed");
        if (priceInfo?.isFallback)
          errorSet.add(`Price of article ${article.title.en} is outdated for ordering via ${method.label}`);
      }
    }
    if (state.step === VIEW_STEPS.CHECKOUT_OVERVIEW && !state.tosAgreed)
      errorSet.add("Please verify your order and agree to our terms of service");
    return Array.from(errorSet);
  }, [orderInformation, state]);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl">
          <div className="card bg-white min-h-100">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder mb-3 fs-3rem">Checkout Orders</span>
              </h3>
              {state.step === VIEW_STEPS.INPUT ? (
                <BaseListing
                  headerDefinition={header}
                  noHover={true}
                  bodyContent={
                    <>
                      {loading ? (
                        <tr className="text-muted text-center">
                          <td colSpan={8}>Loading...</td>
                        </tr>
                      ) : generating ? (
                        <tr className="text-muted text-center">
                          <td colSpan={8}>Generating...</td>
                        </tr>
                      ) : orderInformation.length > 0 ? (
                        orderInformation.map((info) => {
                          return (
                            <ShoppingCartCheckoutCard
                              key={info._id.toString()}
                              context={props.context}
                              globalInfo={globalValues}
                              priceLoading={priceLoading}
                              orderInfo={info}
                              onChangeAmount={handleChangeAmount}
                              onChangeTargetDate={handleChangeTargetDate}
                              onChangeTargetDateType={handleChangeTargetDateType}
                              onChangeAddress={handleChangeAddress}
                              onChangeMethod={handleChangeMethod}
                              onChangeRef={handleChangeRef}
                              onSelectSupplier={handleSelectSupplier}
                              onRemoveFromShoppingCart={handleRemove}
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={12}>
                            <ListPlaceholder
                              type="shopping cart"
                              buttonText="Add to Shopping Cart"
                              overwriteText="Once you added an article to the shopping cart, it will show up here."
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  }
                />
              ) : (
                <>
                  {orderInformation.map((info) => {
                    return (
                      <ShoppingCartCheckoutOverview
                        key={info._id.toString()}
                        globalInfo={globalValues}
                        orderInfo={info}
                        context={props.context}
                      />
                    );
                  })}
                </>
              )}
              {orderInformation.length > 0 && state.step === VIEW_STEPS.INPUT && (
                <div className="row my-5">
                  <div className="col-4">
                    <label className="form-check-label fs-6 mb-2">Reference for all orders: </label>
                    <Input
                      type="text"
                      className="form-control custom-form-control"
                      placeholder="Your reference for all orders..."
                      name="reference"
                      value={globalValues.globalRef}
                      onBlur={handleChangeGlobalRef}
                    />
                  </div>
                  <div className="col-4">
                    <label className="form-check-label fs-6 mb-2">Address for all orders: </label>
                    <OwnAddressSelector
                      context={props.context}
                      isClearable={true}
                      onChange={handleChangeGlobalAddress}
                      value={globalValues.globalAddress}
                      matchFormControl={true}
                      placeholder="Shipping Address for all orders..."
                    />
                  </div>
                </div>
              )}
              {orderInformation.length > 0 && (
                <div className="text-white fs-4 fw-bolder text-right pr-3">{`Total price: ${
                  loading || priceLoading ? "Loading" : formatCurrency(totalPrice, CUSTOMER_BASE_CURRENCY)
                }`}</div>
              )}
              {state.step === VIEW_STEPS.CHECKOUT_OVERVIEW && (
                <div className="row flex-grow-1 mb-4">
                  <div className="my-2">
                    <div
                      className="form-check form-check-sm form-check-custom form-check-solid mt-5"
                      onClick={handleChangeToSAgree}
                    >
                      <input
                        className="form-check-input position-static mr-2"
                        checked={state.tosAgreed}
                        type="checkbox"
                        readOnly={true}
                      />
                      <label className="form-check-label text-warning ">
                        I have verified all data carefully and understand that this order is binding and will be carried
                        out as stated. Delivery dates are non-binding estimates and may vary due to external factors. By
                        placing an order you agree to our{" "}
                        <a href="/tos" target="_blank" rel="noopener noreferrer" className="text-white">
                          <b>
                            <u>terms of service</u>
                          </b>
                        </a>
                        .
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="border-bottom-dark-gray pt-5" />
              <div className="pt-3">
                <div className="d-flex pt-3 align-items-center justify-content-end w-100">
                  <button
                    className="btn btn-light btn-sm float-right ml-4"
                    onClick={
                      state.step === VIEW_STEPS.INPUT
                        ? () => {
                            props.history.push("/articles");
                          }
                        : () => handleChangeStep(VIEW_STEPS.INPUT)
                    }
                    disabled={generating}
                  >
                    {state.step === VIEW_STEPS.INPUT ? "Back to Commodity Listing" : "Back"}
                  </button>
                  <ErrorOverlayButton
                    errors={errors}
                    className={"btn btn-light btn-sm ml-4 float-right"}
                    buttonText={
                      loading
                        ? "Loading"
                        : generating
                        ? "Generating"
                        : state.step === VIEW_STEPS.INPUT
                        ? "Checkout"
                        : "Create Orders"
                    }
                    disabled={orderInformation.length <= 0}
                    saving={loading || generating}
                    onClick={
                      loading
                        ? undefined
                        : state.step === VIEW_STEPS.INPUT
                        ? () => handleChangeStep(VIEW_STEPS.CHECKOUT_OVERVIEW)
                        : handleConfirm
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartCheckout;
