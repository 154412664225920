import _ from "lodash";
import React, { PureComponent, useContext, useMemo } from "react";
import { Commodity, CommoditySnapshot } from "../../model/commodity.types";
import {
  AirFreightCalculationValues,
  AirFreightPriceCalculation,
  calculateAirFreightPrice,
  calculateLCLPrice,
  calculateSeaFreightPrice,
  calculateWarehousePrice,
  getDefaultAirFreightCalculationsValues,
  getDefaultLCLSeaFreightCalculationsValues,
  getDefaultSeaFreightCalculationsValues,
  getDefaultWarehouseCalculationsValues,
  getSupplierOrdersForWarehouseCalculation,
  getTotalTransportCost,
  isAirFreightCalculation,
  isAirFreightValues,
  isLCLSeaFreightCalculation,
  isLCLSeaFreightValues,
  isSeaFreightCalculation,
  isSeaFreightValues,
  isWarehouseCalculation,
  isWarehouseValues,
  LCLSeaFreightCalculationValues,
  LCLSeaFreightPriceCalculation,
  P_CALC_WAREHOUSE,
  reverseCalculateAirFreightPrice,
  reverseCalculateLCLPrice,
  reverseCalculateSeaFreightPrice,
  SeaFreightCalculationValues,
  SeaFreightPriceCalculation,
  WarehouseCalculationRelatedObjects,
  WarehouseCalculationValues,
  WarehousePriceCalculation,
} from "../../utils/priceCalculationUtils";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import { Seaport } from "../../model/seaport.types";
import { DataContextInternal, DataContextInternalType } from "../../context/dataContext";
import { Input } from "../common/Input";
import { getBestSupplierAndPrice } from "../../utils/orderCalculationUtils";
import { T_SEAFREIGHT } from "../../model/customerOrder.types";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { Airport } from "../../model/airport.types";
import LCLSeaFreightCalculationView from "../order/modals/views/LCLSeaFreightCalculationView";
import AirFreightCalculationView from "../order/modals/views/AirFreightCalculationView";
import {
  CNY,
  convertCurrency,
  Currencies,
  EURO,
  getRelevantCurrencyExchangeRates,
  SUPPORTED_CURRENCIES,
  USD,
} from "../../utils/currencyUtils";
import { CALC_AIRFREIGHT, CALC_LCLBASE, CALC_SEAFREIGHT, SupplierOrder } from "../../model/supplierOrder.types";
import { getSeaportName, getStartingSeaports, isSeaport } from "../../utils/seaportUtils";
import { getAirportName, getStartingAirports, isAirport } from "../../utils/airportUtils";
import { ceil, countDecimals, formatCurrency, getNumericValue, pluralize, round } from "../../utils/baseUtils";
import SeaFreightCalculationView from "../order/modals/views/SeaFreightCalculationView";
import SimpleSplashScreen from "../common/SimpleSplashScreen";
import WarehouseCalculationView from "./priceCalculator/WarehouseCalculationView";
import { Incoterm } from "../../utils/commodityUtils";
import { FinishedProduct, FinishedProductSnapshot } from "../../model/finishedProduct.types";
import Tooltip from "../common/Tooltip";

interface PriceCalculatorProps {
  context: DataContextInternalType;
}

interface PriceCalculatorState {
  type: typeof CALC_LCLBASE | typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT | typeof P_CALC_WAREHOUSE;
  commodity?: Commodity;
  pricePerUnit: number;
  totalPrice: number;
  calculationValues:
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | SeaFreightCalculationValues
    | WarehouseCalculationValues
    | undefined;
  totalNetWeight: number;
  port?: Seaport | Airport;
  supplierOrder?: SelectOption<WarehouseCalculationRelatedObjects>;
  priceCalculation?:
    | LCLSeaFreightPriceCalculation
    | AirFreightPriceCalculation
    | SeaFreightPriceCalculation
    | WarehousePriceCalculation;
  marginPercent: string;
  priceWithMargin?: number;
  reverse: boolean;
  targetCurrency: string;
  loading: boolean;
  exchangeRates: Currencies;
  exchangeRatesAdjusted: boolean;
}

class PriceCalculator extends PureComponent<PriceCalculatorProps, PriceCalculatorState> {
  viewRef: React.RefObject<
    LCLSeaFreightCalculationView | AirFreightCalculationView | SeaFreightCalculationView | WarehouseCalculationView
  >;

  constructor(props: PriceCalculatorProps) {
    super(props);
    this.viewRef = React.createRef();
    const totalNetWeight = 1000;
    this.state = {
      type: CALC_SEAFREIGHT,
      pricePerUnit: 0,
      totalPrice: 0,
      totalNetWeight,
      calculationValues: undefined,
      marginPercent: "10",
      reverse: false,
      targetCurrency: EURO,
      loading: true,
      exchangeRates: { ...props.context.currencies },
      exchangeRatesAdjusted: false,
    };
  }

  componentDidMount = async () => {
    try {
      this.setState({
        calculationValues: await getDefaultSeaFreightCalculationsValues(
          this.props.context,
          undefined,
          this.state.totalNetWeight
        ),
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleToggleReverse = async () => {
    const { totalNetWeight, reverse } = this.state;
    let price;
    // If reverse flag is set calculate the DDP price for the best price and set it as price per unit
    if (!reverse) {
      price = await this.getPricePerUnit();
    } else {
      price = await this.getReversePricePerUnit();
    }
    this.setState({
      reverse: !reverse,
      targetCurrency: reverse ? EURO : USD, // flag is currently reversed -> standard mode afterwards therefore EUR
      pricePerUnit: price,
      totalPrice: price * totalNetWeight,
      priceCalculation: undefined,
    });
  };

  handleChangeTargetCurrency = (e: SelectOption) => {
    this.setState({ targetCurrency: e.value, priceCalculation: undefined });
  };

  handleSelectCommodity = async (e: SelectOption<Commodity>) => {
    const { context } = this.props;
    const { type, totalNetWeight, reverse } = this.state;

    if (!e?.object) {
      const calculationValues = await this.getCalculationValues(type);
      this.setState({
        commodity: undefined,
        pricePerUnit: 0,
        totalPrice: 0,
        calculationValues,
        supplierOrder: undefined,
      });
      return;
    }
    const commodity = e.object;
    const bestPrice = getBestSupplierAndPrice(commodity, totalNetWeight, commodity.unit || "kg", T_SEAFREIGHT, context);
    const calculationValues = await this.getCalculationValues(type, commodity, totalNetWeight);

    let price = bestPrice
      ? convertCurrency(bestPrice.price.price, bestPrice.price.currency, USD, context.currencies)
      : 0;
    // If reverse flag is set calculate the DDP price for the best price and set it as price per unit
    if (reverse) {
      price = await this.getPricePerUnit(price, calculationValues);
    }
    price = round(price, 2);
    this.setState({
      commodity: commodity,
      pricePerUnit: price,
      totalPrice: price * totalNetWeight,
      calculationValues,
      supplierOrder: undefined,
    });
  };

  handleChangeNetWeight = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { context } = this.props;
    const { type, commodity, pricePerUnit } = this.state;
    const value = +e.target.value;
    const bestPrice = commodity
      ? getBestSupplierAndPrice(commodity, value, commodity.unit || "kg", T_SEAFREIGHT, context)
      : undefined;
    const calculationValues = await this.getCalculationValues(type, commodity, value);

    const price = round(
      bestPrice
        ? convertCurrency(bestPrice.price.price, bestPrice.price.currency, USD, context.currencies)
        : pricePerUnit,
      2
    );
    const totalPrice = price * value;
    this.setState({
      totalNetWeight: value,
      pricePerUnit: price,
      totalPrice,
      calculationValues,
    });
  };

  handleSelectPort = (e: SelectOption<Airport | Seaport>) => {
    const calculationValues = _.cloneDeep(this.state.calculationValues);
    if (!calculationValues) return;
    let port;
    if (e?.object) {
      port = e.object;
      if (isAirport(port) && isAirFreightValues(calculationValues)) {
        calculationValues.airFreightCost = { cost: port?.cost || 0, currency: port?.currency || CNY };
      } else if (isSeaport(port) && isLCLSeaFreightValues(calculationValues)) {
        calculationValues.seaFreightCost = port?.cost || 0;
      } else if (isSeaport(port) && isSeaFreightValues(calculationValues)) {
        if (!port.containerCost || !port.cost) return;
        const containerCost = port.containerCost;
        calculationValues.seaFreightCost = {
          cost: { containerCost, cost: port.cost },
          currency: port.currency || USD,
        };
      }
    }
    this.setState({ port, calculationValues });
  };

  handleSelectSupplierOrder = async (e: SelectOption<WarehouseCalculationRelatedObjects>) => {
    const { context } = this.props;
    const { type, calculationValues: calculationValuesState, totalNetWeight } = this.state;
    if (!calculationValuesState || !isWarehouseValues(calculationValuesState)) return;

    if (!e.object) {
      const calculationValues = await this.getCalculationValues(type);
      this.setState({
        supplierOrder: undefined,
        pricePerUnit: 0,
        totalPrice: 0,
        calculationValues,
        exchangeRates: { ...context.currencies },
      });
      return;
    }
    const supplierOrder = e.object.supplierOrder;
    const calculationValues = await this.getCalculationValues(type, undefined, undefined, supplierOrder);
    const pricePerUnit = supplierOrder.priceCommodities / supplierOrder.amount;
    this.setState({
      supplierOrder: e,
      calculationValues,
      pricePerUnit,
      totalPrice: pricePerUnit * totalNetWeight,
      exchangeRates: supplierOrder.exchangeRates ? { ...supplierOrder.exchangeRates } : { ...context.currencies },
    });
  };

  handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pricePerUnit = +e.target.value;
    this.setState({ pricePerUnit, totalPrice: pricePerUnit * this.state.totalNetWeight });
  };

  handleChangeTotalPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const totalPrice = +e.target.value;
    const pricePerUnit = totalPrice / this.state.totalNetWeight;
    this.setState({
      pricePerUnit: countDecimals(pricePerUnit) > 5 ? round(pricePerUnit, 5) : pricePerUnit,
      totalPrice,
    });
  };

  handleChangeMargin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { priceCalculation, type } = this.state;
    if (!priceCalculation) return;
    const value = Number(parseInt(e.target.value) || "0").toString();
    e.currentTarget.style.width = value.length + "ch";
    let priceWithMargin = priceCalculation.totalCost;
    if (type !== P_CALC_WAREHOUSE) {
      priceWithMargin *= 1 + +value / 100;
      this.setState({ marginPercent: value, priceWithMargin });
    } else {
      this.setState({ marginPercent: value }, async () => await this.handleCalculate());
    }
  };

  handleChangeCalculationType = async (
    type: typeof CALC_LCLBASE | typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT | typeof P_CALC_WAREHOUSE
  ) => {
    const { commodity, totalNetWeight, supplierOrder } = this.state;
    const calculationValues = await this.getCalculationValues(
      type,
      commodity,
      totalNetWeight,
      supplierOrder?.object?.supplierOrder
    );

    this.setState({
      type,
      calculationValues,
      reverse: false,
      port: undefined,
      priceCalculation: undefined,
      priceWithMargin: undefined,
      supplierOrder: undefined,
    });
  };

  handleCalculate = async () => {
    if (!this.viewRef || !this.viewRef.current) return;
    const { context } = this.props;
    const { type, totalNetWeight, pricePerUnit, marginPercent, reverse, targetCurrency, exchangeRates } = this.state;
    const calculationValues = this.viewRef.current.getCalculationValues();
    let priceCalculation;
    const price = { price: pricePerUnit, currency: USD };
    if (type === CALC_AIRFREIGHT && isAirFreightValues(calculationValues)) {
      priceCalculation = await (reverse
        ? reverseCalculateAirFreightPrice(totalNetWeight, price, calculationValues, exchangeRates, targetCurrency)
        : calculateAirFreightPrice(totalNetWeight, 0, price, calculationValues, exchangeRates, targetCurrency));
    } else if (type === CALC_LCLBASE && isLCLSeaFreightValues(calculationValues)) {
      priceCalculation = await (reverse
        ? reverseCalculateLCLPrice(totalNetWeight, price, calculationValues, exchangeRates, targetCurrency)
        : calculateLCLPrice(totalNetWeight, 0, price, calculationValues, exchangeRates, targetCurrency));
    } else if (type === CALC_SEAFREIGHT && isSeaFreightValues(calculationValues)) {
      priceCalculation = await (reverse
        ? reverseCalculateSeaFreightPrice(totalNetWeight, price, calculationValues, exchangeRates, targetCurrency)
        : calculateSeaFreightPrice(totalNetWeight, 0, price, calculationValues, exchangeRates, targetCurrency));
    } else if (type === P_CALC_WAREHOUSE && isWarehouseValues(calculationValues)) {
      priceCalculation = await calculateWarehousePrice(
        totalNetWeight,
        price,
        calculationValues,
        exchangeRates,
        targetCurrency,
        +marginPercent
      );
    }

    let priceWithMargin;
    if (priceCalculation) {
      // No additional margin for warehouse calc
      if (isWarehouseCalculation(priceCalculation)) priceWithMargin = priceCalculation.totalCost;
      else if (isLCLSeaFreightCalculation(priceCalculation))
        priceWithMargin = priceCalculation.totalCost * (1 + +marginPercent / 100);
      else if (isSeaFreightValues(calculationValues) || isAirFreightValues(calculationValues)) {
        const { minimumAbsoluteMargin } = calculationValues;
        const margin = priceCalculation.totalCost * (+marginPercent / 100);
        const convertedMinimumMargin = convertCurrency(
          minimumAbsoluteMargin.value,
          minimumAbsoluteMargin.currency,
          targetCurrency,
          context.currencies
        );
        const finalMargin = convertedMinimumMargin > margin ? convertedMinimumMargin : margin;
        priceWithMargin = priceCalculation.totalCost + finalMargin;
      }
    }

    this.setState({ priceCalculation, calculationValues, priceWithMargin });
  };

  handleChangeExchangeRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const exchangeRates = { ...this.state.exchangeRates };
    const { name } = e.target;
    if (name in exchangeRates) {
      exchangeRates[name] = Number(getNumericValue(e));
      this.setState({ exchangeRates, exchangeRatesAdjusted: true }, this.handleCalculate);
    }
  };

  /**
   * Get calculation values based on type, commodity and total weight
   * @param type the currently selected type
   * @param commodity optional, the currently selected commodity
   * @param totalNetWeight optional, the total commodity weight
   * @param supplierOrder optional, selected supplier order
   * @returns {Promise<SeaFreightCalculationValues | LCLSeaFreightCalculationValues | AirFreightCalculationValues | WarehouseCalculationValues>} calculation values for given type and data
   */
  getCalculationValues = async (
    type: typeof CALC_LCLBASE | typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT | typeof P_CALC_WAREHOUSE,
    commodity?: Commodity,
    totalNetWeight?: number,
    supplierOrder?: SupplierOrder
  ): Promise<
    | SeaFreightCalculationValues
    | LCLSeaFreightCalculationValues
    | AirFreightCalculationValues
    | WarehouseCalculationValues
  > => {
    const { context } = this.props;
    return type === CALC_AIRFREIGHT
      ? getDefaultAirFreightCalculationsValues(commodity, totalNetWeight)
      : type === CALC_SEAFREIGHT
      ? getDefaultSeaFreightCalculationsValues(context, commodity, totalNetWeight)
      : type === CALC_LCLBASE
      ? getDefaultLCLSeaFreightCalculationsValues(commodity, totalNetWeight)
      : getDefaultWarehouseCalculationsValues(supplierOrder, context);
  };

  /**
   * Calculate price per unit based on given or current state values
   * @param pricePerUnit optional, price per unit
   * @param calcValues optional, sea or air freight calculation values
   * @param currency optional, target currency to convert the price in. Default: USD since commodity prices are in USD
   * @returns {Promise<number>} calculated price per unit with given values or state values
   */
  getPricePerUnit = async (
    pricePerUnit?: number,
    calcValues?:
      | LCLSeaFreightCalculationValues
      | AirFreightCalculationValues
      | SeaFreightCalculationValues
      | WarehouseCalculationValues,
    currency?: string
  ): Promise<number> => {
    const { context } = this.props;
    const { type, totalNetWeight } = this.state;
    let price = pricePerUnit !== undefined ? pricePerUnit : this.state.pricePerUnit;
    if (price <= 0) return 0;
    const calculationValues = calcValues ? calcValues : this.state.calculationValues;
    const calcPrice = { price, currency: USD };
    const priceCalculation = await (type === CALC_AIRFREIGHT
      ? calculateAirFreightPrice(
          totalNetWeight,
          0,
          calcPrice,
          calculationValues as AirFreightCalculationValues,
          context.currencies,
          currency || USD
        )
      : type === CALC_SEAFREIGHT
      ? calculateSeaFreightPrice(
          totalNetWeight,
          0,
          calcPrice,
          calculationValues as SeaFreightCalculationValues,
          context.currencies,
          currency || USD
        )
      : type === CALC_LCLBASE
      ? calculateLCLPrice(
          totalNetWeight,
          0,
          calcPrice,
          calculationValues as LCLSeaFreightCalculationValues,
          context.currencies,
          currency || USD
        )
      : type === P_CALC_WAREHOUSE
      ? 0
      : 0);
    if (priceCalculation && priceCalculation.totalPricePerUnit)
      price =
        countDecimals(priceCalculation.totalPricePerUnit) > 5
          ? round(priceCalculation.totalPricePerUnit, 5)
          : priceCalculation.totalPricePerUnit;
    return price;
  };

  /**
   * Calculate price per unit based on given or current state values
   * @param pricePerUnit optional, price per unit
   * @param calcValues optional, sea or air freight calculation values
   * @param currency optional, target currency to convert the price in. Default: USD since commodity prices are in USD
   * @returns {Promise<number>} calculated price per unit with given values or state values
   */
  getReversePricePerUnit = async (
    pricePerUnit?: number,
    calcValues?: LCLSeaFreightCalculationValues | AirFreightCalculationValues | SeaFreightCalculationValues,
    currency?: string
  ): Promise<number> => {
    const { context } = this.props;
    const { type, totalNetWeight } = this.state;
    let price = pricePerUnit !== undefined ? pricePerUnit : this.state.pricePerUnit;
    if (price <= 0) return 0;
    const calculationValues = calcValues ? calcValues : this.state.calculationValues;
    const reversePrice = { price, currency: USD };
    const priceCalculation = await (type === CALC_AIRFREIGHT
      ? reverseCalculateAirFreightPrice(
          totalNetWeight,
          reversePrice,
          calculationValues as AirFreightCalculationValues,
          context.currencies,
          currency || USD
        )
      : type === CALC_SEAFREIGHT
      ? reverseCalculateSeaFreightPrice(
          totalNetWeight,
          reversePrice,
          calculationValues as SeaFreightCalculationValues,
          context.currencies,
          currency || USD
        )
      : reverseCalculateLCLPrice(
          totalNetWeight,
          reversePrice,
          calculationValues as LCLSeaFreightCalculationValues,
          context.currencies,
          currency || USD
        ));

    if (priceCalculation && priceCalculation.totalPricePerUnit)
      price =
        countDecimals(priceCalculation.totalPricePerUnit) > 5
          ? round(priceCalculation.totalPricePerUnit, 5)
          : priceCalculation.totalPricePerUnit;
    return price;
  };

  getArticlesForWarehouse = () => {
    const { context } = this.props;
    const articles: Array<Commodity | CommoditySnapshot | FinishedProduct | FinishedProductSnapshot> = [];
    const addedArticles: Array<string> = [];
    context.supplierOrder.forEach((sO) => {
      if (!addedArticles.includes(sO.commodity._id.toString())) {
        addedArticles.push(sO.commodity._id.toString());
        articles.push(sO.commodity);
      }
    });
    return articles;
  };

  validateData = () => {
    const { totalNetWeight, pricePerUnit } = this.state;
    const errors: Array<string> = [];
    if (totalNetWeight === 0) errors.push("Enter a commodity amount");
    if (pricePerUnit === 0) errors.push("Enter a valid price");
    return errors;
  };

  render() {
    const { context } = this.props;
    const {
      type,
      commodity,
      totalNetWeight,
      calculationValues,
      pricePerUnit,
      totalPrice,
      port,
      priceCalculation,
      priceWithMargin,
      marginPercent,
      reverse,
      targetCurrency,
      loading,
      supplierOrder,
      exchangeRates,
      exchangeRatesAdjusted,
    } = this.state;

    if (loading) return <SimpleSplashScreen description="Loading..." />;
    if (!calculationValues)
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Price Calculator</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">
                      Calculation values could not be loaded. Please reload or try again later.
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    let commodities = type === P_CALC_WAREHOUSE ? this.getArticlesForWarehouse() : context.commodity.slice();
    commodities = _.orderBy(commodities, "title.en");

    const warehouseSupplierOrders = P_CALC_WAREHOUSE
      ? getSupplierOrdersForWarehouseCalculation(context, commodity)
      : [];
    const errors = this.validateData();
    const supplierOrderDoc = supplierOrder?.object?.supplierOrder;

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title ">
                  <span className="card-label fw-bolder fs-3rem">Price Calculator</span>
                </h3>
                <div className="d-flex flex-column">
                  <div className="btn-group btn-group-md rounded-0 mt-10 mb-5">
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " +
                        (type === CALC_SEAFREIGHT && "active")
                      }
                      onClick={() => this.handleChangeCalculationType(CALC_SEAFREIGHT)}
                    >
                      Sea Freight
                    </button>
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " +
                        (type === CALC_AIRFREIGHT && "active")
                      }
                      onClick={() => this.handleChangeCalculationType(CALC_AIRFREIGHT)}
                    >
                      Air Freight
                    </button>
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " +
                        (type === P_CALC_WAREHOUSE && "active")
                      }
                      onClick={() => this.handleChangeCalculationType(P_CALC_WAREHOUSE)}
                    >
                      Warehouse
                    </button>
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " + (type === CALC_LCLBASE && "active")
                      }
                      onClick={() => this.handleChangeCalculationType(CALC_LCLBASE)}
                    >
                      Sea Freight (LCL) - Deprecated
                    </button>
                  </div>
                </div>
                <div>
                  <h3 className="mb-3 mt-7">General Data</h3>
                  <div className="row">
                    <div className="col-auto col-xl-2">
                      <label className="fs-5 fw-bold mb-2">Mode</label>
                      <span
                        className={
                          "form-check form-switch form-check-custom form-check-solid fs-5 mt-1 " +
                          (type === P_CALC_WAREHOUSE ? "disabled" : "")
                        }
                      >
                        <label className={"form-check-label ml-0 fw-bold " + (reverse ? "text-muted" : "text-white")}>
                          Standard
                        </label>
                        <input
                          className="form-check-input position-static ml-2"
                          style={{ height: "1.25rem" }}
                          checked={reverse}
                          disabled={type === P_CALC_WAREHOUSE}
                          onChange={type === P_CALC_WAREHOUSE ? undefined : this.handleToggleReverse}
                          type="checkbox"
                        />
                        <label className={"form-check-label fw-bold " + (reverse ? "text-white" : "text-muted")}>
                          Reverse
                        </label>
                      </span>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-xl-2">
                      <label className="fs-5 fw-bold mb-2">Target Currency</label>
                      <CustomSelect
                        options={SUPPORTED_CURRENCIES.map((c) => {
                          return { label: c, value: c };
                        })}
                        onChange={this.handleChangeTargetCurrency}
                        matchFormControl={true}
                        value={
                          targetCurrency
                            ? {
                                label: targetCurrency,
                                value: targetCurrency,
                              }
                            : undefined
                        }
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label className="fs-5 fw-bold mb-2">Commodity (optional)</label>
                      <CustomSelect
                        options={commodities.map((c) => {
                          return { label: `${c.title.en} - ${c.subtitle.en}`, value: c._id.toString(), object: c };
                        })}
                        onChange={this.handleSelectCommodity}
                        isClearable={true}
                        matchFormControl={true}
                        disabled={!!supplierOrderDoc && !commodity}
                        value={
                          commodity
                            ? {
                                label: `${commodity.title.en} - ${commodity.subtitle.en}`,
                                value: commodity._id.toString(),
                              }
                            : supplierOrderDoc
                            ? {
                                label: `${supplierOrderDoc.commodity.title.en} - ${supplierOrderDoc.commodity.subtitle.en}`,
                                value: supplierOrderDoc.commodity._id.toString(),
                              }
                            : undefined
                        }
                      />
                    </div>
                    <div className="col-xl-4 mt-2">
                      <label className="fs-5 fw-bold mb-2">Total Commodity Amount</label>
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control"
                          type={"number"}
                          min={0}
                          value={totalNetWeight}
                          onChange={this.handleChangeNetWeight}
                        />
                        <div className="input-group-append rounded-end bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            kg
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 mt-2">
                      <label className="fs-5 fw-bold mb-2">{reverse ? Incoterm.DDP : Incoterm.FOB} Price per Kg</label>
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control"
                          type={"number"}
                          min={0}
                          name={"pricePerUnit"}
                          value={pricePerUnit}
                          onChange={this.handleChangePrice}
                        />
                        <div className="input-group-append rounded-end bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            {USD}/kg
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 mt-2">
                      <label className="fs-5 fw-bold mb-2">{reverse ? Incoterm.DDP : Incoterm.FOB} Total Price</label>
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control"
                          type={"number"}
                          min={0}
                          name={"totalPrice"}
                          value={totalPrice}
                          onChange={this.handleChangeTotalPrice}
                        />
                        <div className="input-group-append rounded-end bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            {USD}/kg
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <label className="fs-5 fw-bold mb-2">
                        {type === P_CALC_WAREHOUSE
                          ? "Purchase Order / Batch (optional)"
                          : type === CALC_AIRFREIGHT
                          ? "Airport (optional)"
                          : "Seaport (optional)"}
                      </label>
                      {type === P_CALC_WAREHOUSE ? (
                        <CustomSelect
                          options={warehouseSupplierOrders}
                          onChange={this.handleSelectSupplierOrder}
                          matchFormControl={true}
                          isClearable={true}
                          placeholder={"Select..."}
                          value={supplierOrder}
                        />
                      ) : (
                        <CustomSelect
                          options={
                            type === CALC_AIRFREIGHT
                              ? getStartingAirports(context.airport).map((a) => {
                                  return {
                                    value: a._id.toString(),
                                    label: getAirportName(a),
                                    object: a,
                                  };
                                })
                              : getStartingSeaports(context.seaport).map((s) => {
                                  return { value: s._id.toString(), label: getSeaportName(s), object: s };
                                })
                          }
                          onChange={this.handleSelectPort}
                          matchFormControl={true}
                          isClearable={true}
                          placeholder={"Select..."}
                          value={
                            port
                              ? {
                                  value: port._id.toString(),
                                  label: "locode" in port ? getSeaportName(port) : getAirportName(port),
                                  object: port,
                                }
                              : null
                          }
                        />
                      )}
                    </div>
                  </div>
                  {isLCLSeaFreightValues(calculationValues) && (
                    <LCLSeaFreightCalculationView
                      ref={this.viewRef as React.RefObject<LCLSeaFreightCalculationView>}
                      totalAmount={totalNetWeight}
                      calculationValues={calculationValues}
                    />
                  )}
                  {isAirFreightValues(calculationValues) && (
                    <AirFreightCalculationView
                      ref={this.viewRef as React.RefObject<AirFreightCalculationView>}
                      totalAmount={totalNetWeight}
                      commodity={commodity}
                      isCalculator={true}
                      calculationValues={calculationValues}
                    />
                  )}
                  {isSeaFreightValues(calculationValues) && (
                    <SeaFreightCalculationView
                      ref={this.viewRef as React.RefObject<SeaFreightCalculationView>}
                      totalAmount={totalNetWeight}
                      commodity={commodity}
                      isCalculator={true}
                      calculationValues={calculationValues}
                    />
                  )}
                  {isWarehouseValues(calculationValues) && (
                    <WarehouseCalculationView
                      ref={this.viewRef as React.RefObject<WarehouseCalculationView>}
                      calculationValues={calculationValues}
                    />
                  )}
                </div>
                {priceCalculation && !reverse && (
                  <>
                    <h3 className="mb-0 mt-7 text-center text-white ">Final Price:</h3>
                    {priceWithMargin !== undefined && (
                      <h1 className="mt-2 text-center fs-2rem">
                        <span className="text-success text-decoration-underline">
                          {formatCurrency(priceWithMargin, targetCurrency)}
                        </span>
                        <span className="ml-2" style={{ position: "relative", width: 0, height: 0 }}>
                          <span className="text-sm-left" style={{ position: "absolute", minWidth: "300px" }}>
                            (+
                            <input
                              className="input-no-style hide-arrows bg-dark"
                              type={"number"}
                              style={{ width: "2ch" }}
                              min={0}
                              name={"marginPercent"}
                              onChange={this.handleChangeMargin}
                              value={marginPercent}
                            />
                            %)
                          </span>
                        </span>
                      </h1>
                    )}
                    {priceWithMargin !== undefined && (
                      <h1 className="mt-0 text-center text-success fs-2rem text-decoration-underline">
                        {formatCurrency(priceWithMargin / totalNetWeight, targetCurrency)}/{commodity?.unit || "kg"}
                      </h1>
                    )}
                    <h1 className="mt-2 text-center text-success " style={{ fontSize: "1.5rem" }}>
                      {formatCurrency(
                        isWarehouseCalculation(priceCalculation)
                          ? priceCalculation.totalCost - priceCalculation.totalIncludedMargin
                          : priceCalculation.totalCost,
                        targetCurrency
                      )}
                      {exchangeRatesAdjusted && (
                        <Tooltip
                          tooltipText={
                            <span className="text-warning fw-bold ">
                              Exchange rates have been adjusted. Prices may vary.
                            </span>
                          }
                        >
                          <i className="fas fa-exclamation-triangle text-warning ml-1" style={{ fontSize: "18px" }} />
                        </Tooltip>
                      )}
                    </h1>
                    {!isWarehouseCalculation(priceCalculation) && (
                      <h1 className="mb-5 mt-2 text-center text-success " style={{ fontSize: "1.2rem" }}>
                        <span className="text-white">Shipping:</span>{" "}
                        {formatCurrency(getTotalTransportCost(priceCalculation), targetCurrency)}
                      </h1>
                    )}
                  </>
                )}
                {priceCalculation ? (
                  reverse && !isWarehouseCalculation(priceCalculation) ? (
                    <>
                      <h3 className="mb-0 mt-7 text-center text-white ">Estimated FOB Price:</h3>
                      <h1 className="mb-1 mt-2 text-center text-success " style={{ fontSize: "1.5rem" }}>
                        {formatCurrency(priceCalculation.totalCommodityPrice, targetCurrency)}
                      </h1>
                      <h1 className="text-center text-success " style={{ fontSize: "1.5rem" }}>
                        {formatCurrency(priceCalculation.totalPricePerUnit, targetCurrency)}/kg
                      </h1>
                      <h1 className="text-center text-success " style={{ fontSize: "1.2rem" }}>
                        <span className="text-white">Shipping:</span>{" "}
                        {formatCurrency(getTotalTransportCost(priceCalculation), targetCurrency)}
                      </h1>
                      {priceCalculation.warehouseHandlingCost && (
                        <h1 className="text-center text-success " style={{ fontSize: "1.2rem" }}>
                          <span className="text-white">Warehouse Handling:</span>{" "}
                          {formatCurrency(priceCalculation.warehouseHandlingCost, targetCurrency)}
                        </h1>
                      )}
                      <div className="fs-6 text-center mt-5">
                        <span className="text-white mr-2">Exchange Rates:</span>
                        <span className="text-white fw-bold">
                          <span>1€ =&nbsp;</span>
                          <ExchangeRatesInput
                            exchangeRates={exchangeRates}
                            onChangeExchangeRate={this.handleChangeExchangeRate}
                          />
                        </span>
                      </div>
                    </>
                  ) : isLCLSeaFreightCalculation(priceCalculation) ? (
                    <div className="row fs-6">
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Price Per KG:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalPricePerUnit, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Purchase Price:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalCommodityPrice, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Customs Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.customsCost.total, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Dockage:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.dockage, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">B2B Follow-Up Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.b2bFollowUpCost, targetCurrency)} (for{" "}
                          {`${pluralize(priceCalculation.baseValues.palettes, "palette")}`})
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Sea Freight:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.seaFreight.cost, targetCurrency)} (for{" "}
                          {priceCalculation.seaFreight.cbm
                            ? `${priceCalculation.baseValues.cbm} m³`
                            : `${round(priceCalculation.baseValues.weight / 1000, 2)} t`}
                          )
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">LCL Charges:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.lclCharges.cost, targetCurrency)} (for{" "}
                          {priceCalculation.lclCharges.cbm
                            ? `${priceCalculation.baseValues.cbm} m³`
                            : `${round(priceCalculation.baseValues.weight / 1000, 2)} t`}
                          )
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Warehouse Handling:</span>
                        <span className="text-white fw-bold">
                          {priceCalculation.warehouseHandlingCost
                            ? formatCurrency(priceCalculation.warehouseHandlingCost, targetCurrency)
                            : "-"}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Exchange Rate:</span>
                        <span className="text-white fw-bold">1€ = {priceCalculation.exchangeRate}$</span>
                      </div>
                    </div>
                  ) : isSeaFreightCalculation(priceCalculation) ? (
                    <div className="row fs-6">
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Price Per KG:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalPricePerUnit, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Purchase Price:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalCommodityPrice, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Customs Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.customsCost.total, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">B2B Follow-Up Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.b2bFollowUpCost, targetCurrency)} (for{" "}
                          {`${pluralize(priceCalculation.baseValues.palettes, "palette")}`})
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">FCL Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.fclCost.total, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">LCL Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.lclCost.total, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Warehouse Handling:</span>
                        <span className="text-white fw-bold">
                          {priceCalculation.warehouseHandlingCost
                            ? formatCurrency(priceCalculation.warehouseHandlingCost, targetCurrency)
                            : "-"}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Exchange Rates:</span>
                        <span className="text-white fw-bold">
                          <span>1€ =&nbsp;</span>
                          <ExchangeRatesInput
                            exchangeRates={exchangeRates}
                            onChangeExchangeRate={this.handleChangeExchangeRate}
                          />
                        </span>
                      </div>
                    </div>
                  ) : isAirFreightCalculation(priceCalculation) ? (
                    <div className="row fs-6">
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Price Per KG:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalPricePerUnit, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Purchase Price:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalCommodityPrice, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Air Freight Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.airFreightCost, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Follow-Up Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.followUpCost, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">B2B Follow-Up Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.b2bFollowUpCost, targetCurrency)} (for{" "}
                          {`${pluralize(priceCalculation.baseValues.palettes, "palette")}`})
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Airport Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.airportCost, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Customs Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.duty + priceCalculation.additionalFee, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Warehouse Handling:</span>
                        <span className="text-white fw-bold">
                          {priceCalculation.warehouseHandlingCost
                            ? formatCurrency(priceCalculation.warehouseHandlingCost, targetCurrency)
                            : "-"}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Exchange Rates:</span>
                        <span className="text-white fw-bold">
                          <span>1€ =&nbsp;</span>
                          <ExchangeRatesInput
                            exchangeRates={exchangeRates}
                            onChangeExchangeRate={this.handleChangeExchangeRate}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="row fs-6">
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Price Per KG:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalPricePerUnit, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Purchase Price:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalCommodityPrice, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Included Margin:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalIncludedMargin, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-left">
                        <span className="text-white mr-2">Included Logistic Cost:</span>
                        <span className="text-white fw-bold">
                          {formatCurrency(priceCalculation.totalLogisticCost, targetCurrency)}
                        </span>
                      </div>
                      <div className="col-12 col-sm-6 text-sm-right">
                        <span className="text-white mr-2">Exchange Rates:</span>
                        <span className="text-white fw-bold">
                          <span>1€ =&nbsp;</span>
                          <ExchangeRatesInput
                            exchangeRates={exchangeRates}
                            onChangeExchangeRate={this.handleChangeExchangeRate}
                          />
                        </span>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
              <div className="card-footer border-none">
                <ErrorOverlayButton
                  errors={errors}
                  className="btn btn-outline btn-outline-light btn-sm w-100"
                  buttonText={"Calculate"}
                  onClick={this.handleCalculate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface ExchangeRatesInputProps {
  exchangeRates: Currencies;
  onChangeExchangeRate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ExchangeRatesInput: React.FC<ExchangeRatesInputProps> = ({ exchangeRates, onChangeExchangeRate }) => {
  const relevantExchangeRates = useMemo(
    () => Object.entries(getRelevantCurrencyExchangeRates(exchangeRates, [])).filter((c) => c[0] !== EURO),
    [exchangeRates]
  );
  return (
    <span className="position-absolute">
      <div className="d-flex">
        {relevantExchangeRates.map(([currency, exchangeRate], idx) => (
          <div key={currency} className="d-flex">
            <input
              className="input-no-style hide-arrows bg-dark"
              style={{ width: exchangeRate.toString().length.toString() + "ch" }}
              type="number"
              name={currency}
              min={0}
              onChange={onChangeExchangeRate}
              value={exchangeRate}
            />
            {currency}
            {idx !== relevantExchangeRates.length - 1 && <>&nbsp;/&nbsp;</>}
          </div>
        ))}
      </div>
    </span>
  );
};

interface PriceCalculatorWrapperProps {}

const PriceCalculatorWrapper: React.FunctionComponent<PriceCalculatorWrapperProps> = (props) => {
  const context = useContext(DataContextInternal);
  return <PriceCalculator {...props} context={context} />;
};

export default PriceCalculatorWrapper;
