import React, { useMemo } from "react";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import Tooltip from "../../../common/Tooltip";

interface YearSelectorProps {
  year: number;
  onChangeYear: (year: number) => void;
}

const YearSelector: React.FC<YearSelectorProps> = ({ year, onChangeYear }) => {
  const yearOptions = useMemo(() => {
    const options: Array<SelectOption> = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 2; i++) {
      options.push({ value: (currentYear - i).toString(), label: (currentYear - i).toString() });
    }
    return options;
  }, []);

  return (
    <div className="card bg-white rounded mb-3">
      <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-2 text-right">
        <div className="px-9">
          <div className="row mb-2">
            <div className="col-6 col-md-8 col-lg-10 col-xl-11 pr-0 align-self-center">
              <Tooltip
                tooltipText={
                  <span className="text-white">
                    Adjusting the year affects the graphs for turnover, margin, warehouse, expenses and the turnover
                    development.
                  </span>
                }
              >
                <i className="fa fa-info-circle fa-2x text-white" />
              </Tooltip>
            </div>
            <div className="col-6 col-md-4 col-lg-2 col-xl-1">
              <CustomSelect
                options={yearOptions}
                value={{ value: year.toString(), label: year.toString() }}
                onChange={(e) => onChangeYear(Number(e.value))}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearSelector;
