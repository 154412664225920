import React, { useState } from "react";
import { toast } from "react-toastify";
import { CO_T_REMOVEDSUPPLIER, CustomerOrderExtended } from "../../../../../model/customerOrder.types";
import SimpleConfirmationModal from "../../../../common/SimpleConfirmationModal";
import { getOrderNumber } from "../../../../../utils/orderUtils";
import { Action, CUSTOMERORDER, transaction } from "../../../../../services/dbService";
import { getCustomerOrderTimelineEntry } from "../../../../../utils/customerOrderUtils";

interface RemoveSupplierModalProps {
  order: CustomerOrderExtended;
}

const RemoveSupplierModal: React.FunctionComponent<RemoveSupplierModalProps> = ({ order }) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleRemoveSupplier = async () => {
    setSaving(true);
    try {
      const action: Array<Action> = [];
      action.push({
        collection: CUSTOMERORDER,
        filter: { _id: order._id },
        update: {
          supplier: null,
        },
        push: {
          timeline: {
            $each: [getCustomerOrderTimelineEntry(CO_T_REMOVEDSUPPLIER)],
          },
        },
      });
      const res = await transaction(action);
      if (res) {
        toast.success("Order successfully updated");
      }
    } catch (e) {
      console.error("Error removing supplier: ", e);
      toast.error("Error removing supplier, please try again later");
    } finally {
      setSaving(false);
    }
    setShow(false);
  };

  return (
    <>
      <button className="btn btn-icon btn-sm p-0  h-auto w-auto align-middle mb-1 ml-1" onClick={handleShow}>
        <i className="fa fa-trash text-gray-300 text-hover-white p-0" />
      </button>
      <SimpleConfirmationModal.SimpleConfirmationModal
        show={show}
        modalTitle="Remove supplier"
        size="md"
        saving={saving}
        cancelButtonText="Close"
        confirmButtonText="Confirm"
        modalDescription={
          <span className="text-white">
            <h4 className="fw-bolder text-white text-left">
              Do you really want to remove Supplier from {getOrderNumber(order)}?
            </h4>
            <h4 className="fw-bolder text-white text-left mt-5">This operation is permanent and cannot be revoked!</h4>
          </span>
        }
        onClose={handleHide}
        onConfirm={handleRemoveSupplier}
      />
    </>
  );
};

export default RemoveSupplierModal;
