import countries from "i18n-iso-countries";
import React from "react";
import { BSON } from "realm-web";
import { AddressType, Address } from "../model/commonTypes";
import { getCountryNameForCode } from "./baseUtils";
import { Notify } from "../model/notify.types";
import { DataContextInternal, DataContextType } from "../context/dataContext";
import { Property } from "../model/property.types";
import { Airport } from "../model/airport.types";
import { Seaport } from "../model/seaport.types";
import { SelectOption } from "../components/common/CustomSelect";

export interface AddressSelectOption extends SelectOption {
  address: Address;
}

export interface GlommAddress {
  addressString: string;
  address: Address;
}

export const STANDARDWAREHOUSE_NOTIFY_ID = "64d4aab2f4c901aad1c6962d";

/**
 * Helper function to check an address for being too short.
 * @param address Address that should be checked
 * @returns { boolean } Indicates whether an address is too short or not
 */
export function isAddressTooShort(address: Address): boolean {
  return (
    address.street?.trim().length < 2 ||
    !address.houseNo?.trim() ||
    address.postalCode?.trim().length < 3 ||
    address.city?.length < 2
  );
}

/**
 * Retrieves the address from a list of addresses that matches the specified type, if none with that type is found it
 * is tried to return the primary address.
 * @param addresses Array of addresses that should be checked for the shipping address
 * @param type Type that is looked for
 * @param allowFallback Optional, if set the first address is returned in case that neither type not primary are found
 * @returns { Address | undefined } Address, if found - undefined if the array was empty
 */
export function getAddressByType(
  addresses: Array<Address> | undefined,
  type: AddressType,
  allowFallback?: boolean
): Address | undefined {
  if (!addresses || addresses.length === 0) return;
  let addr = addresses.find((a) => "type" in a && a.type === type);
  if (!addr) addr = addresses.find((a) => "type" in a && a.type === AddressType.A_PRIMARY);
  if (!addr && allowFallback) return addresses[0];
  return addr;
}

/**
 * Formats the given address into a single string.
 * @param address Address that should be formatted
 * @param options Optional, contains multiple options to adjust the result
 * * withoutBreak: Replace explicit line breaks
 * * withoutCountry: Do not include the country name
 * * withComma: Add commas instead of line breaks
 * * withBrTag: Use br tag vor line breaks, necessary for pdf creation
 * @returns { string } Address as single string
 */
export function formatAddress(
  address: Address,
  options?: { withoutBreak?: boolean; withoutCountry?: boolean; withComma?: boolean; withBrTag?: boolean }
): string {
  const { name, street, houseNo, postalCode, city, country } = address;
  let formattedAddress = "";
  const end = options?.withoutBreak ? " " : "\n";
  const separator = options?.withComma ? ", " : options?.withBrTag ? "<br/>" : "\n";
  if (name.trim()) formattedAddress = `${name} ${end}`;
  formattedAddress += `${street} ${houseNo}${separator} ${postalCode} ${city}`;
  if (!options?.withoutCountry) {
    const countryName = getCountryNameForCode(country);
    formattedAddress += `${separator}${countryName ?? "unknown"}`;
  }
  if (options?.withComma) {
    formattedAddress = formattedAddress.replace("\n", ", ");
  }
  return formattedAddress;
}

/**
 * Returns an address as string if it already was a string or as formatted address string if it was an address
 * @param address Address as string or Address
 * @returns { string } original string if it was a string, formatted address string (without breaks) if it was an address, "" if no match was found
 */
export function getAddressString(address: string | Address): string {
  if (typeof address === "string") {
    return address;
  }
  if (isAddress(address)) {
    return formatAddress(address, { withoutBreak: true, withComma: true });
  }
  return "";
}

/**
 * Checks if address is empty.
 * @param address Address that should be checked
 * @param checkPartial Optional, if set one empty field is enough for the address being considered as empty
 * @returns { boolean } Indicating whether the address is empty or not
 */
export function isEmptyAddress(address: Address, checkPartial?: boolean): boolean {
  const { street, houseNo, postalCode, city, country } = address;
  const checks = [!street.trim(), !houseNo.trim(), !postalCode.trim(), !city.trim(), !country.trim()];
  if (checkPartial) return checks.some((v) => v);
  return checks.every((v) => v);
}

/**
 * Check if given object is an address
 * @param obj the object to check
 * @returns {boolean} true if object is an Address, else false
 */
export function isAddress(obj: Airport | Seaport | Address | string): obj is Address {
  if (typeof obj === "string") return false;
  return "houseNo" in obj;
}

/**
 * Returns a default address object.
 * @param country Optional, if set this is used for the country field
 * @param type Optional, if used this is used for the type field
 * @returns { Address } Default address
 */
export function getDefaultAddress(country?: string, type?: AddressType): Address {
  return {
    _id: new BSON.ObjectId(),
    name: "",
    street: "",
    houseNo: "",
    postalCode: "",
    city: "",
    type: type ?? AddressType.A_OTHER,
    country: country ?? Object.values(countries.getAlpha2Codes())[0],
  };
}

/**
 * Get standard warehouse address object including address and string address, or undefined if not found
 * @param context internal context
 * @param withLineBreak optional, if true add a line break after company name
 * @returns {GlommAddress | undefined} standard warehouse address including the address as formatted string as well as the address object; undefined if not found
 */
export function getStandardWarehouseAddress(
  context: React.ContextType<typeof DataContextInternal>,
  withLineBreak?: boolean
): GlommAddress | undefined {
  const warehouse = getStandardWarehouse(context);
  if (warehouse) {
    return {
      addressString: warehouse.companyName + `${withLineBreak ? "\n" : " "}` + formatAddress(warehouse.address),
      address: warehouse.address,
    };
  }
}

/**
 * Get standard warehouse from notify collection or undefined if not found
 * @param context the internal context
 * @returns {Notify} the standard warehouse as Notify
 */
export function getStandardWarehouse(context: React.ContextType<typeof DataContextInternal>): Notify | undefined {
  return context.notify.find((n) => n._id.toString() === STANDARDWAREHOUSE_NOTIFY_ID);
}

/**
 * Get a property from an address
 * @param address address to get the property from
 * @param propertyType the property type to get
 * @param context the internal context
 * @param all flag if all properties or only the first appearance should be returned
 * @returns { null | undefined | Property | Array<Property>} the matching property/properties or null/undefined
 */
export const getAddressProperty = (
  address: Address | undefined,
  propertyType: string,
  context: DataContextType,
  all?: boolean
): null | undefined | Property | Array<Property> => {
  if (!address || !address.properties || !context) return null;
  const addressProperties = address.properties
    .map((propId) => context.property.find((prop) => prop._id.toString() === propId))
    .filter(Boolean) as Property[];
  if (!addressProperties) return null;
  if (all) return addressProperties.filter((p) => p.type === propertyType);
  return addressProperties.find((p) => p.type === propertyType);
};
