import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import { createStockListingHTML, StockListingRow } from "../../utils/pdf/stockListingGenerationUtils";
import { BatchState } from "../../model/batch.types";
import { formatArticleUnit } from "../../utils/productArticleUtils";
import { isFinishedProduct } from "../../utils/finishedProductUtils";
import { getAvailableAndIncomingStock } from "../../utils/customerOrderUtils";
import { createPDF } from "../../utils/pdfUtils";
import { resolveFilePath } from "../../utils/fileUtils";
import { DataContextInternal } from "../../context/dataContext";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";

const GenerateStockListing: React.FC = () => {
  const { batch } = useContext(DataContextInternal);

  const [loading, setLoading] = useState<boolean>(false);

  const handleExportStockListing = useCallback(async () => {
    setLoading(true);
    const articleStocks: Array<StockListingRow> = [];
    try {
      const relatedArticlesMap = new Map();
      batch
        .filter((batchItem) => batchItem.state === BatchState.RELEASED)
        .forEach((filteredBatchItem) => {
          const id = filteredBatchItem.commodity._id.toString();
          const title = filteredBatchItem.commodity.title.en;
          const unit = formatArticleUnit(filteredBatchItem.commodity.unit, filteredBatchItem.commodity);

          if (!relatedArticlesMap.has(id)) {
            relatedArticlesMap.set(id, {
              id,
              title,
              unit,
              isFinishedProduct: isFinishedProduct(filteredBatchItem.commodity),
            });
          }
        });
      const relatedArticleIds: Array<{ id: string; title: string; unit: string; isFinishedProduct: boolean }> =
        Array.from(relatedArticlesMap.values());
      const stockDataPromises = relatedArticleIds.map(async (article) => {
        const availableStock = await getAvailableAndIncomingStock(article.id, true);
        return {
          id: article.id,
          title: article.title,
          availableStock: availableStock[0],
          unit: article.unit,
          isFinishedProduct: article.isFinishedProduct,
        };
      });
      const resolvedStockData = await Promise.all(stockDataPromises);
      articleStocks.push(...resolvedStockData);
      const filteredArticleStocks = articleStocks.filter((articleStock) => articleStock.availableStock > 0);

      const path = await createPDF(createStockListingHTML(filteredArticleStocks), "AvailableStockListing");
      if (path) {
        window.open(resolveFilePath(path));
      } else {
        toast.error(`PDF could not be generated`);
      }
    } catch (e) {
      toast.error("An Error occurred: " + e);
    } finally {
      setLoading(false);
    }
  }, [batch]);

  return (
    <SimpleConfirmationModal.SimpleConfirmationModalButton
      onConfirm={handleExportStockListing}
      buttonClasses="btn btn-outline btn-outline-light float-right mr-2 align-items-center"
      disabled={loading}
      saving={loading}
      confirmButtonText="Generate"
      buttonText="Gen. Stock Listing"
      cancelButtonText="Close"
      modalTitle="Generate Stock Listing"
      modalDescription={
        <span className="text-white">Do you want to generate a list of all available stock entries?</span>
      }
      size="md"
    />
  );
};

export default GenerateStockListing;
