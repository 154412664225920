import { BSON } from "realm-web";
import {
  CustomerOrder,
  CustomerOrderExtended,
  CustomerOrderShippingGroup,
  CustomerOrderShippingInformation,
} from "../model/customerOrder.types";
import { Batch } from "../model/batch.types";
import { C_PACKAGE_OPTIONS } from "./commodityUtils";
import { isAnyFinishedProduct } from "./productArticleUtils";
import { isAddress } from "./addressUtils";

export const DI_UNITLOAD = [
  { label: "Pallet", value: "pallet" },
  { label: "Carton", value: "carton" },
];

/**
 * Get default shipping information object
 * @param order customer order
 * @param batches Optional, list of batches
 * @returns {CustomerOrderShippingInformation} shipping information object
 */
export function getDefaultShippingInformation(
  order: CustomerOrderExtended,
  batches?: Array<Batch>
): CustomerOrderShippingInformation {
  return {
    shipVia: "",
    estimatedDeliveryDate: new Date(new Date().setDate(new Date().getDate() + 5)),
    additionalInformation: isAddress(order.destination) ? order.destination.openingHours || "" : "",
    unitLoad: DI_UNITLOAD[0].value,
    unitLoadAmount: 1,
    shippingGroups: batches ? getShippingGroupsFromBatch(order, batches) : [getDefaultShippingGroup(order)],
    grossWeight: 0,
  };
}

/**
 * Get default shipping group
 * @param order customer order
 * @param batches list of batches
 * @returns {CustomerOrderShippingGroup} a shipping group object
 */
export function getDefaultShippingGroup(
  order: CustomerOrder | CustomerOrderExtended,
  batches?: Array<Batch>
): CustomerOrderShippingGroup {
  const expiry =
    batches && batches.length > 0 ? batches[0].expiry : new Date(new Date().setFullYear(new Date().getFullYear() + 2));
  return {
    _id: new BSON.ObjectId(),
    description: order.commodity.title.en,
    lot: batches && batches.length > 0 ? `RB${batches[0].identifier}` : "-",
    supplierLot: batches && batches.length > 0 ? batches[0]?.lot : "-",
    expiry,
    packageType: order.commodity.packagingSizes ? order.commodity.packagingSizes[0].type : C_PACKAGE_OPTIONS[0].value,
    packageAmount: 1,
    packageNetWeight: order.commodity.packagingSizes
      ? isAnyFinishedProduct(order.commodity)
        ? order.commodity.packagingSizes[0].grossWeight // for finished products packagingSize is per 1000 pcs, so we use grossWeight here instead
        : order.commodity.packagingSizes[0].packagingSize
      : 25,
  };
}

/**
 * Get all shipping groups of batch
 * @param order customer order
 * @param allBatches all batches that are available for this customer order
 * @returns {Array<CustomerOrderShippingGroup>} a shipping group object array
 */
export function getShippingGroupsFromBatch(
  order: CustomerOrder | CustomerOrderExtended,
  allBatches: Array<Batch>
): Array<CustomerOrderShippingGroup> {
  const batches = order.usedBatches;
  const shippingGroups: Array<CustomerOrderShippingGroup> = [];
  if (batches) {
    for (let i = 0; i < batches.length; i++) {
      const differentPackages: Array<{ amountEach: number; packageType: string; number: number }> = [];
      for (let j = 0; j < batches[i].packages.length; j++) {
        if (
          differentPackages.some(
            (dp) =>
              dp.packageType === batches[i].packages[j].packageType &&
              dp.amountEach === batches[i].packages[j].amountEach
          )
        ) {
          const batchPackage = differentPackages.find(
            (dp) =>
              dp.packageType === batches[i].packages[j].packageType &&
              dp.amountEach === batches[i].packages[j].amountEach
          );
          if (batchPackage) {
            batchPackage.number++;
          }
        } else {
          differentPackages.push({
            amountEach: batches[i].packages[j].amountEach,
            packageType: batches[i].packages[j].packageType,
            number: 1,
          });
        }
      }
      const batch = allBatches.find((aB) => aB._id.toString() === batches[i]._id.toString());
      const expiry = batch ? batch.expiry : new Date(new Date().setFullYear(new Date().getFullYear() + 2));
      for (let k = 0; k < differentPackages.length; k++) {
        shippingGroups.push({
          _id: new BSON.ObjectId(),
          description: order.commodity.title.en,
          lot: batches && batches.length > 0 ? `RB${batches[i].identifier}` : "-",
          supplierLot: batches[i].supplierLot || "",
          expiry: batches[i].expiry || expiry,
          packageType: differentPackages[k].packageType,
          packageAmount: differentPackages[k].number,
          packageNetWeight: isAnyFinishedProduct(order.commodity)
            ? differentPackages[k].amountEach * order.commodity.weightPerUnit // amountEach for finished products is in batch unit which is 1000 pcs
            : differentPackages[k].amountEach,
        });
      }
    }
  }
  return shippingGroups;
}
