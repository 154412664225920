import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Content, ContentPrepared, VersionHistory, VersionHistoryType } from "../../../model/versionHistory.types";
import userService from "../../../services/userService";
import { ANONYMOUS, CUSTOMER, INTERNAL, SUPPLIER } from "../../../utils/userUtils";
import { AppDesign, formatDate } from "../../../utils/baseUtils";

interface VersionHistoryModalProps {
  versionHistory: Array<VersionHistory>;
}

interface VersionHistoryModalState {
  historyFiltered: Array<VersionHistory>;
  show: boolean;
}

class VersionHistoryModal extends PureComponent<VersionHistoryModalProps, VersionHistoryModalState> {
  constructor(props: VersionHistoryModalProps) {
    super(props);
    const historyFiltered = this.filterVersionHistory(props);
    this.state = { show: false, historyFiltered };
  }

  componentDidUpdate(prevProps: Readonly<VersionHistoryModalProps>) {
    if (!_.isEqual(prevProps.versionHistory, this.props.versionHistory)) {
      const historyFiltered = this.filterVersionHistory(this.props);
      this.setState({ historyFiltered });
    }
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  /**
   * Sort, slice and filter the version history
   * @param props Props of the component
   * @returns {Array<VersionHistory>} Sorted, sliced and filtered version history
   */
  filterVersionHistory = (props: VersionHistoryModalProps): Array<VersionHistory> => {
    const { versionHistory } = props;
    const sortedHistory = versionHistory.sort((h1, h2) => h2.date.getTime() - h1.date.getTime()).slice(0, 10);
    const userType = userService.getUserType();
    // We don't care that we adjust the reference, we will never need to filtered values inside this view
    for (const entry of sortedHistory) {
      entry.content = entry.content.filter(
        (c) =>
          userType === INTERNAL ||
          ([ANONYMOUS, CUSTOMER].includes(userType) && c.forCustomersVisible) ||
          (userType === SUPPLIER && c.forSuppliersVisible)
      );
    }
    return sortedHistory;
  };

  resolveVersionInformation = () => {
    if (process.env.REACT_APP_BASE_URL?.startsWith("demo")) {
      return { name: "Demo Dünsche", link: "https://de.wikipedia.org/wiki/D%C3%BCnsche", version: "1.demo.rawbids" };
    }
    if (process.env.NODE_ENV === "production") {
      return {
        prefix: process.env.REACT_APP_VERSION_VILLAGE_PREFIX,
        name: process.env.REACT_APP_VERSION_VILLAGE,
        link: process.env.REACT_APP_VERSION_VILLAGE_LINK,
        version: process.env.REACT_APP_VERSION,
      };
    }
    return {
      prefix: "Development",
      name: "Dolgow",
      link: "https://de.wikipedia.org/wiki/Dolgow_%28Dorf%29",
      version: "1.dev.rawbids",
    };
  };

  render() {
    const { historyFiltered, show } = this.state;
    const versionInformation = this.resolveVersionInformation();
    const useArkDesign = process.env.REACT_APP_DESIGN === AppDesign.ARK;

    return (
      <>
        <span className="menu-link px-2">
          <span onClick={this.handleShow}>
            {useArkDesign ? "ARK" : "Rawbids"} - v{versionInformation.version}
          </span>
          {userService.getUserType() === INTERNAL ? (
            <a className="text-muted ml-1" href={versionInformation.link} target="_blank" rel="noopener noreferrer">
              ({versionInformation.prefix + " " + versionInformation.name})
            </a>
          ) : (
            <span className="text-muted ml-1">({versionInformation.name})</span>
          )}
        </span>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Version History</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="overflow-auto" style={{ maxHeight: "80vh" }}>
            {historyFiltered.length === 0 ? (
              <div className="text-center text-muted my-5">No history available</div>
            ) : (
              historyFiltered.map((v) => (
                <React.Fragment key={v.version}>
                  <div className="row my-4">
                    <div className="col-12">
                      <span className="h3 text-white">
                        v{v.version} - {formatDate(v.date)}
                      </span>
                    </div>
                  </div>
                  <ContentListing content={v.content} />
                </React.Fragment>
              ))
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-secondary" onClick={this.handleHide}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default VersionHistoryModal;

interface ContentListingProps {
  content: Array<Content>;
}

class ContentListing extends PureComponent<ContentListingProps> {
  prepareContent = () => {
    let contentFiltered = this.props.content;
    const type = userService.getUserType();
    if (type === CUSTOMER) contentFiltered = contentFiltered.filter((c) => c.forCustomersVisible);
    else if (type === SUPPLIER) contentFiltered = contentFiltered.filter((c) => c.forSuppliersVisible);

    const preparedContent: ContentPrepared = { features: [], changes: [], bugfixes: [], general: [] };
    for (let i = 0; i < contentFiltered.length; i++) {
      const c = contentFiltered[i];
      if (c.type === VersionHistoryType.FEATURE) preparedContent.features.push(c);
      else if (c.type === VersionHistoryType.CHANGE) preparedContent.changes.push(c);
      else if (c.type === VersionHistoryType.BUG_FIX) preparedContent.bugfixes.push(c);
      else if (c.type === VersionHistoryType.GENERAL) preparedContent.general.push(c);
    }
    return preparedContent;
  };

  render() {
    const content = this.prepareContent();
    return (
      <div className="mb-8">
        {content.features.map((f, idx) => (
          <div key={idx} className="row my-1">
            <div className="col-2">
              <span className="text-white">{idx === 0 && <span>Features</span>}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{f.content}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{f.reason}</span>
            </div>
          </div>
        ))}
        {content.features.length > 0 && content.changes.length > 0 && <div className="border-bottom-dark-gray my-4" />}
        {content.changes.map((c, idx) => (
          <div key={idx} className="row my-1">
            <div className="col-2">
              <span className="text-white">{idx === 0 && <span>Changes</span>}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{c.content}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{c.reason}</span>
            </div>
          </div>
        ))}
        {(content.features.length > 0 || content.changes.length > 0) && content.bugfixes.length > 0 && (
          <div className="border-bottom-dark-gray my-4" />
        )}
        {content.bugfixes.map((b, idx) => (
          <div key={idx} className="row my-2">
            <div className="col-2">
              <span className="text-white">{idx === 0 && <span>Bugfixes</span>}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{b.content}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{b.reason}</span>
            </div>
          </div>
        ))}
        {(content.features.length > 0 || content.changes.length > 0 || content.bugfixes.length > 0) &&
          content.general.length > 0 && <div className="border-bottom-dark-gray my-4" />}
        {content.general.map((g, idx) => (
          <div key={idx} className="row my-1">
            <div className="col-2">
              <span className="text-white">{idx === 0 && <span>General</span>}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{g.content}</span>
            </div>
            <div className="col-5">
              <span className="text-white">{g.reason}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
