import { DateType, isCustomerOrder, isSupplierOrder } from "./orderUtils";
import { getCW } from "./dateUtils";
import { formatDate } from "./baseUtils";
import { I_PAYMENTTARGETS } from "./invoiceUtils";
import { Forwarder, ForwarderExtended } from "../model/forwarder.types";
import {
  CO_TYPES,
  CustomerOrder,
  CustomerOrderExtended,
  T_AIRFREIGHT,
  T_SEAFREIGHT,
  T_WAREHOUSE,
} from "../model/customerOrder.types";
import { SupplierOrder, SupplierOrderExtended } from "../model/supplierOrder.types";
import { Company } from "../model/company.types";
import { isCompany } from "./companyUtils";
import { isBackToBackOrder } from "./forwardingOrderUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { SupplierType } from "./supplierUtils";
import { calculateCBMAndGrossWeight } from "./priceCalculationUtils";
import { CalculationConfiguration } from "../model/configuration/calculationConfiguration.types";
import { CONFIG, getConfiguration } from "./configurationUtils";

export const FW_SORTOPTIONS = [
  { value: "forwardingOrderNo", label: "Forwarding Order Number" },
  { value: "deliveryDate", label: "Delivery Date" },
];

export const ST_SORTOPTIONS = [
  { value: "storageOrderNo", label: "Storage Order Number" },
  { value: "deliveryDate", label: "Delivery Date" },
];

export const LOGISTICSFILTER = [
  { value: "active", label: "Active orders" },
  { value: "canceled", label: "Order canceled" },
  { value: "all", label: "All" },
];

export interface TransportSelectOption extends Omit<SelectOption, "value"> {
  value: CO_TYPES;
}

export const TRANSPORT_SELECT: Array<TransportSelectOption> = [
  { label: "Sea Freight", value: T_SEAFREIGHT },
  { label: "Air Freight", value: T_AIRFREIGHT },
  { label: "Warehouse", value: T_WAREHOUSE },
];

export enum AdditionalDateTypeLogistics {
  ASAP = "asap",
}

export type DateTypeLogistics = AdditionalDateTypeLogistics | DateType;

/**
 * Returns a formatted string based on the given date format (asap/fixed/CW)
 * @param date the date to format
 * @param prefix optional, can be used if another prefix than "Week" should be used for the week display (e.g. "CW")
 * @param targetDateType optional, indicates as what the date should be formatted (CW, Fix, Asap)
 * @returns { string } the formatted target date string
 */
export function formatDateWithType(
  date: Date | null | undefined,
  targetDateType?: DateTypeLogistics,
  prefix?: string
): string {
  return targetDateType === AdditionalDateTypeLogistics.ASAP
    ? "Asap"
    : targetDateType === DateType.CW && date
    ? prefix ?? "Week " + getCW(date) + " " + date.getFullYear()
    : date
    ? formatDate(date) + ` ${targetDateType === DateType.FIX ? "FIX" : ""}`
    : "-";
}

/**
 * Returns a flag if given order has a fixed eta or target date
 * @param order Customer or supplier order
 * @returns {boolean} Flag if order has a fixed eta or target date
 */
export function hasFixedDate(order: CustomerOrderExtended | SupplierOrderExtended): boolean {
  return isCustomerOrder(order)
    ? order.changedETAType
      ? order.changedETAType === DateType.FIX
      : order.targetDateType === DateType.FIX
    : isBackToBackOrder(order) && isSupplierOrder(order)
    ? order.customerOrders[0].changedETAType
      ? order.customerOrders[0].changedETAType === DateType.FIX
      : order.customerOrders[0].targetDateType === DateType.FIX
    : false;
}

/**
 * Get the select option for the payment target
 * @param company the company, supplier or forwarder for which the payment target should be loaded
 * @param customPaymentTarget if the payment target is custom or not
 * @returns {SelectOption} the current paymentTarget SelectOption
 */
export const getPaymentTargetSelectOption = (
  company: Company | SupplierType | Forwarder | ForwarderExtended,
  customPaymentTarget: boolean
): SelectOption => {
  if (customPaymentTarget) {
    return { value: "custom", label: "Custom" };
  } else if (!customPaymentTarget && company.paymentTerms) {
    if (isCompany(company)) {
      // for company, label needs to be checked
      // other option should not be an option since it would be custom then
      return I_PAYMENTTARGETS.find((pt) => pt.label === company.paymentTerms?.paymentTarget) || I_PAYMENTTARGETS[4];
    } else {
      return I_PAYMENTTARGETS.find((pt) => pt.value === company.paymentTerms?.paymentTarget) || I_PAYMENTTARGETS[4];
    }
  } else if (
    !customPaymentTarget &&
    isCompany(company) &&
    company.paymentTarget !== undefined &&
    company.paymentTarget !== null
  ) {
    return I_PAYMENTTARGETS.find((pt) => pt.value === company.paymentTarget?.toString()) || I_PAYMENTTARGETS[4]; // other option should not be an option since it would be custom then
  } else {
    return { value: "", label: "Not specified" };
  }
};

/**
 * Get gross weight for forwarding or storage order by given order and net weight
 * @param order The supplier or customer order
 * @param netWeight The net weight that should be calculated
 * @returns {Promise<number>} The gross weight for the corresponding netWeight
 */
export const getGrossWeight = async (
  order: SupplierOrderExtended | CustomerOrderExtended | SupplierOrder | CustomerOrder,
  netWeight: number
): Promise<number> => {
  if (isSupplierOrder(order) && order.calculationDetails) {
    return calculateCBMAndGrossWeight(netWeight, order.calculationDetails.details.baseValues.paletteData)[1];
  } else {
    // for stock orders or supplier orders without calculationDetails, default palette is used
    const config: CalculationConfiguration = await getConfiguration(CONFIG.CALCULATION);
    const defaultPalette = config.values.generalValues.defaultPalette;
    return calculateCBMAndGrossWeight(netWeight, defaultPalette)[1]; // 1 is the grossWeight
  }
};
