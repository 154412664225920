import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PropertyType } from "../../../../utils/propertyUtils";
import PropertiesWidget from "../../../common/PropertiesWidget";
import CommodityPageAdvancedInformationBlock from "../../common/CommodityPageAdvancedInformationBlock";
import CommodityPageGeneralInformationBlock from "../../common/CommodityPageGeneralInformationBlock";
import CreateCustomerOrder from "../CreateCustomerOrder";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../../context/dataContext";
import CommodityActiveSubstanceListing from "../../common/CommodityActiveSubstanceListing";
import { C_G_TYPES } from "../../../../utils/commodityUtils";
import CommoditySpecifications from "../CommoditySpecifications";
import Tooltip from "../../../common/Tooltip";
import FavoriteStar from "../../../common/FavoriteStar";
import CreateCustomerOrderAnonymous from "../../anonymous/CreateCustomerOrderAnonymous";
import { hasPricesInAnonymousView } from "../../../../utils/anonymousViewUtils";
import { CustomerArticleExtended } from "../../../../utils/productArticleUtils";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import userService from "../../../../services/userService";

interface CommodityPageGeneralProps extends RouteComponentProps {
  article: CustomerArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CommodityPageGeneral: React.FC<CommodityPageGeneralProps> = (props) => {
  // const priceGraduationRef?: CommodityPagePriceGraduation; Temporarily unused RB-453

  const { article, context } = props;
  const [search, setSearch] = useState<string>("");

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), []);

  const handleBlurAmount = useCallback(
    (amount: number) => {
      // if (priceGraduationRef) priceGraduationRef.handleChangeAmount(amount); Temporarily unused RB-453
    },
    [
      // priceGraduationRef - Temporarily unused RB-453
    ]
  );

  // Temporarily unused RB-453: see below
  // setRef = (ref: CommodityPagePriceGraduation | null) => {
  //   if (ref) this.priceGraduationRef = ref;
  // };

  const company = useMemo(() => getDocFromCollection(context.company, userService.getCompany()), [context.company]);
  // Should not happen but a little sanity is fine
  if (!company) return <></>;

  return (
    <>
      <div className="card bg-white">
        <div className="d-flex flex-column px-9 py-4 bg-light2">
          <h3>
            {article.disabled && <span className="text-danger">[DISABLED]</span>}
            {!article.approved && !article.disabled && <span className="text-warning">[PENDING]</span>}
          </h3>
          <div className="d-flex align-items-center justify-content-between mb-1 w-100">
            <div className="d-flex flex-column">
              <span className="fs-1 text-gray-800 fw-bolder">{article.title.en}</span>
              <div className="fs-4 text-muted">{article.subtitle.en}</div>
            </div>
            {!isAnonymousContext(context) && (
              <div className="d-flex align-items-center">
                <div className="mr-3">
                  <Tooltip tooltipText="Mark as Favorite" delay={{ show: 500, hide: 0 }}>
                    <span>
                      <FavoriteStar favorite={article._id.toString()} context={context} iconClasses="fs-3 text-muted" />
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card-body p-9 pt-4">
          <div className="pt-0">
            <CommodityPageGeneralInformationBlock article={article} onSearch={handleChangeSearch} search={search} />
            <div className="border-bottom-dark-gray" />
            {C_G_TYPES.map((t, idx) => (
              <React.Fragment key={t}>
                {idx !== 0 && <div className="border-bottom-dark-gray" />}
                <CommodityPageAdvancedInformationBlock context={context} search={search} article={article} type={t} />
              </React.Fragment>
            ))}
            <div className="border-bottom-dark-gray" />
            {article.activeSubstances.length > 0 && (
              <>
                <div className="py-0">
                  <div className="d-flex py-6 align-items-center w-100">
                    <div className="me-3">
                      <div className="text-white fs-3 fw-bolder">Active Substances</div>
                      <div className="text-muted">Substances like vitamins and their concentration</div>
                    </div>
                  </div>
                  <CommodityActiveSubstanceListing article={article} />
                </div>
                <div className="border-bottom-dark-gray" />
              </>
            )}
            <div className="py-0">
              <div className="d-flex py-6 align-items-center w-100">
                <div className="me-3">
                  <div className="text-white fs-3 fw-bolder">Allergens</div>
                  <div className="text-muted">Substances possibly causing allergic reactions</div>
                </div>
              </div>
              <div className="py-5">
                <PropertiesWidget properties={article?.properties || []} types={[PropertyType.ALLERGEN]} />
              </div>
            </div>
            <div className="border-bottom-dark-gray" />
            {!isAnonymousContext(context) && !article.hiddenSupplier && (
              <>
                <CommoditySpecifications article={article} />
                <div className="border-bottom-dark-gray" />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="pt-5">
        {/* Temporarily disabled RB-453: <CommodityPagePriceGraduation commodity={commodity} ref={this.setRef} />*/}
      </div>
      <div className="pt-5">
        {isAnonymousContext(context) && !hasPricesInAnonymousView(article._id.toString(), context.configuration) ? (
          <CreateCustomerOrderAnonymous {...props} onBlurAmount={handleBlurAmount} />
        ) : (
          <CreateCustomerOrder {...props} company={company} onBlurAmount={handleBlurAmount} />
        )}
      </div>
    </>
  );
};

export default withRouter(CommodityPageGeneral);
