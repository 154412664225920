import React, { useContext, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { CC_STATE, CustomerContract } from "../../../model/customerContract.types";
import { getDaysUntil, getTimeDiffString } from "../../../utils/dateUtils";
import { formatDate, getDocFromCollection, round } from "../../../utils/baseUtils";
import { getContractNumber, isContractInactive } from "../../../utils/customerContractUtils";
import CallReferences from "../common/CallReferences";
import { DataContextInternal } from "../../../context/dataContext";
import { formatArticleUnit } from "../../../utils/productArticleUtils";

interface ContractRowProps extends RouteComponentProps {
  contract: CustomerContract;
  style?: object;
}

const ContractRow: React.FunctionComponent<ContractRowProps> = ({ contract, style, history }) => {
  const { totalAmount, restAmount, minimumCallQuantity } = contract.contractInformation;

  const context = useContext(DataContextInternal);

  const forwardContract = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`/customerContract/${contract._id.toString()}`);
  };

  const progress = round((1 - restAmount / totalAmount) * 100, 0);
  const progressColor =
    progress === 100 || [CC_STATE.CANCELED, CC_STATE.CLOSED].includes(contract.state)
      ? "danger"
      : progress <= 25
      ? "warning"
      : "success";
  const timeLeft = getDaysUntil(contract.validityPeriod.end);

  const company = useMemo(() => getDocFromCollection(context.company, contract.company), [contract.company]);

  return (
    <tr
      className="cursor-pointer-row"
      onClick={forwardContract}
      style={style ?? { opacity: isContractInactive(contract) ? 0.5 : 1 }}
    >
      <td className="align-middle">
        <div className="text-white fs-5 text-ellipsis" style={{ maxWidth: "25rem" }}>
          {contract.contractInformation.totalAmount}
          {formatArticleUnit(contract.commodity.unit)} {contract.commodity.title.en}
        </div>
        <div className="text-muted fs-7">
          {contract.customerReference.trim() ? `${contract.customerReference} - ` : ""}
          {company?.name}
        </div>
        <span className="text-white fs-7">{getContractNumber(contract)}</span>
      </td>
      <td className="text-muted align-middle">
        <CallReferences contract={contract} link={"/customerOrder/"} />
      </td>
      <td className="text-light align-middle">
        {contract.state !== CC_STATE.CANCELED ? (
          <>
            <span className={"text-white"}>
              {restAmount}
              {formatArticleUnit(contract.commodity.unit)} left
            </span>
            <div className={"text-muted"}>
              Minimum Quantity: {minimumCallQuantity}
              {formatArticleUnit(contract.commodity.unit)}
            </div>
          </>
        ) : (
          <div>Canceled</div>
        )}
      </td>
      <td className="text-light align-middle">
        <ProgressBar style={{ backgroundColor: "#232323", height: 5 }}>
          <ProgressBar
            min={0}
            max={100}
            now={contract.state === CC_STATE.CANCELED ? 100 : progress}
            variant={progressColor}
          />
        </ProgressBar>
        <div className="fs-7 text-nowrap text-center">{contract.state === CC_STATE.CANCELED ? 100 : progress}%</div>
      </td>
      <td className="align-middle">
        {contract.state !== CC_STATE.CANCELED && (
          <>
            <span className={"text-white"}>
              {formatDate(contract.validityPeriod.start)} to {formatDate(contract.validityPeriod.end)}
            </span>
            <div className={"text-muted"}>
              {timeLeft > 0 ? "expiring" : "expired"} {getTimeDiffString(contract.validityPeriod.end)}
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default withRouter(ContractRow);
