import React, { PureComponent } from "react";
import {
  CO_CANCELED,
  CO_REQUESTEDSTOCK,
  CustomerOrderExtended,
  T_EUSTOCK,
  T_WAREHOUSE,
} from "../../../../../../model/customerOrder.types";
import { toAbsoluteUrl } from "../../../../../../utils/baseUtils";
import { CO_ORDERCONFIRMATION } from "../../../../../../utils/customerOrderUtils";
import { resolveFilePath } from "../../../../../../utils/fileUtils";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import ConfirmCustomerOrderModal from "../../modals/ConfirmCustomerOrderModal";

interface WorkflowOrderConfirmationCardProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

class WorkflowOrderConfirmationCard extends PureComponent<WorkflowOrderConfirmationCardProps> {
  render() {
    const { order, context } = this.props;
    const confirmationCustomer = order.files.find((f) => f.type === CO_ORDERCONFIRMATION);
    return (
      <div
        className="opacity-100-hover"
        style={{ opacity: confirmationCustomer || order.state === CO_CANCELED ? 0.3 : 1 }}
      >
        <div className="fw-bolder text-white fs-3 my-5">
          Confirmation{" "}
          {confirmationCustomer ? (
            <i className="h2 fas fa-check-circle text-success" />
          ) : order.state !== CO_CANCELED ? (
            <span className="text-warning">[Current Task]</span>
          ) : null}
        </div>
        <span className="align-middle text-white w-50">
          Order Confirmation (Customer){" "}
          {confirmationCustomer ? (
            <a href={resolveFilePath(confirmationCustomer.path)} target="_blank" rel="noopener noreferrer">
              <img alt="pdf" className="w-30px me-3" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
            </a>
          ) : (order.transport === T_WAREHOUSE || order.transport === T_EUSTOCK) &&
            order.state === CO_REQUESTEDSTOCK ? (
            <span>
              Missing <ConfirmCustomerOrderModal order={order} context={context} stateChangeToProcessing={true} />
            </span>
          ) : order.transport === T_WAREHOUSE && order.state !== CO_REQUESTEDSTOCK ? (
            <span>
              Missing <ConfirmCustomerOrderModal order={order} context={context} />
            </span>
          ) : (
            "Missing"
          )}
        </span>
      </div>
    );
  }
}

export default WorkflowOrderConfirmationCard;
