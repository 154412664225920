import React, { PureComponent } from "react";
import Tool, { ToolTypes } from "./Tool";
import userService from "../../services/userService";
import { REQUIRED_ROLES_CALCULATION_CONFIGURATION } from "../../utils/userUtils";

class Tools extends PureComponent {
  render() {
    const isAdmin = userService.isAdmin();
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Tools &amp; Automations</span>
                </h3>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool type={ToolTypes.COMMODITY_PARSING_TOOL} disabled={!isAdmin} />
                  </div>
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool type={ToolTypes.CUSTOMER_PARSING_TOOL} disabled={!isAdmin} />
                  </div>
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool type={ToolTypes.SUPPLIER_PARSING_TOOL} disabled={!isAdmin} />
                  </div>
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool
                      type={ToolTypes.SEAPORT_PARSING_TOOL}
                      requiredRoles={REQUIRED_ROLES_CALCULATION_CONFIGURATION}
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool type={ToolTypes.MASTERSPEC_GENERATION_TOOL} disabled={!isAdmin} />
                  </div>
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool type={ToolTypes.PRICE_CALCULATOR} />
                  </div>
                  <div className="col-12 col-sm-6 mb-sm-6">
                    <Tool type={ToolTypes.CUSTOMER_MAILS_TOOL} disabled={!isAdmin} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tools;
