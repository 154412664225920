import _ from "lodash";
import React, { useMemo } from "react";
import { DataContextCustomerType } from "../../context/dataContext";
import { isCustomerFinishedProduct } from "../../utils/finishedProductUtils";
import { CUSTOMER } from "../../utils/userUtils";
import { PropertyType } from "../../utils/propertyUtils";
import { T_WAREHOUSE } from "../../model/customerOrder.types";
import { formatCurrency, formatUnit, getDocFromCollection, pluralize } from "../../utils/baseUtils";
import userService from "../../services/userService";
import { formatDateFromType, getDeliveryTimeLabel, getPaymentTermsFromCompany } from "../../utils/customerOrderUtils";
import { UploadedFileExtended } from "../../model/commodity.types";
import { D_MASTERSPECIFICATION } from "../../utils/commodityUtils";
import { getPackagingSizeString, getSupplierSpecs } from "../../utils/productArticleUtils";
import {
  FileWidget,
  OverviewEntries,
  OverviewEntryValue,
  OverviewTable,
} from "../commodities/customer/CreateCustomerOrderOverview";
import { O_ORDERMETHODS } from "../../utils/orderUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../utils/currencyUtils";
import { GlobalShoppingCartInfo, ShoppingCartOrderInfo } from "./ShoppingCartCheckout";

interface ShoppingCartCheckoutOverviewProps {
  context: DataContextCustomerType;
  globalInfo: GlobalShoppingCartInfo;
  orderInfo: ShoppingCartOrderInfo;
}

const ShoppingCartCheckoutOverview: React.FunctionComponent<ShoppingCartCheckoutOverviewProps> = (props) => {
  const { orderInfo, context, globalInfo } = props;
  const {
    article,
    amount,
    method,
    priceInfo,
    targetDate,
    targetDateType,
    incomingOrderableStock,
    shippingAddress,
    reference,
    supplier,
    earliestDeliveryDate,
  } = orderInfo;

  const isFP = isCustomerFinishedProduct(article, CUSTOMER);
  const composition = useMemo(() => article.properties.find((p) => p.type === PropertyType.COMPOSITION), [article]);
  const priceCommodities = useMemo(
    () =>
      priceInfo && isFinite(priceInfo.totalPrice)
        ? priceInfo.totalPrice
        : incomingOrderableStock && method.value === T_WAREHOUSE
        ? incomingOrderableStock.price * amount
        : Infinity,
    [priceInfo, incomingOrderableStock, amount, method]
  );
  const company = useMemo(() => getDocFromCollection(context.company, userService.getCompany()), [context.company]);

  const paymentTerms = useMemo(
    () => (company ? getPaymentTermsFromCompany(company, shippingAddress?.address) : undefined),
    [company, shippingAddress]
  );
  const specifications = useMemo(() => {
    let specs: Array<UploadedFileExtended> = [];
    const masterSpecification = article.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    if (supplier) {
      specs = getSupplierSpecs(article, supplier.value);
    } else if (masterSpecification !== undefined) {
      specs = [masterSpecification];
    }
    return specs;
  }, [article, supplier]);

  const mappedSpecs: Array<[key: string, value: OverviewEntryValue]> = useMemo(
    () => specifications.map((s) => [`Specification `, { content: <FileWidget file={s} /> }]) || [],
    [specifications]
  );

  const overviewTableDefinition: Array<OverviewEntries> = useMemo(
    () => [
      [
        "Commodity Information",
        [
          ["Title", { content: article.title.en }],
          ["Subtitle", { content: article.subtitle.en }],
          ["Article Number", { content: article.articleNo }],
          [
            isFP ? "Type" : "CAS Number",
            { content: isFP ? composition?.name.en ?? "-" : article.casNumber.join(" / ") },
          ],
        ],
      ],
      [
        "Delivery Information",
        [
          [
            "Address",
            {
              content: globalInfo.globalAddress
                ? globalInfo.globalAddress.label
                : shippingAddress
                ? shippingAddress.label
                : "",
            },
          ],
          [
            "Delivery",
            {
              content: `DDP, via ${
                O_ORDERMETHODS.find((ot) => ot.value === method.value)?.label || _.upperFirst(method.value)
              }, in ${getPackagingSizeString(article)}`,
            },
          ],
          [
            "ETA",
            {
              content: `Deliverable in approx. ${
                method.value === T_WAREHOUSE && incomingOrderableStock
                  ? incomingOrderableStock.inWeeks + 1 + " weeks"
                  : getDeliveryTimeLabel(earliestDeliveryDate)
              }. ${"Targeted for " + formatDateFromType(targetDate, targetDateType)}`,
              className: method.value === T_WAREHOUSE && incomingOrderableStock ? "text-warning" : "text-success",
            },
          ],
        ],
      ],
    ],
    [article, composition, method, shippingAddress, earliestDeliveryDate, incomingOrderableStock]
  );

  useMemo(() => {
    if (mappedSpecs.length > 0) overviewTableDefinition.push(["Documents", mappedSpecs]);
    overviewTableDefinition.push([
      "Order Information",
      [
        ["Quantity", { content: formatUnit(amount, article.unit), className: "text-bold text-white fs-4" }],
        [
          "Price",
          {
            content: (
              <>
                <span className="text-bold text-white fs-4">
                  {formatCurrency(priceCommodities, CUSTOMER_BASE_CURRENCY)}
                </span>
                <span className="text-bold text-muted fs-4 ml-1">
                  ({formatCurrency(priceCommodities / amount, CUSTOMER_BASE_CURRENCY)}/{article.unit})
                </span>
              </>
            ),
          },
        ],
        ["Reference", { content: globalInfo.globalRef ?? reference }],
        [
          "Payment",
          {
            content: paymentTerms
              ? paymentTerms.paymentTermConditions
                ? `${paymentTerms.paymentTerms} ${paymentTerms.paymentTermConditions}`
                : `${paymentTerms.paymentTerms} after the goods are received.`
              : `${pluralize(company?.paymentTarget || 14, "day")} after the goods are received.`,
          },
        ],
      ],
    ]);
  }, [paymentTerms, priceCommodities, amount, article, mappedSpecs]);

  return <OverviewTable entries={overviewTableDefinition} />;
};

export default ShoppingCartCheckoutOverview;
