import _ from "lodash";
import { BSON } from "realm-web";
import { SelectOption } from "../components/common/CustomSelect";
import { DateType } from "./orderUtils";
import { LanguageObject } from "../model/commonTypes";
import { Supplier } from "../model/supplier.types";
import { TransportSelectOption } from "./logisticsUtils";
import { AddressSelectOption } from "./addressUtils";

// No database object, kept in local storage
export interface ShoppingCart {
  articlesToOrder: Array<ShoppingCartEntry>;
  globalShippingAddress?: AddressSelectOption;
  globalReference?: string;
}

export interface ShoppingCartEntry {
  _id: BSON.ObjectId;
  article: { _id: BSON.ObjectId; title: LanguageObject; subtitle: LanguageObject; unit: string };
  reference: string;
  amount: number;
  unit: "kg" | "ltr" | "";
  shippingAddress?: AddressSelectOption;
  supplier?: SelectOption<Supplier>;
  method?: TransportSelectOption;
  targetDate?: Date;
  targetDateType?: DateType;
  userSetTargetDate?: boolean;
}

/**
 * Get the shopping cart from local storage as ShoppingCart object
 * @returns {ShoppingCart} The shopping cart as ShoppingCart object
 */
export const getShoppingCart = (): ShoppingCart | undefined => {
  const shoppingCartString = window.localStorage.getItem("shoppingCart");
  let shoppingCart;
  if (shoppingCartString) {
    shoppingCart = replaceIdsAndDates(JSON.parse(shoppingCartString)) as ShoppingCart;
  }
  return shoppingCart;
};

/**
 * Get an object where all values with name _id are replaced by proper ObjectIds and dates are replaced by proper Date types
 * @param obj The object to convert
 * @param givenKey optional, the former key of a single value
 * @returns {any} The alternated object
 */
function replaceIdsAndDates(obj: any, givenKey?: string) {
  if (_.isNull(obj)) {
    return obj;
  }
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      replaceIdsAndDates(obj[i]);
    }
  } else if (typeof obj === "object") {
    const keys = Object.keys(obj);
    for (const key of keys) {
      if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          replaceIdsAndDates(obj[key][i]);
        }
      } else if (typeof obj[key] === "object" && !(obj[key] instanceof Date)) {
        obj[key] = replaceIdsAndDates(obj[key], key);
      } else {
        obj[key] = replaceSpecificIdOrDate(obj[key], key);
      }
    }
    if ((!Array.isArray(obj) || typeof obj !== "object") && givenKey) {
      obj = replaceSpecificIdOrDate(obj, givenKey);
    }
  }
  return obj;
}

/**
 * Convert value with key _id to valid ObjectId or of former type date back to real date
 * @param value The value to convert
 * @param key The given object key of the value
 * @returns {any} The (alternated) value, changed only _id and date type
 */
const replaceSpecificIdOrDate = (value: any, key: string) => {
  if (key === "_id") {
    return new BSON.ObjectId(value);
  }
  const mightBeDate = new Date(value);
  if (mightBeDate.getTime() && !Number(value)) {
    // check if this is a valid date type and no number
    return mightBeDate;
  }
  return value;
};
