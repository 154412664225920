import _ from "lodash";
import React, { PureComponent, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import {
  INBOUND,
  OUTBOUND,
  sortOrderGroups,
  splitGroupByDestination,
  splitGroupByETD,
  splitGroupByIncoterm,
  splitGroupByStartingPoint,
  splitGroupBySupplier,
  splitGroupByTargetDate,
  splitGroupByTransportCondition,
  splitGroupByTransportMethod,
  splitOrderGroup,
  StorageOrderMap,
} from "../../../utils/SCMUtils";
import {
  calculateArrivalInformation,
  ChangeDateAction,
  getInternalTransportTypeLabel,
  getOrderNumber,
  isCustomerOrder,
  isSupplierOrder,
} from "../../../utils/orderUtils";
import { formatDate, formatUnit, pluralize, toAbsoluteUrl } from "../../../utils/baseUtils";
import { formatAddress, getStandardWarehouse } from "../../../utils/addressUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import {
  getAvailableOrders,
  getFWOTransportTypeLabel,
  getOrdersFromFWO,
  getWarnings,
  getWarningText,
  isBackToBackOrder,
  isOutboundOrder,
} from "../../../utils/forwardingOrderUtils";
import { DataContextInternal, DataContextInternalType } from "../../../context/dataContext";
import { SupplierOrderExtended } from "../../../model/supplierOrder.types";
import {
  CustomerOrderExtended,
  FWO_TRANSPORT,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_RAILFREIGHT,
  T_SEAAIRCOMBINED,
  T_SEAFREIGHT,
} from "../../../model/customerOrder.types";
import { ForwardingOrder, FWO_STATES } from "../../../model/forwardingOrder.types";
import ChangeETAModal from "../../orders/internal/supplierOrder/modals/ChangeETAModal";
import Tooltip from "../../common/Tooltip";
import { getSupplierOrderStartString } from "../../../utils/supplierOrderUtils";
import { formatOrderDate } from "../../../utils/customerOrderUtils";
import OrderDateOverview from "../../orders/internal/OrderDateOverview";
import { STO_STATES } from "../../../model/storageOrder.types";
import { STO_STORAGENOTICE, STO_STORAGEORDER } from "../../../utils/storageOrderUtils";
import UploadStorageNoticeModal from "../../logistics/internal/modals/UploadStorageNoticeModal";
import { formatDateWithType } from "../../../utils/logisticsUtils";

// Inbound columns
const I_RECEIPT = "InboundReceipt";
const I_IN_TRANSIT = "InboundInTransit";
const I_BEFORE_ARRIVAL = "InboundBeforeArrival";
const I_INCOMING = "InboundIncoming";

// Outbound columns
const O_RECEIPT = "OutboundReceipt";
const O_FORWARDER = "OutboundForwarder";

interface SCMDashboardOrderKanbanProps {
  context: DataContextInternalType;
  selectedTab: typeof INBOUND | typeof OUTBOUND;
  forwardingOrders: Array<ForwardingOrder>;
  availableOrders: Array<SupplierOrderExtended | CustomerOrderExtended>;
  sorting?: string;
  sortingOrder?: string;
}

interface SCMDashboardOrderKanbanState {
  showAll: Array<string>; // List of tabs where all orders should be shown
  groupedSupplierOrders: Array<Array<SupplierOrderExtended>>; // inbound groups
  groupedCustomerOrders: Array<Array<CustomerOrderExtended>>; // outbound groups
  loading: boolean;
}

interface GroupedForwardingOrders {
  transitInbound: Array<ForwardingOrder>;
  beforeArrivalInbound: Array<ForwardingOrder>;
  incomingInbound: Array<ForwardingOrder>;
  forwarderOutbound: Array<ForwardingOrder>;
}

class SCMDashboardOrderKanban extends PureComponent<SCMDashboardOrderKanbanProps, SCMDashboardOrderKanbanState> {
  constructor(props: SCMDashboardOrderKanbanProps) {
    super(props);
    this.state = {
      showAll: [],
      groupedSupplierOrders: [],
      groupedCustomerOrders: [],
      loading: true,
    };
  }

  componentDidMount() {
    const { sorting, sortingOrder } = this.props;
    this.getOrderGroups(sorting, sortingOrder);
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps: SCMDashboardOrderKanbanProps) {
    const { context, availableOrders, sorting, sortingOrder } = this.props;
    this.setState({ loading: true });
    // if orders were filtered, recalculate order groups
    if (
      !_.isEqual(prevProps.availableOrders, availableOrders) ||
      !_.isEqual(prevProps.sorting, sorting) ||
      !_.isEqual(prevProps.sortingOrder, sortingOrder)
    ) {
      this.getOrderGroups(sorting, sortingOrder);
    }
    if (
      !_.isEqual(context.supplierOrder, prevProps.context.supplierOrder) ||
      !_.isEqual(context.customerOrder, prevProps.context.customerOrder)
    ) {
      this.updateOrderGroups();
    }
    this.setState({ loading: false });
  }

  handleClickShowAll = (state: string) => {
    const showAll = _.cloneDeep(this.state.showAll);
    showAll.push(state);
    this.setState({ showAll });
  };

  handleClickAddGroup = () => {
    const { selectedTab } = this.props;
    if (selectedTab === INBOUND) {
      const groupedOrders = _.cloneDeep(this.state.groupedSupplierOrders);
      groupedOrders.unshift([]);
      this.setState({ groupedSupplierOrders: groupedOrders });
    } else {
      const groupedOrders = _.cloneDeep(this.state.groupedCustomerOrders);
      groupedOrders.unshift([]);
      this.setState({ groupedCustomerOrders: groupedOrders });
    }
  };

  handleClickDeleteGroup = (idx: number) => {
    const { selectedTab } = this.props;
    if (selectedTab === INBOUND) {
      const groupedOrders = _.cloneDeep(this.state.groupedSupplierOrders);
      groupedOrders.splice(idx, 1);
      this.setState({ groupedSupplierOrders: groupedOrders });
    } else {
      const groupedOrders = _.cloneDeep(this.state.groupedCustomerOrders);
      groupedOrders.splice(idx, 1);
      this.setState({ groupedCustomerOrders: groupedOrders });
    }
  };

  handleDragEnd = (result: DropResult) => {
    const { selectedTab } = this.props;
    const groupedOrders = _.cloneDeep(
      selectedTab === INBOUND ? this.state.groupedSupplierOrders : this.state.groupedCustomerOrders
    );
    const { destination, source } = result;
    // Object was dropped outside of list or location did not change
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return;

    const sourceGroupNumber = Number(source.droppableId.split("_").pop());
    const destinationGroupNumber = Number(destination.droppableId.split("_").pop());
    const sourceGroup = groupedOrders[sourceGroupNumber];
    const destinationGroup = groupedOrders[destinationGroupNumber];
    const sourceComparisonOrder = sourceGroup[source.index];
    const destinationComparisonOrder = destinationGroup[0];
    const isSO = isSupplierOrder(sourceComparisonOrder);

    // nothing to check if destination is an empty group, otherwise check if grouping is allowed
    if (destinationGroup.length === 0 || this.validateData(sourceComparisonOrder, destinationComparisonOrder)) {
      const draggedOrder = sourceGroup.splice(source.index, 1)[0];
      if (isSO) {
        destinationGroup.splice(destination.index, 0, draggedOrder as SupplierOrderExtended);
      } else {
        destinationGroup.splice(destination.index, 0, draggedOrder as CustomerOrderExtended);
      }
    }
    if (selectedTab === INBOUND) {
      this.setState({ groupedSupplierOrders: groupedOrders as Array<Array<SupplierOrderExtended>> });
    } else {
      this.setState({ groupedCustomerOrders: groupedOrders as Array<Array<CustomerOrderExtended>> });
    }
  };

  /**
   * Validates if the given orders can be grouped together
   * @param sourceOrder the order which should be grouped
   * @param destinationOrder an order from the group the sourceOrder should be moved to
   * @returns {boolean} true if grouping is allowed, false if not
   */
  validateData = (
    sourceOrder: CustomerOrderExtended | SupplierOrderExtended,
    destinationOrder: CustomerOrderExtended | SupplierOrderExtended
  ): boolean => {
    // types do not match
    if (isSupplierOrder(sourceOrder) !== isSupplierOrder(destinationOrder)) {
      toast.error("Order types do not match");
      return false;
    }
    // delivery terms differ
    if (sourceOrder.terms?.deliveryTerms !== destinationOrder.terms?.deliveryTerms) {
      toast.error("Delivery terms do not match");
      return false;
    }
    if (isSupplierOrder(sourceOrder) && isSupplierOrder(destinationOrder)) {
      // Transport types differ (SO only)
      if (sourceOrder.transport !== destinationOrder.transport) {
        toast.error("Transport type does not match");
        return false;
      }
      // Starting point differs (SO only)
      if (getSupplierOrderStartString(sourceOrder) !== getSupplierOrderStartString(destinationOrder)) {
        toast.error("Starting point does not match");
        return false;
      }
    }
    return true;
  };

  /**
   * Splits all orders into groups for displaying in the first column
   */
  getOrderGroups = (sorting?: string, sortingOrder?: string) => {
    const { availableOrders } = this.props;
    // Arrays for accumulated groups
    let groupedSOs: Array<Array<SupplierOrderExtended>> = [];
    let groupedCOs: Array<Array<CustomerOrderExtended>> = [];

    // Split orders into CO and SO
    const availableSO = availableOrders.filter(isSupplierOrder);
    const availableCO = availableOrders.filter(isCustomerOrder);
    groupedSOs.push(availableSO as Array<SupplierOrderExtended>);
    groupedCOs.push(availableCO as Array<CustomerOrderExtended>);
    // Split orders into transport conditions
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByTransportCondition);
    groupedCOs = splitOrderGroup(groupedCOs, splitGroupByTransportCondition);
    // Split orders into incoterms
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByIncoterm);
    groupedCOs = splitOrderGroup(groupedCOs, splitGroupByIncoterm);
    // split orders into same destinations
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByDestination);
    groupedCOs = splitOrderGroup(groupedCOs, splitGroupByDestination);
    // split orders into similar targetDate/changedTargetDate
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByTargetDate);
    groupedCOs = splitOrderGroup(groupedCOs, splitGroupByTargetDate);
    // split SO into same transport type
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByTransportMethod);
    // split SO into similar ETD
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByETD);
    // split SO into same starting point
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupByStartingPoint);
    // split SO into same supplier (airfreight only)
    groupedSOs = splitOrderGroup(groupedSOs, splitGroupBySupplier);

    if (sorting && sortingOrder) {
      // Sorting only for outbound for now
      groupedCOs = sortOrderGroups(groupedCOs, sorting, sortingOrder);
    }

    this.setState({
      groupedSupplierOrders: groupedSOs,
      groupedCustomerOrders: groupedCOs,
    });
  };

  /**
   * Updates order content while maintaining the order groups
   */
  updateOrderGroups = () => {
    const { context } = this.props;
    const groupedSupplierOrders = _.cloneDeep(this.state.groupedSupplierOrders);
    const groupedCustomerOrders = _.cloneDeep(this.state.groupedCustomerOrders);
    const availableOrders = getAvailableOrders(context).map((selectOption) => selectOption.object);
    const availableSO = availableOrders.filter(isSupplierOrder) as Array<SupplierOrderExtended>;
    const availableCO = availableOrders.filter(isCustomerOrder) as Array<CustomerOrderExtended>;
    // If the orders from the context were updated, we want to keep the order of groupedOrders, but update the orders inside
    const replaceOrderInGroup = (
      group: Array<SupplierOrderExtended | CustomerOrderExtended>,
      availableOrders: Array<SupplierOrderExtended | CustomerOrderExtended>
    ) => {
      return group.map((order) => {
        const match = availableOrders.find((availableOrder) => availableOrder._id.toString() === order._id.toString());
        return match ? match : order;
      });
    };
    const updatedGroupedSupplierOrders = groupedSupplierOrders.map((group) => replaceOrderInGroup(group, availableSO));
    const updatedGroupedCustomerOrders = groupedCustomerOrders.map((group) => replaceOrderInGroup(group, availableCO));
    this.setState({
      groupedSupplierOrders: updatedGroupedSupplierOrders as Array<Array<SupplierOrderExtended>>,
      groupedCustomerOrders: updatedGroupedCustomerOrders as Array<Array<CustomerOrderExtended>>,
    });
  };

  /**
   * Checks if the date of the orders inside the forwarding orders are a certain time before or after today
   * 14 Days for seafreight, 2 days for airfreight, 10 days for rail and road, 5 days for sea/air combined
   * @param order the forwardingOrder to check
   * @returns {boolean} true if the latest date of an order inside the forwardingOrder is smaller or equal the checked timeframe
   */
  isBeforeArrival = (order: ForwardingOrder): boolean => {
    const { context } = this.props;
    const orders = getOrdersFromFWO(order, context);
    // If there are no orders we can't calculate a difference
    if (orders.length === 0) return false;
    const today = new Date();
    const latestDate = orders.reduce((latestDate, current) => {
      const currentDate = current.changedETA ?? current.targetDate;
      return currentDate > latestDate ? currentDate : latestDate;
    }, orders[0].changedETA ?? orders[0].targetDate);
    const differenceInMilliseconds = latestDate.getTime() - today.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    // order should be moved to the column a certain time before arrival
    switch (order.transportType) {
      case T_SEAFREIGHT:
        return differenceInDays <= 14;
      case T_AIRFREIGHT:
        return differenceInDays <= 2;
      case T_RAILFREIGHT:
      case T_EUSTOCK:
        return differenceInDays <= 10;
      case T_SEAAIRCOMBINED:
        return differenceInDays <= 5;
      default:
        return false;
    }
  };

  /**
   * Checks if all orders inside the forwardingOrders have associated storage orders
   * @param order the forwardingOrder to check
   * @returns {boolean} true all orders have associated storage orders
   */
  isIncoming = (order: ForwardingOrder): boolean => {
    const { context } = this.props;
    const orders = getOrdersFromFWO(order, context);
    // map for faster lookups
    const storageOrderMap: StorageOrderMap = context.storageOrder.reduce((acc: StorageOrderMap, stO) => {
      if (stO.state !== STO_STATES.STO_CANCELED) {
        acc[stO.orderInformation.orderId] = stO;
      }
      return acc;
    }, {});
    return orders.every((order) => !!storageOrderMap[order._id.toString()]);
  };

  /**
   * Groups the provided forwardingOrders based on their state and their transport type
   * @param forwardingOrders the array of forwardingOrders which should be grouped
   * @param selectedTab the currently selected tab to determine which forwarding order groups will be filled
   * @returns {GroupedForwardingOrders} the forwardingOrders sorted into groups
   */
  groupForwardingOrders = (
    forwardingOrders: Array<ForwardingOrder>,
    selectedTab: typeof INBOUND | typeof OUTBOUND
  ): GroupedForwardingOrders => {
    const currentTabInbound = selectedTab === INBOUND;
    return forwardingOrders.reduce(
      (acc: GroupedForwardingOrders, order) => {
        if (order.state === FWO_STATES.FWO_IN_TRANSIT || order.state === FWO_STATES.FWO_CREATED) {
          if (currentTabInbound && !isOutboundOrder(order)) {
            if (!this.isBeforeArrival(order)) {
              acc.transitInbound.push(order);
            } else if (!this.isIncoming(order)) {
              acc.beforeArrivalInbound.push(order);
            } else {
              acc.incomingInbound.push(order);
            }
          } else if (!currentTabInbound && isOutboundOrder(order)) {
            acc.forwarderOutbound.push(order);
          }
        }
        return acc;
      },
      {
        transitInbound: [],
        beforeArrivalInbound: [],
        incomingInbound: [],
        forwarderOutbound: [],
      }
    );
  };

  render() {
    const { selectedTab, context, forwardingOrders } = this.props;
    const { showAll, groupedSupplierOrders, groupedCustomerOrders, loading } = this.state;
    const groupedForwardingOrders = this.groupForwardingOrders(forwardingOrders, selectedTab);

    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        {loading ? (
          <div>
            Loading...
            <span className="spinner-border spinner-border-sm align-middle text-muted"></span>
          </div>
        ) : (
          <div className="container-fluid px-0" style={{ minWidth: "1200px" }}>
            {selectedTab === INBOUND ? (
              <div className="row">
                <div className="col mb-0">
                  <SCMKanbanColumn
                    columnName={I_RECEIPT}
                    groupedOrders={groupedSupplierOrders}
                    context={context}
                    showAll={showAll.includes(I_RECEIPT)}
                    onClickShowAll={this.handleClickShowAll}
                    onClickAddGroup={this.handleClickAddGroup}
                    onClickDeleteGroup={this.handleClickDeleteGroup}
                  />
                </div>
                <div className="col mb-0">
                  <SCMKanbanColumn
                    columnName={I_IN_TRANSIT}
                    groupedOrders={groupedForwardingOrders.transitInbound}
                    context={context}
                    showAll={showAll.includes(I_IN_TRANSIT)}
                    onClickShowAll={this.handleClickShowAll}
                  />
                </div>
                <div className="col mb-0">
                  <SCMKanbanColumn
                    columnName={I_BEFORE_ARRIVAL}
                    groupedOrders={groupedForwardingOrders.beforeArrivalInbound}
                    context={context}
                    showAll={showAll.includes(I_BEFORE_ARRIVAL)}
                    onClickShowAll={this.handleClickShowAll}
                  />
                </div>
                <div className="col mb-0">
                  <SCMKanbanColumn
                    columnName={I_INCOMING}
                    groupedOrders={groupedForwardingOrders.incomingInbound}
                    context={context}
                    showAll={showAll.includes(I_INCOMING)}
                    onClickShowAll={this.handleClickShowAll}
                  />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col mb-0">
                  <SCMKanbanColumn
                    columnName={O_RECEIPT}
                    groupedOrders={groupedCustomerOrders}
                    context={context}
                    showAll={showAll.includes(O_RECEIPT)}
                    onClickShowAll={this.handleClickShowAll}
                    onClickAddGroup={this.handleClickAddGroup}
                  />
                </div>
                <div className="col mb-0">
                  <SCMKanbanColumn
                    columnName={O_FORWARDER}
                    groupedOrders={groupedForwardingOrders.forwarderOutbound}
                    context={context}
                    showAll={showAll.includes(O_FORWARDER)}
                    onClickShowAll={this.handleClickShowAll}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </DragDropContext>
    );
  }
}

interface SCMKanbanColumnProps {
  columnName: string;
  groupedOrders: Array<Array<SupplierOrderExtended> | Array<CustomerOrderExtended>> | Array<ForwardingOrder>;
  context: DataContextInternalType;
  showAll: boolean;
  onClickShowAll: (state: string) => void;
  onClickAddGroup?: () => void;
  onClickDeleteGroup?: (idx: number) => void;
}

const SCMKanbanColumn: React.FunctionComponent<SCMKanbanColumnProps> = React.memo(
  ({ columnName, groupedOrders, context, showAll, onClickShowAll, onClickAddGroup, onClickDeleteGroup }) => {
    const ordersShown = useMemo(() => (showAll ? groupedOrders : groupedOrders.slice(0, 5)), [showAll, groupedOrders]);
    const isForwardingOrderArray = !Array.isArray(groupedOrders[0]);

    const getColumnLabel = (value: string) => {
      const columnLabels = [
        { value: I_RECEIPT, label: "Receipt" },
        { value: I_IN_TRANSIT, label: "In Transit" },
        { value: I_BEFORE_ARRIVAL, label: "Before Arrival" },
        { value: I_INCOMING, label: "Incoming" },
        { value: O_RECEIPT, label: "Receipt" },
        { value: O_FORWARDER, label: "Forwarder" },
      ];
      const item = columnLabels.find((item) => item.value === value);
      return item?.label;
    };

    return (
      <div className="card bg-white min-h-100">
        <div className="card-header border-0 p-0 ">
          <h6 className="card-title d-block m-5">
            {_.upperFirst(getColumnLabel(columnName))}
            <div className="text-muted fs-7 mt-1">
              {pluralize(groupedOrders.length, `${isForwardingOrderArray ? "Forwarding Order" : "Order Group"}`)}
            </div>
          </h6>
        </div>
        <div className="card-body pt-0 pb-2 px-5 " style={{ minHeight: "65vh" }}>
          <div className="row">
            {ordersShown.length > 0 ? (
              <>
                {[I_RECEIPT, O_RECEIPT].includes(columnName) && (
                  <button className="btn btn-sm btn-light mb-2" onClick={onClickAddGroup}>
                    Add new group
                  </button>
                )}
                {ordersShown.map((grp, index) => (
                  <SCMKanbanItemGroup
                    key={index}
                    groupNo={index}
                    columnName={columnName}
                    context={context}
                    orders={grp}
                    onClickDeleteGroup={onClickDeleteGroup}
                  />
                ))}
              </>
            ) : (
              <div className="text-white">No orders to display!</div>
            )}
          </div>
          {!showAll && groupedOrders.length > 5 && (
            <div className="row">
              <div className="col-12 text-center">
                <button onClick={() => onClickShowAll(columnName)} className="btn btn-sm text-white">
                  Show all {groupedOrders.length} orders
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

interface SCMKanbanItemGroupProps {
  groupNo: number;
  columnName: string;
  context: React.ContextType<typeof DataContextInternal>;
  orders: Array<CustomerOrderExtended | SupplierOrderExtended> | ForwardingOrder;
  onClickDeleteGroup?: (idx: number) => void;
}

const SCMKanbanItemGroup: React.FunctionComponent<SCMKanbanItemGroupProps> = ({
  columnName,
  groupNo,
  context,
  orders,
  onClickDeleteGroup,
}) => {
  const history = useHistory();
  const isForwardingOrder = !Array.isArray(orders);
  const allOrders = isForwardingOrder ? getOrdersFromFWO(orders, context) : orders;
  const forwarder =
    isForwardingOrder && orders.forwarder
      ? context.forwarder.find((f) => f._id.toString() === orders.forwarder)?.name || "-"
      : undefined;
  const [warnings, setWarnings] = useState<Array<string>>([]);

  const handleCreateFWO = () => {
    const orderIds = allOrders.map((order) => order._id).join(",");
    history.push("/createForwardingOrder?orderIds=" + orderIds);
  };

  useEffect(() => {
    if (isForwardingOrder) return;
    setWarnings(getWarnings(context, undefined, undefined, undefined, orders));
  }, [orders]);

  /**
   * Returns an icon based on the transport type of the given forwardingOrder
   * @param forwardingOrder the forwardingOrder for which the color should be returned
   * @returns {JSX.Element} the svg with the matching icon
   */
  const getTransportIcon = (forwardingOrder: ForwardingOrder): JSX.Element => {
    const iconMap = {
      [T_SEAFREIGHT]: "/assets/media/svg/custom/sea.svg",
      [T_AIRFREIGHT]: "/assets/media/svg/custom/air.svg",
      [T_EUSTOCK]: "/assets/media/svg/custom/road.svg",
      [T_RAILFREIGHT]: "/assets/media/svg/custom/rail.svg",
      [T_SEAAIRCOMBINED]: "/assets/media/svg/custom/seaAir.svg",
    };
    const iconPath = iconMap[forwardingOrder.transportType as FWO_TRANSPORT] || "/assets/media/svg/custom/air.svg";
    return <SVG className="mr-2" width="20px" height="20px" src={toAbsoluteUrl(iconPath)} />;
  };

  return (
    <div className="bg-light2 rounded-3 my-2 px-3 py-2">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center fw-bolder text-white fs-6">
          {isForwardingOrder && getTransportIcon(orders)}
          {!isForwardingOrder ? `Group ${groupNo + 1}` : ` FW-${orders.forwardingOrderNo}`}
          {isForwardingOrder && (
            <span className="ml-2">
              <a href={resolveFilePath(orders.file)} target="_blank" rel="noopener noreferrer">
                <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
              </a>
            </span>
          )}
        </div>
        {!isForwardingOrder ? (
          allOrders.length > 0 ? (
            <button
              type="button"
              className="btn btn-outline btn-outline-light btn-sm px-2 p-1 "
              onClick={handleCreateFWO}
            >
              Create FO
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline btn-outline-light btn-sm px-2 p-1 "
              onClick={() => (onClickDeleteGroup ? onClickDeleteGroup(groupNo) : undefined)}
            >
              Delete Group
            </button>
          )
        ) : (
          <div className="text-right">
            {[I_IN_TRANSIT, I_BEFORE_ARRIVAL, O_FORWARDER].includes(columnName) && (
              <button
                type="button"
                className="btn btn-outline btn-outline-light btn-sm px-2 p-1"
                onClick={() => history.push("/createForwardingOrder/" + orders._id.toString())}
              >
                Edit FO
              </button>
            )}
          </div>
        )}
      </div>
      {!isForwardingOrder && warnings.length > 0 && (
        <div className="text-warning">
          <b>Warnings: </b>
          {warnings
            .map((w) => {
              return getWarningText(w);
            })
            .join(", ")}
        </div>
      )}
      {isForwardingOrder && (
        <div>
          <table className="table table-no-padding fw-bold gy-0">
            <tbody>
              {forwarder && (
                <tr>
                  <td className="text-white w-50">Forwarder</td>
                  <td className="text-muted">{forwarder}</td>
                </tr>
              )}
              <tr>
                <td className="text-white w-50">Transport</td>
                <td className="text-muted">{getFWOTransportTypeLabel(orders.transportType)}</td>
              </tr>
              <tr>
                <td className="text-white w-50">Starting Point</td>
                <td className="text-muted">{orders.takeOver.startingFrom}</td>
              </tr>
              <tr>
                <td className="text-white w-50">Incoterm</td>
                <td className="text-muted">{orders.takeOver.startIncoterm}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <Droppable
        droppableId={columnName.toString() + "_" + groupNo.toString()}
        isDropDisabled={![I_RECEIPT, O_RECEIPT].includes(columnName)}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {allOrders &&
              allOrders.map((order, index) => (
                <SCMKanbanItem
                  key={order._id.toString()}
                  index={index}
                  order={order}
                  columnName={columnName}
                  groupNo={groupNo}
                  context={context}
                />
              ))}
            {[I_RECEIPT, O_RECEIPT].includes(columnName) && provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

interface SCMKanbanItemProps {
  index: number;
  order: SupplierOrderExtended | CustomerOrderExtended;
  columnName: string;
  groupNo: number;
  context: DataContextInternalType;
}

const SCMKanbanItem: React.FunctionComponent<SCMKanbanItemProps> = ({ index, order, columnName, groupNo, context }) => {
  const history = useHistory();
  const isSO = isSupplierOrder(order);
  const isB2B = isBackToBackOrder(order);
  const storageOrder = context.storageOrder.find(
    (stO) => stO.orderInformation.orderId === order._id.toString() && stO.state !== STO_STATES.STO_CANCELED
  );
  const storageOrderFile = storageOrder?.files?.find((d) => d.type === STO_STORAGEORDER);
  const storageNoticeFile = storageOrder?.files?.find((d) => d.type === STO_STORAGENOTICE);
  const glomm = getStandardWarehouse(context);
  const arrival = calculateArrivalInformation(order);

  const handleCreateSTO = () => {
    history.push("/createStorageOrder?orderId=" + order._id.toString());
  };

  return (
    <Draggable
      draggableId={columnName.toString() + "_" + groupNo.toString() + "_" + order._id.toString()}
      index={index}
      isDragDisabled={![I_RECEIPT, O_RECEIPT].includes(columnName)}
    >
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div
            className={`${snapshot.isDragging ? "bg-color-base-6" : "bg-custom-medium-gray"} rounded-3 my-1 px-3 py-2`}
          >
            <div className="d-flex justify-content-between">
              <div className="fw-bolder text-white fs-6">
                {order.commodity.title.en.length > 30
                  ? order.commodity.title.en.substring(0, 30) + "..."
                  : order.commodity.title.en}
              </div>
              {[I_IN_TRANSIT, I_BEFORE_ARRIVAL].includes(columnName) && !storageOrder && !isBackToBackOrder(order) && (
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-outline btn-outline-light btn-sm mb-2 ms-2 px-2 p-1"
                    onClick={handleCreateSTO}
                  >
                    Create StO
                  </button>
                </div>
              )}
            </div>
            <Link
              className="custom-link text-white fs-6"
              to={(isSO ? "/supplierOrder/" : "/customerOrder/") + order._id.toString()}
            >
              {`${getOrderNumber(order)}${isSO && isB2B ? " (B2B)" : ""}`}
            </Link>
            <table className="table table-no-padding fw-bold gy-0 mb-0">
              <tbody>
                {[I_RECEIPT, O_RECEIPT].includes(columnName) && isSO && order.etd && (
                  <tr>
                    <td className="text-white w-50">ETD</td>
                    <td className="text-muted">{formatDate(order.etd)}</td>
                  </tr>
                )}
                <tr>
                  <td className="text-white w-50">
                    ETA
                    <Tooltip tooltipText={<OrderDateOverview order={order} />}>
                      <span className="ml-1">
                        <i className="fa fa-info-circle text-white fa-xs" />
                      </span>
                    </Tooltip>
                  </td>
                  <td
                    className={
                      arrival.late && arrival.target && [I_BEFORE_ARRIVAL, I_INCOMING].includes(columnName)
                        ? "text-danger"
                        : "text-muted"
                    }
                  >
                    {formatOrderDate(order)}
                    {[I_RECEIPT, I_IN_TRANSIT, I_BEFORE_ARRIVAL, O_RECEIPT, O_FORWARDER].includes(columnName) &&
                      !order.deliveryDate && (
                        <ChangeETAModal order={order} action={ChangeDateAction.CHANGEETA}></ChangeETAModal>
                      )}
                  </td>
                </tr>
                {[I_RECEIPT, O_RECEIPT].includes(columnName) && (
                  <tr>
                    <td className="text-white w-50">Transport</td>
                    <td className="text-muted">{getInternalTransportTypeLabel(order.transport)}</td>
                  </tr>
                )}
                {columnName === I_RECEIPT && isSO && (
                  <tr>
                    <td className="text-white w-50">Starting Point</td>
                    <td className="text-muted">
                      {order.terms?.deliveryTerms
                        ? `${order.terms.deliveryTerms} ${getSupplierOrderStartString(order)}`
                        : getSupplierOrderStartString(order)}
                    </td>
                  </tr>
                )}
                {columnName === I_RECEIPT && order.supplier && (
                  <tr>
                    <td className="text-white w-50">Supplier</td>
                    <td className="text-muted">{order.supplier.name}</td>
                  </tr>
                )}
                <tr>
                  <td className="text-white w-50">Amount</td>
                  <td className="text-muted">{formatUnit(order.amount, order.unit)}</td>
                </tr>
                {columnName === O_RECEIPT && isCustomerOrder(order) && order.company && (
                  <tr>
                    <td className="text-white w-50">Ordered by</td>
                    <td className="text-muted">
                      <Link className="text-muted custom-link" to={"/customer/" + order.company._id.toString()}>
                        {order.company.name}
                      </Link>
                    </td>
                  </tr>
                )}
                {columnName === O_RECEIPT && (
                  <>
                    <tr>
                      <td className="text-white w-50">Starting Point</td>
                      <td className="text-muted">{isSO ? getSupplierOrderStartString(order) : "-"}</td>
                    </tr>
                    <tr>
                      <td className="text-white w-50">Destination Address</td>
                      <td className="text-muted">
                        {isSO
                          ? glomm?.address
                            ? formatAddress(glomm.address)
                            : "-"
                          : typeof order.destination === "string"
                          ? order.destination
                          : formatAddress(order.destination)}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          {[I_INCOMING, I_BEFORE_ARRIVAL, I_IN_TRANSIT].includes(columnName) && storageOrder && (
            <div>
              <div className="bg-custom-medium-gray rounded-3 px-3 py-2 ms-8">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-bolder text-white fs-6">
                    Storage Order
                    <span className="ml-2">
                      {storageOrderFile && (
                        <Tooltip tooltipText={"Storage Order"}>
                          <a href={resolveFilePath(storageOrderFile.path)} target="_blank" rel="noopener noreferrer">
                            <img
                              src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                              style={{ height: 24 }}
                              alt="PDF"
                            />
                          </a>
                        </Tooltip>
                      )}
                      {storageNoticeFile && (
                        <Tooltip tooltipText={"Storage Notice"}>
                          <a href={resolveFilePath(storageNoticeFile.path)} target="_blank" rel="noopener noreferrer">
                            <img
                              src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                              style={{ height: 24 }}
                              alt="PDF"
                            />
                          </a>
                        </Tooltip>
                      )}
                    </span>
                  </div>
                  <UploadStorageNoticeModal buttonText="Upload StN" storageOrder={storageOrder} disabled={false} />
                </div>
                <div className="text-white fs-6">{`ST-${storageOrder.storageOrderNo}`}</div>
                <table className="table table-no-padding fw-bold gy-0">
                  <tbody>
                    <tr>
                      <td className="text-white w-50">Delivery</td>
                      <td className="text-muted">
                        {formatDateWithType(storageOrder.deliveryDate, storageOrder.deliveryDateType)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default SCMDashboardOrderKanban;
