import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import OpenCommodityIcon from "../../../common/OpenCommodityIcon";
import {
  SO_ARRIVEDATSTARTINGPORT,
  SO_CANCELED,
  SO_REQUESTED,
  SupplierOrderExtended,
} from "../../../../model/supplierOrder.types";
import { formatCurrency, formatDate, pluralize } from "../../../../utils/baseUtils";
import {
  calculateArrivalInformation,
  ChangeDateAction,
  getForwarderFromOrder,
  getOrderNumber,
  getOrderStateDescriptions,
  getOrderType,
  getPartialShipmentStateDescription,
} from "../../../../utils/orderUtils";
import { getUserName, INTERNAL } from "../../../../utils/userUtils";
import HoverPopover from "../../../common/HoverPopover";
import ChangeEtaModal from "./modals/ChangeETAModal";
import { getCW } from "../../../../utils/dateUtils";
import { getSeaportName, isSeaport } from "../../../../utils/seaportUtils";
import { getAirportName, isAirport } from "../../../../utils/airportUtils";
import { T_EUSTOCK } from "../../../../model/customerOrder.types";
import { formatAddress } from "../../../../utils/addressUtils";
import userService from "../../../../services/userService";
import UpdateCommoditySnapshotModal from "../../../common/internal/modals/UpdateCommoditySnapshotModal";
import { DataContextInternalType } from "../../../../context/dataContext";
import { isActive } from "../../../../utils/supplierOrderUtils";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import CancelSupplierOrderModal from "./modals/CancelSupplierOrderModal";

import { extendCustomerOrder } from "../../../../utils/dataTransformationUtils";
import { Incoterm } from "../../../../utils/commodityUtils";
import Tooltip from "../../../common/Tooltip";

interface SupplierOrderSummaryProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

class SupplierOrderSummary extends PureComponent<SupplierOrderSummaryProps> {
  getAmountOfDestinations = () => {
    const { order, context } = this.props;
    if (order.customerOrders.length === 0 && !order.warehouseAmount) return "";
    const cus = (
      <HoverPopover
        popoverStyle={{ maxWidth: "95vw", border: "none" }}
        content={
          <div className="card overflow-auto card-amount-destinations">
            <div className="card-body">
              <div className="text-white">
                {order.customerOrders.map((cO, idx) => {
                  const extendedCO = extendCustomerOrder(cO, context);
                  return (
                    <div key={cO._id.toString()} className="row" style={{ minWidth: "300px" }}>
                      <div className="col-7 px-2 my-auto fw-bolder fs-7 text-left">
                        <Link
                          className="custom-link text-white"
                          to={`/customer/${extendedCO.company._id.toString()}`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {extendedCO.company.name}
                        </Link>
                      </div>
                      <div className="col-5 pr-2 pl-0 my-auto fs-7 text-left">
                        {cO.amount + formatArticleUnit(cO.unit, cO.commodity)}
                      </div>
                      {idx < order.customerOrders.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      >
        <span>{pluralize(order.customerOrders.length, "customer")}</span>
      </HoverPopover>
    );
    const wh = order.warehouseAmount ? "and warehouse" : "";
    return (
      <span>
        for {cus} {wh}
      </span>
    );
  };

  render() {
    const { order, context } = this.props;
    const stateDescription = getOrderStateDescriptions(order);
    const arrival = calculateArrivalInformation(order, order.changedETA);
    let changedEtaCW;
    if (order.changedETA) {
      changedEtaCW = getCW(order.changedETA);
    }
    const targetDateCW = getCW(order.targetDate);
    const today = new Date();
    const todayInAWeek = new Date(today.setDate(today.getDate() + 7));
    const cloneETD = order.etd ? new Date(order.etd) : undefined;
    const etdPlusOneWeek = cloneETD ? new Date(cloneETD.setDate(cloneETD.getDate() + 7)) : undefined;
    const view = userService.getUserType();
    const active = isActive(order);
    const forwarder = getForwarderFromOrder(context, order);
    const etdNotConfirmedInLessThanWeek =
      order.etd &&
      (order.shipment.length === 0 || !order.shipment[0].readyToShip) &&
      etdPlusOneWeek &&
      order.etd <= todayInAWeek;

    const isCIF = order.terms?.deliveryTerms === Incoterm.CIF;
    const startingPoint = order.shipment.length > 0 ? order.shipment[0].shipping.startingPoint : undefined;
    const destination = order.shipment.length > 0 ? order.shipment[0].shipping.destination : undefined;
    const deliveryTermsPort = isCIF && destination ? destination : startingPoint;
    return (
      <div>
        <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
          <div className="card-body">
            <div className="d-flex flex-center flex-column py-5">
              <span className="fs-2 text-gray-800 fw-bolder mb-3">{getOrderNumber(order)}</span>
              <span className="badge badge-pill badge-warning mb-3">Supplier Order</span>
              {order.commissionData && (
                <span className="badge badge-pill badge-light-dark text-uppercase mb-3">Commission</span>
              )}
              {order.paymentInformation?.paid ? (
                <span className="badge badge-pill badge-success mb-3">Paid</span>
              ) : (
                order.paymentInformation?.partiallyPaidAmount && (
                  <Tooltip
                    tooltipText={
                      <span className="text-white">
                        {formatCurrency(order.paymentInformation.partiallyPaidAmount, order.currency)} already paid
                      </span>
                    }
                  >
                    <span className="badge badge-pill badge-warning mb-3">Partially Paid</span>
                  </Tooltip>
                )
              )}
              <div className="fs-6 fw-bold text-muted">
                {order.commodity.title.en}
                <OpenCommodityIcon article={order.commodity} />
              </div>
              <div className="fw-bold text-muted mb-3">{order.commodity.subtitle.en}</div>
              <div className="mb-6">
                <div className="d-inline text-white">{this.getAmountOfDestinations()}</div>
              </div>
              <div className="mb-6">
                <span
                  className="badge fw-bolder badge-lg my-2"
                  style={{ backgroundColor: arrival.late && arrival.target ? "#f15151" : "#50cc89ff" }}
                >
                  {arrival.target ? (
                    <span className="mb-1">
                      {arrival.weekDiff} {arrival.weekDiff === 1 ? "week" : "weeks"}{" "}
                      {arrival.late ? "overdue" : "remaining"}
                    </span>
                  ) : (
                    <span className="mb-1">delivered</span>
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex flex-stack fs-4 py-3">
              <div className="fw-bolder rotate collapsible">Order Details</div>
            </div>
            <div className="border-bottom-dark-gray" />
            <div className="pb-5 fs-6">
              <div className="fw-bolder mt-5">Amount</div>
              <div className="text-muted">
                {order.amount} {formatArticleUnit(order.unit, order.commodity)}
              </div>
              {order.shipment.length > 1 &&
                order.shipment.map((pS) => (
                  <div key={pS._id.toString()} className="text-muted">
                    Partial: {pS.amount} {formatArticleUnit(order.unit, order.commodity)}
                  </div>
                ))}
              <div className="fw-bolder mt-5">Type</div>
              <div className="text-muted">{getOrderType(order)}</div>
              {order.transport === T_EUSTOCK && (
                <>
                  <div className="fw-bolder mt-5">Supplier EU Warehouse</div>
                  <div className="text-muted">
                    {startingPoint
                      ? typeof startingPoint !== "string" && "street" in startingPoint
                        ? formatAddress(startingPoint, { withComma: true })
                        : order.purchaseOrderInformation?.startingEUWarehouse
                        ? formatAddress(order.purchaseOrderInformation.startingEUWarehouse, { withComma: true })
                        : "-"
                      : "-"}
                  </div>
                </>
              )}
              <div className="fw-bolder mt-5">Status</div>
              <div className={order.state === SO_CANCELED ? "text-danger" : "text-warning"}>
                {stateDescription.title}
              </div>
              {(order.shipment.length > 1 ||
                order.shipment[0]?.amount < order.amount ||
                (order.shipment.length > 0 && order.shipment[0].state === SO_ARRIVEDATSTARTINGPORT)) &&
                order.shipment.map((pS) => (
                  <div key={pS._id.toString()} className="text-warning">
                    {order.shipment.length === 1 ? "" : "Partial: "}
                    {getPartialShipmentStateDescription(pS.state).title}
                  </div>
                ))}
              {order.etd && (
                <>
                  <div className="fw-bolder mt-5">
                    Target Departure (ETD)
                    <ChangeEtaModal order={order} action={ChangeDateAction.CHANGEETD} />
                  </div>
                  <Tooltip
                    tooltipText={
                      etdNotConfirmedInLessThanWeek ? "ETD is in less than a week and not confirmed yet" : ""
                    }
                    show={etdNotConfirmedInLessThanWeek ? undefined : false}
                  >
                    <div className={etdNotConfirmedInLessThanWeek ? "text-warning" : "text-muted"}>
                      CW {getCW(order.etd)}-{order.etd.getFullYear()}
                    </div>
                  </Tooltip>
                </>
              )}
              <div className="fw-bolder mt-5">
                {arrival.target ? "Target Week (ETA)" : "Delivery Week"}
                {active && arrival.target && <ChangeEtaModal order={order} action={ChangeDateAction.CHANGEETA} />}
              </div>
              <div
                className={
                  changedEtaCW && changedEtaCW !== targetDateCW && changedEtaCW < getCW(new Date())
                    ? "text-danger"
                    : changedEtaCW && changedEtaCW !== targetDateCW
                    ? "text-warning"
                    : "text-muted"
                }
              >
                CW {arrival.cw}-{arrival.year}
              </div>
              <div className="fw-bolder mt-5">Original Target Week</div>
              <div className="text-muted">
                CW {getCW(order.targetDate)}-{order.targetDate.getFullYear()}
              </div>
              <div className="fw-bolder mt-5">Last Update</div>
              <div className="text-muted">
                {formatDate(
                  order.timeline.length > 0 ? order.timeline[order.timeline.length - 1].date : order.createdAt
                )}
              </div>
              <div className="fw-bolder mt-5">Supplier</div>
              <div>
                <Link className="text-muted custom-link" to={"/supplier/" + order.supplier._id.toString()}>
                  {order.supplier.name}
                </Link>
              </div>
              {order.terms && (
                <>
                  <div className="fw-bolder mt-5">Payment Terms</div>
                  <div className="text-muted">
                    {order.terms.paymentTerms} {order.terms.paymentTermConditions}
                  </div>
                  <div className="fw-bolder mt-5">Delivery Terms</div>
                  <div className="text-muted">
                    {`${order.terms.deliveryTerms} 
                    ${
                      deliveryTermsPort
                        ? isSeaport(deliveryTermsPort)
                          ? getSeaportName(deliveryTermsPort)
                          : isAirport(deliveryTermsPort)
                          ? getAirportName(deliveryTermsPort)
                          : ""
                        : order.purchaseOrderInformation?.startingSeaport
                        ? getSeaportName(order.purchaseOrderInformation.startingSeaport)
                        : order.purchaseOrderInformation?.startingAirport
                        ? getAirportName(order.purchaseOrderInformation.startingAirport)
                        : ""
                    }`}
                  </div>
                </>
              )}
              {forwarder && (
                <>
                  <div className="fw-bolder mt-5">Forwarder</div>
                  <div>
                    <Link className="text-muted custom-link" to={"/forwarder/" + forwarder._id.toString()}>
                      {forwarder.name}
                    </Link>
                  </div>
                </>
              )}
              <div className="fw-bolder mt-5">Created by</div>
              <div className="text-muted">{getUserName(order.person)}</div>
            </div>
            {active && view === INTERNAL && <UpdateCommoditySnapshotModal order={order} context={context} />}
            {order.state === SO_REQUESTED && (
              <>
                <br />
                <Link
                  to={`/editSupplierOrder${
                    order.customerContracts?.length
                      ? "/contract/" + order.customerContracts[0]._id.toString()
                      : order.customerOrders.length
                      ? "/" + order.customerOrders[0]._id.toString()
                      : ""
                  }?edit=${order._id.toString()}`}
                  className="btn btn-light btn-sm mt-5"
                >
                  Edit Order
                </Link>
              </>
            )}
            {active && view === INTERNAL && (
              <>
                <br />
                <CancelSupplierOrderModal context={context} order={order} />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierOrderSummary;
