import _ from "lodash";
import { BSON } from "realm-web";
import { callFunction } from "../services/dbService";
import {
  ForwardingOrder,
  ForwardingOrderInformation,
  ForwardingOrderTimelineEntry,
  ForwardingOrderTimelineEntryPayload,
  FWO_STATES,
  FWO_TIMELINE,
} from "../model/forwardingOrder.types";
import { SO_ARCHIVED, SO_CANCELED, SupplierOrder, SupplierOrderExtended } from "../model/supplierOrder.types";
import {
  CO_ARCHIVED,
  CO_CANCELED,
  CustomerOrder,
  CustomerOrderExtended,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_RAILFREIGHT,
} from "../model/customerOrder.types";
import { DataContextInternalType } from "../context/dataContext";
import {
  DateType,
  getOrderNumber,
  isCustomerOrder,
  isSupplierOrder,
  O_TRANSPORTTYPES_FORWARDINGORDER,
} from "./orderUtils";
import { getSupplierOrderStartString, SO_ORDERCONFIRMATION } from "./supplierOrderUtils";
import { UserData } from "../model/userData.types";
import { getAddressString, getStandardWarehouse, isAddress } from "./addressUtils";
import { Address, OrderFile } from "../model/commonTypes";
import userService from "../services/userService";
import { getDaysBetween } from "./dateUtils";
import { SelectOption } from "../components/common/CustomSelect";
import { AdditionalDateTypeLogistics, hasFixedDate } from "./logisticsUtils";
import { extendCustomerOrder, extendSupplierOrder } from "./dataTransformationUtils";
import { isAnyFinishedProduct } from "./productArticleUtils";

export interface OverwriteFiles {
  order: SupplierOrder | CustomerOrder | SupplierOrderExtended | CustomerOrderExtended;
  file: OrderFile;
  overwrite: boolean;
}

export interface AddressOrStringSelection extends SelectOption {
  address: string | Address;
  text: string;
  additionalInfo: string;
  backupUsed?: boolean;
}

export interface ForwardingOrderInfoCreation
  extends Omit<ForwardingOrderInformation, "customsCleared" | "sender" | "recipient" | "destinationIncoterm"> {
  customsCleared: boolean;
  destinationIncotermSelect: SelectOption;
  destinationTo: string;
  sender: string;
  senderSelect: AddressOrStringSelection;
  senderSelectOptions: Array<AddressOrStringSelection>;
  recipientSelect: AddressOrStringSelection;
  recipientSelectOptions: Array<AddressOrStringSelection>;
  recipient: string;
}

export const UPSERTFORWARDINGORDER = "upsertForwardingOrder";

export const FWO_REMARKS_SEA =
  "<ul><li>Please clarify the FOB acceptance, etc. directly with the sender.</li><li>Shipment by Sea – pls combine with other current shipments to save costs.</li><li>Please provide shipping schedule asap to contact ms@rawbids.com</li><li>Please deliver shipment directly to the given transport address after import is completed.</li><li>Import and customs clearance by LSP</li></ul><span>In case of any damage please inform us before transportation of goods. Please let us have your shipping advice and don’t hesitate to contact us for any further requests. </span>";

export const FWO_REMARKS_AIR =
  "<ul><li>Please clarify the FOB acceptance, etc. directly with the sender.</li><li>Shipment by Air.</li><li>Please provide shipping schedule asap to contact ms@rawbids.com</li><li>Please deliver shipment directly to the given transport address after import is completed.</li><li>Import and customs clearance by LSP</li></ul><span>In case of any damage please inform us before transportation of goods. Please let us have your shipping advice and don’t hesitate to contact us for any further requests. </span>";

export const FWO_REMARKS_ROAD =
  "<ul><li>Please put the delivery note and the CoA on the palette </li><li>Please notify the delivery date 24 hours in advance to ms@rawbids.com</li></ul><span>In case of any damage please inform us before transportation of goods. Please let us have your shipping advice and don’t hesitate to contact us for any further requests. Please send us the POD asap. </span>";

export const FWO_REMARKS_RAIL =
  "<ul><li>Please clarify the FOB acceptance, etc. directly with the sender.</li><li>Shipment by Rail.</li><li>Please provide shipping schedule asap to contact ms@rawbids.com</li><li>Please deliver shipment directly to the given transport address after import is completed.</li><li>Import and customs clearance by LSP</li></ul><span>In case of any damage please inform us before transportation of goods. Please let us have your shipping advice and don’t hesitate to contact us for any further requests. </span>";

export const FWO_REMARKS_SEAAIRCOMBINED =
  "<ul><li>Please clarify the FOB acceptance, etc. directly with the sender.</li><li>Shipment by Sea / Air combined.</li><li>Please provide shipping schedule asap to contact ms@rawbids.com</li><li>Please deliver shipment directly to the given transport address after import is completed.</li><li>Import and customs clearance by LSP</li></ul><span>In case of any damage please inform us before transportation of goods. Please let us have your shipping advice and don’t hesitate to contact us for any further requests. </span>";

// warnings
export enum FWO_WARNINGS {
  DIFFERENTDESTINATIONS = "ordersWithDifferentDestinations",
  DIFFERENTTRANSPORTCONDITIONS = "commoditiesWithDifferentTransportConditions",
  DIFFERENTTRANSPORTTYPESO = "differentTransportTypeSO",
  DIFFERENTSTARTS = "differentStarts",
  ETDSOTODIFFERNT = "etdToDifferent",
  ETACOTODIFFERENT = "etaCOToDifferent",
  SUPPLIERSOAIRDIFFERENT = "supplierSOAirDifferent",
  ETAWASFIXED = "etaWasFixed",
  SENDERCONTACTNOTFROMADDRESS = "senderContactNotFromAddress",
  ALREADYEXISTINGFWOS = "forwardingOrdersExistingForOrder",
}

/**
 * Inserts a new forwardingOrder into the database.
 * @param forwardingOrder forwardingOrder that should be inserted into the database
 * @returns { Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; forwardingOrderNo: string } | false> } Result of the function
 */
export async function insertForwardingOrder(
  forwardingOrder: ForwardingOrder
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; forwardingOrderNo: string } | false> {
  return (await callUpsertForwardingOrder(forwardingOrder, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; forwardingOrderNo: string }
    | false;
}

/**
 * Updates an existing forwardingOrder inside the database.
 * @param forwardingOrder forwardingOrder that should be updated inside the database
 * @param forwardingOrderId optional id of forwarding order if it is not contained in forwarding order object
 * @param timelineEntry Optional timeline entry
 * @returns { Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; forwardingOrderNo: string } | false> } Result of the function
 */
export async function updateForwardingOrder(
  forwardingOrder: Partial<ForwardingOrder>,
  forwardingOrderId: BSON.ObjectId,
  timelineEntry?: ForwardingOrderTimelineEntry
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; forwardingOrderNo: string } | false> {
  if (forwardingOrderId) forwardingOrder._id = forwardingOrderId;
  return (await callUpsertForwardingOrder(forwardingOrder, false, timelineEntry)) as
    | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; forwardingOrderNo: string }
    | false;
}

/**
 * Calls the upsert forwardingOrder function in backend.
 * @param forwardingOrder forwardingOrder that should be upsert
 * @param insert True for insert, else update
 * @param timelineEntry Optional timeline entry
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
async function callUpsertForwardingOrder(
  forwardingOrder: Partial<ForwardingOrder>,
  insert: boolean,
  timelineEntry?: ForwardingOrderTimelineEntry
): Promise<
  Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false
> {
  return callFunction(UPSERTFORWARDINGORDER, [forwardingOrder, insert, timelineEntry]);
}

/**
 * Get all available orders of context or only matching ones to the given order
 * @param context the internal context with all supplier and customer orders
 * @param order optional, the order the other orders have to match
 * @param usedOrders optional, the orders already used
 * @returns {Array<SelectOption<SupplierOrderExtended | CustomerOrderExtended>>} the available orders as OrderSelectOption
 */
export function getAvailableOrders(
  context: DataContextInternalType,
  order?: SupplierOrderExtended | CustomerOrderExtended,
  usedOrders?: Array<string>
): Array<SelectOption<SupplierOrderExtended | CustomerOrderExtended>> {
  let availableOrders: Array<SelectOption<SupplierOrderExtended | CustomerOrderExtended>> = [];
  const supplierOrders = context.supplierOrder.filter(
    (sO) => sO.state !== SO_CANCELED && sO.state !== SO_ARCHIVED && sO.shipment[0]?.readyToShip
  );
  const customerOrders = context.customerOrder.filter((cO) => cO.state !== CO_CANCELED && cO.state !== CO_ARCHIVED);
  // get all orders
  for (let i = 0; i < supplierOrders.length; i++) {
    if (supplierOrders[i].files.some((f) => f.type === SO_ORDERCONFIRMATION)) {
      availableOrders.push({
        value: supplierOrders[i]._id.toString(),
        label: getOrderNumber(supplierOrders[i]),
        object: extendSupplierOrder(supplierOrders[i], context),
      });
    }
  }
  for (let i = 0; i < customerOrders.length; i++) {
    const orderBatches = customerOrders[i].usedBatches;
    if (orderBatches && orderBatches.length > 0 && orderBatches.some((uB) => uB.supplierCoA)) {
      availableOrders.push({
        value: customerOrders[i]._id.toString(),
        label: getOrderNumber(customerOrders[i]),
        object: extendCustomerOrder(customerOrders[i], context),
      });
    }
  }
  // filter orders matching with main order
  if (order) {
    availableOrders = availableOrders.filter(
      (aO) =>
        aO.object &&
        isSupplierOrder(order) === isSupplierOrder(aO.object) &&
        aO.object._id.toString() !== order._id.toString() &&
        order.terms?.deliveryTerms === aO.object.terms?.deliveryTerms &&
        ((isSupplierOrder(aO.object) &&
          isSupplierOrder(order) &&
          aO.object.transport === order.transport &&
          getSupplierOrderStartString(aO.object) === getSupplierOrderStartString(order)) ||
          (isCustomerOrder(aO.object) && isCustomerOrder(order)))
    );
  }
  // filter already used orders
  if (usedOrders && usedOrders.length > 0) {
    availableOrders = availableOrders.filter((aO) => !usedOrders.includes(aO.object?._id.toString() ?? ""));
  }

  // filter orders without incoterm DDP which are of type EU stock or from an EU supplier
  // filter orders that still need a forwarding order since their full amount is not transported yet or no forwarding order exists yet
  availableOrders = availableOrders.filter((aO) => aO.object && orderNeedsForwarding(aO.object, context));
  return availableOrders;
}

/**
 * Resolves the label for a given transport method
 * @param transportMethod the transport method
 * @returns {string | undefined} the matching label or undefined if no label for the given transport method was found
 */
export function getFWOTransportTypeLabel(transportMethod: string): string | undefined {
  return O_TRANSPORTTYPES_FORWARDINGORDER.find((obj) => obj.value === transportMethod)?.label;
}

/**
 * Returns if order could be a back to back order (not guaranteed)
 * @param order Supplier or Customer order
 * @returns {boolean} Flag if order could be back to back
 */
export function isBackToBackOrder(
  order: CustomerOrder | CustomerOrderExtended | SupplierOrder | SupplierOrderExtended
): boolean {
  if (isCustomerOrder(order)) {
    // CustomerOrder should always be back to back
    return true;
  } else {
    // orders with organic commodities should never be labeled as back to back
    return order.customerOrders.length === 1 && order.warehouseAmount === 0 && !order.commodity.organic;
  }
}

/**
 * Returns if forwarding order is an outbound order
 * @param order the forwarding order to check
 * @returns {boolean} true if order counts as outbound, false if not
 */
export function isOutboundOrder(order: ForwardingOrder): boolean {
  return order.transportType === T_EUSTOCK || order.transportType === T_RAILFREIGHT;
}

/**
 * Check if two orders have equal transportConditions
 * @param order1 the first customer or supplier order
 * @param order2 the second customer or supplier order
 * @returns {boolean} Flag if the transport conditions are equal
 */
export function checkTransportConditionsEqual(
  order1: CustomerOrder | CustomerOrderExtended | SupplierOrder | SupplierOrderExtended,
  order2: CustomerOrder | CustomerOrderExtended | SupplierOrder | SupplierOrderExtended
): boolean {
  // both conditions lead to we do not have transportConditions
  if (
    (order1.commodity.transportConditions === undefined && order2.commodity.transportConditions?.length === 0) ||
    (order2.commodity.transportConditions === undefined && order1.commodity.transportConditions?.length === 0)
  ) {
    return true;
  }
  // only check this if the other conditions are false
  else return _.isEqual(order1.commodity.transportConditions, order2.commodity.transportConditions);
}

/**
 * Creates a default forwardingOrderInfoCreation object for a given order
 * @param order customer order supplier order the forwardingOrderInfo should be created from
 * @returns {ForwardingOrderInfoCreation} the forwardingOrderInfo object on creation
 */
export function getDefaultForwardingOrderInfo(order: SupplierOrder | CustomerOrder): ForwardingOrderInfoCreation {
  return {
    _id: new BSON.ObjectId(),
    orderId: order._id.toString(),
    orderNo: order.orderNo,
    packaging: "",
    netWeight: isAnyFinishedProduct(order.commodity) ? order.amount * order.commodity.weightPerUnit : order.amount,
    grossWeight: 0,
    customsCleared: isCustomerOrder(order),
    destinationIncotermSelect: { value: "", label: "" },
    destinationTo: "",
    sender: "",
    senderSelect: { value: "", label: "", address: "", text: "", additionalInfo: "" },
    senderSelectOptions: [{ value: "", label: "", address: "", text: "", additionalInfo: "" }],
    recipientSelect: { value: "", label: "", address: "", text: "", additionalInfo: "" },
    recipientSelectOptions: [{ value: "", label: "", address: "", text: "", additionalInfo: "" }],
    recipient: "",
    recipientInformation: "",
    deliveryDate: new Date(),
    deliveryDateType:
      isCustomerOrder(order) && order.changedETAType
        ? order.changedETAType
        : isCustomerOrder(order) && order.targetDateType
        ? order.targetDateType
        : isCustomerOrder(order) && !order.targetDateType && !order.changedETAType
        ? undefined
        : AdditionalDateTypeLogistics.ASAP,
  };
}

/**
 * Get the address as a string with a contact person if available, as text string
 * @param context the internal context
 * @param address optional, the address as an Address or string
 * @param companyName optional, the name of the customer or supplier
 * @param contactPerson optional, contactPerson as UserData Object or as a string id
 * @param warehouse optional, flag if the address should be our warehouse
 * @param withOpeningHours optional, flag if opening hours should be included
 * @returns {string} The address as formatted string
 */
export function getAddress(
  context: DataContextInternalType,
  address?: Address | string,
  companyName?: string,
  contactPerson?: UserData | string,
  warehouse?: boolean,
  withOpeningHours?: boolean
): string {
  let html = "";
  let glomm;
  if (warehouse) {
    glomm = getStandardWarehouse(context);
    if (glomm) {
      html = `${glomm.contactPerson}\n${glomm.companyName}\n${getAddressString(glomm.address)}\nTel: ${
        glomm.telephone
      }\nEmail: ${glomm.email}`;
    }
  } else if (address && companyName && contactPerson) {
    const addressString = getAddressString(address);
    const userData =
      typeof contactPerson !== "string"
        ? contactPerson
        : context.userData.find((uD) => uD._id.toString() === contactPerson);
    if (userData) {
      const mail =
        userData.emails.length > 0
          ? userData.emails.find((em) => em.description.toLowerCase() === "shipping")?.value || userData.emails[0].value
          : "";
      const tel =
        userData.phones.length > 0
          ? userData.phones.find((t) => t.description.toLowerCase() === "shipping")?.value || userData.phones[0].value
          : "";
      html = `${userData.prename + " " + userData.surname}${
        userData.position.trim() !== "" ? `\n${userData.position}` : ""
      }\n${companyName}\n${addressString}\nTel: ${tel}\nEmail: ${mail}`;
    } else {
      html = `${companyName}\n${addressString}`;
    }
  } else if (address) {
    const addressString = getAddressString(address);
    html = `${companyName || ""}\n${addressString}`;
  }
  if (withOpeningHours) {
    const openingHours =
      address && isAddress(address) ? address.openingHours : glomm ? glomm.address.openingHours : undefined;
    if (openingHours) {
      html += `\n\nOpening hours:\n${openingHours}`;
    }
  }
  return html;
}

/**
 * Retrieves the contact person from an address. If the address is a string or no contact person is given, uses the primary person of the given supplier id. If none was given, undefined is returned
 * @param context the internal context to retrieve the supplier from
 * @param address the address to check for a contact person
 * @param supplierId the id from the supplier from which the primary person should be taken if no contact person is stored in the address
 * @return { {contactPerson: string | UserData | undefined, backupUsed: boolean} } the contactPerson, either from the address (backupUsed false), from the supplier (backupUsed true) or undefined
 */
export function getSupplierAddressContactPerson(
  context: DataContextInternalType,
  address: string | Address,
  supplierId: string
): { contactPerson: string | UserData | undefined; backupUsed: boolean } {
  if (isAddress(address) && address.contactPerson) {
    return { contactPerson: address.contactPerson, backupUsed: false };
  }
  // if no contactPerson exists on the address, try to retrieve primary person of given supplier as backup
  const supplier = context.supplier.find((s) => s._id.toString() === supplierId);
  if (supplier && supplier.primaryPerson) {
    return { contactPerson: supplier.primaryPerson, backupUsed: true };
  }
  return { contactPerson: undefined, backupUsed: false };
}

/**
 * Retrieves the recipient from an order based on if it is a supplier/customer order and a back to back order
 * @param order customer or supplier order from which the recipient should be retrieved
 * @param context the internal context to retrieve the userdata and warehouse from
 * @return { string } the recipient address as formatted string
 */
export function getRecipient(
  order: CustomerOrderExtended | SupplierOrderExtended,
  context: DataContextInternalType
): string {
  let recipient;
  if (isSupplierOrder(order)) {
    if (isBackToBackOrder(order)) {
      const cOExtended = extendCustomerOrder(order.customerOrders[0], context);
      recipient = getAddress(
        context,
        cOExtended.destination,
        cOExtended.company.name,
        isAddress(cOExtended.destination) && cOExtended.destination.contactPerson
          ? cOExtended.destination.contactPerson
          : "",
        false
      );
    } else {
      recipient = getAddress(context, undefined, undefined, undefined, true);
    }
  } else {
    recipient = getAddress(
      context,
      order.destination,
      order.company.name,
      isAddress(order.destination) && order.destination.contactPerson ? order.destination.contactPerson : "",
      false
    );
  }
  return recipient;
}

/**
 * Get the warnings for the current order selection
 * @param context the internal context
 * @param orderSelect OrderSelectOptions which contain orders selected
 * @param orderInformation the order information for this forwarding order
 * @param transportType the transport type of the forwarding order
 * @param ordersArray optional, supplierOrders or customerOrders which should be used instead of orderSelect and orderInformation
 * @returns {Array<string>} The warnings for the current order selection
 */
export function getWarnings(
  context: DataContextInternalType,
  orderSelect?: Array<SelectOption<SupplierOrderExtended | CustomerOrderExtended>>,
  orderInformation?: Array<ForwardingOrderInfoCreation>,
  transportType?: string,
  ordersArray?: Array<SupplierOrderExtended | CustomerOrderExtended>
): Array<string> {
  const orders = orderSelect ? orderSelect.map((o) => o.object) : ordersArray;
  if (!orders) return [];
  const warnings: Set<string> = new Set();
  if (
    transportType &&
    !warnings.has(FWO_WARNINGS.DIFFERENTTRANSPORTTYPESO) &&
    orders.some((o) => isSupplierOrder(o) && o.transport !== transportType)
  ) {
    warnings.add(FWO_WARNINGS.DIFFERENTTRANSPORTTYPESO);
  }
  if (
    !warnings.has(FWO_WARNINGS.ETAWASFIXED) &&
    orderInformation &&
    orders.some((o) =>
      orderInformation.some(
        (oi) => o && oi.orderId === o._id.toString() && hasFixedDate(o) && oi.deliveryDateType !== DateType.FIX
      )
    )
  ) {
    warnings.add(FWO_WARNINGS.ETAWASFIXED);
  }
  if (
    !warnings.has(FWO_WARNINGS.SENDERCONTACTNOTFROMADDRESS) &&
    orderInformation &&
    orderInformation.some((oI) => oI.senderSelect.backupUsed === true)
  ) {
    warnings.add(FWO_WARNINGS.SENDERCONTACTNOTFROMADDRESS);
  }
  for (let i = 0; i < orders.length; i++) {
    const order = orders[i];
    if (order) {
      const netWeightInAllExistingFWOs = getNetWeightOfOrderInExistingForwardingOrders(order, context);
      if (
        (!warnings.has(FWO_WARNINGS.ALREADYEXISTINGFWOS) && isAnyFinishedProduct(order.commodity)
          ? order.amount * order.commodity.weightPerUnit > netWeightInAllExistingFWOs
          : order.amount > netWeightInAllExistingFWOs) &&
        netWeightInAllExistingFWOs !== 0
      ) {
        warnings.add(FWO_WARNINGS.ALREADYEXISTINGFWOS);
      }
    }
    for (let j = i + 1; j < orders.length; j++) {
      const order1 = orders[i];
      const order2 = orders[j];
      if (!order1 || !order2) continue; // Should never happen
      if (
        !warnings.has(FWO_WARNINGS.DIFFERENTDESTINATIONS) &&
        ((orderInformation && orderInformation[i].recipient !== orderInformation[j].recipient) ||
          (ordersArray && getRecipient(order1, context) !== getRecipient(order2, context)))
      ) {
        warnings.add(FWO_WARNINGS.DIFFERENTDESTINATIONS);
      }
      if (!warnings.has(FWO_WARNINGS.DIFFERENTTRANSPORTCONDITIONS) && !checkTransportConditionsEqual(order1, order2))
        warnings.add(FWO_WARNINGS.DIFFERENTTRANSPORTCONDITIONS);
      if (
        !warnings.has(FWO_WARNINGS.DIFFERENTSTARTS) &&
        isSupplierOrder(order1) &&
        isSupplierOrder(order2) &&
        getSupplierOrderStartString(order1) !== getSupplierOrderStartString(order2)
      )
        warnings.add(FWO_WARNINGS.DIFFERENTSTARTS);
      if (
        !warnings.has(FWO_WARNINGS.ETDSOTODIFFERNT) &&
        isSupplierOrder(order1) &&
        isSupplierOrder(order2) &&
        order1.etd &&
        order2.etd &&
        Math.abs(getDaysBetween(order1.etd, order2.etd)) > 7
      )
        warnings.add(FWO_WARNINGS.ETDSOTODIFFERNT);
      if (
        !warnings.has(FWO_WARNINGS.ETACOTODIFFERENT) &&
        isCustomerOrder(order1) &&
        isCustomerOrder(order2) &&
        Math.abs(getDaysBetween(order1.targetDate, order2.targetDate)) > 7
      )
        warnings.add(FWO_WARNINGS.ETACOTODIFFERENT);
      if (
        !warnings.has(FWO_WARNINGS.SUPPLIERSOAIRDIFFERENT) &&
        isSupplierOrder(order1) &&
        isSupplierOrder(order2) &&
        order1.transport === T_AIRFREIGHT &&
        order2.transport === T_AIRFREIGHT &&
        order1.supplier._id.toString() !== order2.supplier._id.toString()
      )
        warnings.add(FWO_WARNINGS.SUPPLIERSOAIRDIFFERENT);
    }
  }
  return Array.from(warnings);
}

/**
 * Get the warning text for a specific warning in forwarding orders
 * @param warning string the warning that is set
 * @returns {string} The warning text for the given warning
 */
export function getWarningText(warning: string): string {
  switch (warning) {
    case FWO_WARNINGS.DIFFERENTTRANSPORTCONDITIONS:
      return "Transport conditions of articles in orders are not matching";
    case FWO_WARNINGS.DIFFERENTDESTINATIONS:
      return "Orders have different destination addresses";
    case FWO_WARNINGS.DIFFERENTTRANSPORTTYPESO:
      return "Transport type differs from supplier order";
    case FWO_WARNINGS.DIFFERENTSTARTS:
      return "Starting points differ";
    case FWO_WARNINGS.ETDSOTODIFFERNT:
      return "ETDs of at least two orders are more than one week apart";
    case FWO_WARNINGS.ETACOTODIFFERENT:
      return "Target dates of at least two orders are more than one week apart";
    case FWO_WARNINGS.SUPPLIERSOAIRDIFFERENT:
      return "Airfreight orders with differing suppliers";
    case FWO_WARNINGS.ETAWASFIXED:
      return "ETA of one or more orders was fixed and is not set as fixed now";
    case FWO_WARNINGS.SENDERCONTACTNOTFROMADDRESS:
      return "Sender contact of at least one order could not be loaded from address, using supplier primary contact";
    case FWO_WARNINGS.ALREADYEXISTINGFWOS:
      return "Already existing forwarding order(s)";
    default:
      return "Unknown Warning";
  }
}

/**
 * Generates a timeline entry for a forwarding order
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns { ForwardingOrderTimelineEntry } Timeline entry object
 */
export function getForwardingOrderTimelineEntry(
  type: FWO_TIMELINE,
  payload?: ForwardingOrderTimelineEntryPayload
): ForwardingOrderTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  };
}

/**
 * Retrieve all customer or supplier orders included in the given forwarding order
 * @param forwardingOrder the forwarding order from which the orders should be retrieved from
 * @param context the internal context with all supplier and customer orders
 * @returns {Array<SupplierOrder | CustomerOrder>} the retrieved supplier or customer orders
 */
export function getOrdersFromFWO(
  forwardingOrder: ForwardingOrder,
  context: DataContextInternalType
): Array<SupplierOrderExtended | CustomerOrderExtended> {
  const ordersInFO: Array<SupplierOrderExtended | CustomerOrderExtended> = [];
  // currently it is impossible to know whether the ids in orderInformation are from customer or supplier orders, so both contexts have to be searched
  for (let i = 0; i < forwardingOrder.orderInformation.length; i++) {
    const order = context.supplierOrder.find((sO) => forwardingOrder.orderInformation[i].orderId === sO._id.toString());
    if (order === undefined && i === 0) break; // if first order is undefined, stop searching since orders are customerOrders
    if (order !== undefined) ordersInFO.push(extendSupplierOrder(order, context));
  }
  // check customerOrders if no supplierOrder was found
  if (ordersInFO.length === 0) {
    for (let i = 0; i < forwardingOrder.orderInformation.length; i++) {
      const order = context.customerOrder.find(
        (cO) => forwardingOrder.orderInformation[i].orderId === cO._id.toString()
      );
      if (order !== undefined) ordersInFO.push(extendCustomerOrder(order, context));
    }
  }
  return ordersInFO;
}

/**
 * Checks if an order still might need a forwarding order if no forwarding order(s) exist, that use the whole amount or full net weight of the order
 * @param order The order to be checked
 * @param context The internal data context
 * @returns {boolean} Flag, if the order might still need a forwarding order
 */
function orderNeedsForwarding(
  order: CustomerOrderExtended | SupplierOrderExtended,
  context: DataContextInternalType
): boolean {
  const amountSumAllForwardingOrders = getNetWeightOfOrderInExistingForwardingOrders(order, context);
  if (isAnyFinishedProduct(order.commodity)) {
    return order.amount * order.commodity.weightPerUnit > amountSumAllForwardingOrders;
  } else {
    return order.amount > amountSumAllForwardingOrders;
  }
}

/**
 * Get the sum of net weights for a given order that is used in all forwarding orders
 * @param order The order the net weights in forwarding orders should be summed up for
 * @param context The internal data context
 * @returns {number} The sum of net weights of all forwarding orders for this order
 */
export function getNetWeightOfOrderInExistingForwardingOrders(
  order: CustomerOrderExtended | SupplierOrderExtended,
  context: DataContextInternalType
): number {
  const forwardingOrdersForOrder = context.forwardingOrder.filter(
    (fw) => fw.orderInformation.some((o) => o.orderId === order._id.toString()) && fw.state !== FWO_STATES.FWO_CANCELED
  );
  if (forwardingOrdersForOrder.length === 0) return 0;
  else {
    const allOrderInfosWithThisOrder = forwardingOrdersForOrder.flatMap((fwo) =>
      fwo.orderInformation.filter((oi) => oi.orderId === order._id.toString())
    );
    return allOrderInfosWithThisOrder.reduce((sum, fwoInfo) => sum + fwoInfo.netWeight, 0);
  }
}
