import _ from "lodash";
import countryList from "i18n-iso-countries";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import {
  CustomerOrderExtended,
  CustomerOrderShippingInformation,
  T_WAREHOUSE,
} from "../../../../../../../model/customerOrder.types";
import { Batch } from "../../../../../../../model/batch.types";
import { Input } from "../../../../../../common/Input";
import DateInput from "../../../../../../common/DateInput";
import CustomSelect, { SelectOption } from "../../../../../../common/CustomSelect";
import { C_PACKAGE_OPTIONS } from "../../../../../../../utils/commodityUtils";
import {
  DI_UNITLOAD,
  getDefaultShippingGroup,
  getDefaultShippingInformation,
} from "../../../../../../../utils/deliveryInformationUtils";
import ErrorOverlayButton from "../../../../../../common/ErrorOverlayButton";
import { DataContextInternal } from "../../../../../../../context/dataContext";
import { Address, AddressType } from "../../../../../../../model/commonTypes";
import WysiwygEditor from "../../../../../../common/WYSIWYGEditor";
import Tooltip from "../../../../../../common/Tooltip";
import {
  AddressSelectOption,
  formatAddress,
  getAddressByType,
  isAddress,
} from "../../../../../../../utils/addressUtils";
import { round } from "../../../../../../../utils/baseUtils";

interface WorkflowDeliveryInformationModalProps {
  deliveryNote?: boolean;
  exists: boolean;
  order: CustomerOrderExtended;
  batches?: Array<Batch>;
  saving: boolean;
  shippingInformation?: CustomerOrderShippingInformation;
  disabled?: boolean;
  context: React.ContextType<typeof DataContextInternal>;
  onSaveDeliveryInformation: (shippingInformation: CustomerOrderShippingInformation) => void;
  onAddDeliveryInformation: (
    shippingInformation: CustomerOrderShippingInformation,
    deliveryAddress: EditorState
  ) => Promise<void>;
  onCreateDeliveryNoteDraft: (
    shippingInformation: CustomerOrderShippingInformation,
    deliveryAddress: EditorState
  ) => void;
  isEUStock?: boolean;
}

interface WorkflowDeliveryInformationModalState {
  show: boolean;
  shippingInformation: CustomerOrderShippingInformation;
  relevantBatches: Array<Batch>;
  selectedAddress?: AddressSelectOption;
  deliveryAddress: EditorState; // Type of the draft js WYSIWYG editor
}

class WorkflowDeliveryInformationModal extends PureComponent<
  WorkflowDeliveryInformationModalProps,
  WorkflowDeliveryInformationModalState
> {
  constructor(props: WorkflowDeliveryInformationModalProps) {
    super(props);

    const address = isAddress(props.order.destination)
      ? props.order.destination
      : getAddressByType(props.order.company.address, AddressType.A_SHIPPING);

    this.state = {
      show: false,
      shippingInformation: props.shippingInformation ?? getDefaultShippingInformation(props.order, props.batches),
      relevantBatches: props.context.batch.filter(
        (b) => b.commodity._id.toString() === props.order.commodity._id.toString()
      ),
      selectedAddress: address
        ? {
            label: formatAddress(address),
            value: formatAddress(address),
            address,
          }
        : undefined,
      deliveryAddress: this.getDeliveryAddress(),
    };
  }

  componentDidUpdate(
    prevProps: Readonly<WorkflowDeliveryInformationModalProps>,
    prevState: Readonly<WorkflowDeliveryInformationModalState>
  ) {
    if (
      this.props.shippingInformation &&
      _.isEqual(prevState, this.state) &&
      !_.isEqual(this.props.shippingInformation, this.state.shippingInformation) &&
      !_.isEqual(this.props.shippingInformation, prevProps.shippingInformation)
    ) {
      this.setState({ shippingInformation: this.props.shippingInformation });
    } else if (
      _.isEqual(prevState, this.state) &&
      (!_.isEqual(this.props.order, prevProps.order) || !_.isEqual(prevProps.batches, this.props.batches))
    ) {
      if (!_.isEqual(this.props.order.company.notes, prevProps.order.company.notes)) {
        const { order } = this.props;
        const shippingInformation = getDefaultShippingInformation(order);
        if (!shippingInformation.additionalInformation.includes(order.company.notes)) {
          shippingInformation.additionalInformation = `${shippingInformation.additionalInformation} ${order.company.notes}`;
        }
        this.setState({ shippingInformation });
      } else {
        this.setState({ shippingInformation: getDefaultShippingInformation(this.props.order) });
      }
    }
    if (!_.isEqual(prevProps.context.batch, this.props.context.batch)) {
      this.setState({
        relevantBatches: this.props.context.batch.filter(
          (b) => b.commodity._id.toString() === this.props.order.commodity._id.toString()
        ),
      });
    }
  }

  /**
   * Get editor state for an address
   * @param address optional, an address object
   * @returns {EditorState} editor state for wysiwyg editor
   */
  getDeliveryAddress = (address?: Address): EditorState => {
    const { order } = this.props;
    let html: string;
    if (address) {
      html = `<b>${order.company.name}</b><br/>${formatAddress(address, {
        withoutCountry: true,
      })}<br/>${countryList.getName(address.country, "en", {
        select: "alias",
      })}`;
    } else {
      const country =
        typeof order.destination === "string"
          ? order.company.address.find((a) => formatAddress(a) === order.destination)?.country
          : order.company.address.find((a) => a === order.destination)?.country;
      html = `<b>${order.company.name}</b><br/>${
        isAddress(order.destination) ? formatAddress(order.destination, { withoutCountry: true }) : order.destination
      }<br/>${
        country
          ? countryList.getName(country, "en", {
              select: "alias",
            })
          : "-"
      }`;
    }

    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  handleShow = () => {
    const { shippingInformation, order, batches, context } = this.props;
    const { selectedAddress } = this.state;
    let newShippingInformation;
    if (!shippingInformation) {
      newShippingInformation = getDefaultShippingInformation(
        order,
        order.transport === T_WAREHOUSE ? context.batch : batches
      );
      // add note of customer to additional information input, since it might contain necessary information on customers delivery times etc.
      if (!newShippingInformation.additionalInformation.includes(order.company.notes)) {
        newShippingInformation.additionalInformation = `${newShippingInformation.additionalInformation}${
          newShippingInformation.additionalInformation ? "\n\n" : ""
        } ${selectedAddress?.address ? selectedAddress.address.openingHours + "\n\n" : ""} ${order.company.notes}`;
      }
    } else {
      newShippingInformation = shippingInformation;
    }
    this.setState({
      show: true,
      shippingInformation: newShippingInformation,
    });
  };
  handleHide = () => this.setState({ show: false });

  handleDeliveryAddressSelect = (e: AddressSelectOption) =>
    this.setState({ selectedAddress: e, deliveryAddress: this.getDeliveryAddress(e.address) });
  handleDeliveryAddressChange = (deliveryAddress: EditorState) => this.setState({ deliveryAddress });

  handleAddDeliveryInformation = async () => {
    await this.props.onAddDeliveryInformation(this.state.shippingInformation, this.state.deliveryAddress);
    this.handleHide();
  };
  handleCreateDraft = () => {
    this.props.onCreateDeliveryNoteDraft(this.state.shippingInformation, this.state.deliveryAddress);
  };
  handleSaveDeliveryInformation = () => {
    this.props.onSaveDeliveryInformation(this.state.shippingInformation);
    this.handleHide();
  };

  handleChangeShippingInformation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    if (!this.state.shippingInformation) return;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    _.set(shippingInformation, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ shippingInformation });
  };

  handleChangeShippingInformationDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.shippingInformation) return;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    _.set(shippingInformation, e.target.name, val);
    this.setState({ shippingInformation });
  };

  handleChangeShippingGroup = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (!this.state.shippingInformation) return;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    const shippingGroup = shippingInformation.shippingGroups[index];
    _.set(shippingGroup, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ shippingInformation });
  };

  handleChangeShippingGroupDate = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (!this.state.shippingInformation) return;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    const shippingGroup = shippingInformation.shippingGroups[index];
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    _.set(shippingGroup, e.target.name, val);
    this.setState({ shippingInformation });
  };

  handleChangeShippingGroupBatch = (e: SelectOption, index: number) => {
    const { relevantBatches } = this.state;
    if (!this.state.shippingInformation) return;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    const shippingGroup = shippingInformation.shippingGroups[index];
    const selectedBatch = relevantBatches.find((rB) => rB.identifier === e.value);
    shippingGroup.lot = e.value;
    shippingGroup.supplierLot = selectedBatch?.lot || "-";
    shippingGroup.expiry = selectedBatch?.expiry || shippingGroup.expiry;
    this.setState({ shippingInformation });
  };

  handleAddShippingGroup = () => {
    if (!this.state.shippingInformation) return;
    const { order, batches } = this.props;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    shippingInformation.shippingGroups.push(getDefaultShippingGroup(order, batches));
    this.setState({ shippingInformation });
  };

  handleRemoveShippingGroup = (index: number) => {
    if (!this.state.shippingInformation) return;
    const shippingInformation = _.cloneDeep(this.state.shippingInformation);
    shippingInformation.shippingGroups.splice(index, 1);
    this.setState({ shippingInformation });
  };

  validateData = () => {
    const { shippingInformation } = this.state;
    const { shipVia, shippingGroups, estimatedDeliveryDate, unitLoad, unitLoadAmount } = shippingInformation;
    const errors = [];
    if (!shipVia.trim()) errors.push("Enter the way the packages are shipped");
    if (!estimatedDeliveryDate) errors.push("Enter a estimated delivery date");
    if (!unitLoad.trim()) errors.push("Enter a valid unit load");
    if (unitLoadAmount <= 0) errors.push("Enter a valid unit load amount");
    shippingGroups.forEach((sg, idx) => {
      if (!sg.lot) errors.push(`Select a batch for shipping group ${idx + 1}`);
      if (!sg.description) errors.push(`Add a description for shipping group ${idx + 1}`);
      if (!sg.expiry) errors.push(`Set an expiration date for shipping group ${idx + 1}`);
      if (!sg.packageType) errors.push(`Set the package type for shipping group ${idx + 1}`);
      if (sg.packageAmount <= 0) errors.push(`Set a valid package amount for shipping group ${idx + 1}`);
      if (sg.packageNetWeight <= 0) errors.push(`Add a valid package weight for shipping group ${idx + 1}`);
    });
    return errors;
  };

  render() {
    const { order, saving, disabled, exists, deliveryNote, isEUStock } = this.props;
    const { relevantBatches, show, shippingInformation, deliveryAddress, selectedAddress } = this.state;
    const errors = this.validateData();

    return (
      <>
        <span className="fw-bolder text-white">
          {deliveryNote ? "Delivery PDF: " : "Delivery Information: "}
          <Tooltip tooltipText={deliveryNote ? "Create a delivery note pdf" : "Add delivery information"}>
            <span
              className={
                ((saving || disabled) && "disabled") +
                (exists ? " text-success text-success-hover" : " text-danger text-danger-hover")
              }
              onClick={saving || disabled ? undefined : this.handleShow}
            >
              {exists ? "Done" : "Pending"}
            </span>
          </Tooltip>
        </span>
        <Modal contentClassName="bg-dark" show={show} size={"xl"} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {deliveryNote ? "Create Delivery PDF" : "Add Delivery Information"}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-5">
              <div className="col-md-12 mt-5">
                <label className="required fs-5 fw-bold mb-2">Shipping Groups</label>
                <div className="row my-4">
                  <div className="col-4">
                    <span className="text-white fw-bold mb-2">Description</span>
                  </div>
                  <div className="col-2">
                    <span className="text-white fw-bold mb-2">Batch-Number/LOT</span>
                  </div>
                  <div className="col-2">
                    <span className="text-white fw-bold mb-2">Expiration Date</span>
                  </div>
                  <div className="col">
                    <span className="text-white fw-bold mb-2">Packages And Weight (net)</span>
                  </div>
                  <div className="col-auto" />
                </div>
                {shippingInformation.shippingGroups.map((sg, idx) => (
                  <div className="row my-4" key={sg._id.toString()}>
                    <div className="col-4">
                      <Input
                        type="text"
                        placeholder={"description"}
                        className="form-control custom-form-control"
                        name="description"
                        value={sg.description}
                        onBlur={(e) => this.handleChangeShippingGroup(e, idx)}
                      />
                    </div>
                    <div className="col-2">
                      {isEUStock ? (
                        <input className="form-control custom-form-control" disabled={true} value="Ext. EU Stock" />
                      ) : (
                        <CustomSelect
                          options={relevantBatches.map((b) => {
                            return { label: `RB${b.identifier}`, value: b.identifier };
                          })}
                          placeholder={"Select Batch..."}
                          value={{ label: `${sg.lot.startsWith("RB") ? sg.lot : `RB${sg.lot}`}`, value: sg.lot }}
                          onChange={(e: SelectOption) => this.handleChangeShippingGroupBatch(e, idx)}
                          matchFormControl={true}
                        />
                      )}
                    </div>
                    <div className="col-2">
                      <DateInput
                        classes="form-control custom-form-control"
                        name={"expiry"}
                        value={sg.expiry}
                        onBlur={(e) => this.handleChangeShippingGroupDate(e, idx)}
                      />
                    </div>
                    <div className="col">
                      <div className="input-group">
                        <Input
                          type="number"
                          min={0}
                          className="form-control custom-form-control pt-0 pb-0"
                          integerOnly={true}
                          value={sg.packageAmount}
                          style={{ maxWidth: "60px" }}
                          name={"packageAmount"}
                          onBlur={(e) => this.handleChangeShippingGroup(e, idx)}
                        />
                        <div className="input-group-append bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            x
                          </div>
                        </div>
                        <div className="input-group-append bg-custom-light-gray">
                          <Input
                            type="number"
                            min={0}
                            className="form-control custom-form-control pt-0 pb-0"
                            style={{ maxWidth: "80px" }}
                            value={sg.packageNetWeight}
                            name={"packageNetWeight"}
                            onBlur={(e) => this.handleChangeShippingGroup(e, idx)}
                          />
                        </div>
                        <div className="input-group-append bg-custom-light-gray">
                          <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                            kg
                          </div>
                        </div>
                        <div className="input-group-append bg-custom-light-gray rounded-end select-wrapper flex-grow-1">
                          <select
                            className="form-control custom-form-control pt-0 pb-0"
                            value={sg.packageType}
                            name={"packageType"}
                            onChange={(e) => this.handleChangeShippingGroup(e, idx)}
                          >
                            {C_PACKAGE_OPTIONS.filter((o: SelectOption) => !o.value.toLowerCase().includes("open")).map(
                              (o: SelectOption) => (
                                <option key={o.value} value={o.value}>
                                  {o.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        className={
                          "btn btn-light btn-sm float-right " +
                          (shippingInformation.shippingGroups.length === 1 && "disabled")
                        }
                        disabled={shippingInformation.shippingGroups.length === 1}
                        onClick={
                          shippingInformation.shippingGroups.length === 1
                            ? undefined
                            : () => this.handleRemoveShippingGroup(idx)
                        }
                      >
                        <i className="fa fa-trash text-white p-0" />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col">
                    <button className="btn btn-light btn-sm float-right" onClick={this.handleAddShippingGroup}>
                      <i className="fa fa-plus text-white p-0" />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8" />
                  <div className="col-auto">
                    <span className="text-white fs-6 fw-bold mb-2">
                      Total Weight (net):{" "}
                      {shippingInformation.shippingGroups.reduce(
                        (a, b) => round(a + b.packageAmount * b.packageNetWeight, 2),
                        0
                      )}
                      kg
                    </span>
                  </div>
                </div>
              </div>
              <div className="separator mt-10 mb-5 border-bottom-dark-gray" />
              <div className="col-12">
                <div className="row mb-5 ">
                  <div className="col-md-4 mt-5">
                    <label className="required fs-5 fw-bold mb-2">Unit Load</label>
                    <div className="input-group">
                      <div className="input-group-prepend bg-custom-light-gray rounded-start">
                        {shippingInformation.unitLoad === "" ||
                        !DI_UNITLOAD.some((ul) => ul.value === shippingInformation.unitLoad) ? (
                          <Input
                            type="text"
                            className="form-control custom-form-control pt-0 pb-0"
                            value={shippingInformation.unitLoad}
                            name={"unitLoad"}
                            placeholder={"Unit Load, e.g. Pallet"}
                            onChange={this.handleChangeShippingInformation}
                          />
                        ) : (
                          <select
                            className="form-control custom-form-control pt-0 pb-0"
                            value={shippingInformation.unitLoad}
                            name={"unitLoad"}
                            onChange={this.handleChangeShippingInformation}
                          >
                            {DI_UNITLOAD.map((ul) => (
                              <option key={ul.value} value={ul.value}>
                                {ul.label}
                              </option>
                            ))}
                            <option value={""}>Other</option>
                          </select>
                        )}
                      </div>
                      <div className="input-group-append bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          :
                        </div>
                      </div>
                      <Input
                        type="number"
                        min={0}
                        integerOnly={true}
                        className="form-control custom-form-control pt-0 pb-0"
                        value={shippingInformation.unitLoadAmount}
                        placeholder={`${shippingInformation.unitLoad} amount`}
                        name={"unitLoadAmount"}
                        onBlur={this.handleChangeShippingInformation}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mt-5">
                    <label className="required fs-5 fw-bold mb-2">Est. Delivery Date</label>
                    <DateInput
                      classes="form-control custom-form-control"
                      name={"estimatedDeliveryDate"}
                      value={shippingInformation.estimatedDeliveryDate}
                      onBlur={this.handleChangeShippingInformationDate}
                    />
                  </div>
                  <div className="col-md-4 mt-5">
                    <label className="required fs-5 fw-bold mb-2">Ship Via</label>
                    <Input
                      type="text"
                      className="form-control custom-form-control pt-0 pb-0"
                      placeholder={"e.g. by Truck"}
                      value={shippingInformation.shipVia}
                      name={"shipVia"}
                      onChange={this.handleChangeShippingInformation}
                    />
                  </div>
                  <div className="col-md-4 mt-5">
                    <label className="fs-5 fw-bold mb-2">Gross Weight</label>
                    <div className="input-group">
                      <Input
                        type="number"
                        className="form-control custom-form-control pt-0 pb-0"
                        value={shippingInformation.grossWeight}
                        name={"grossWeight"}
                        min={0}
                        onChange={this.handleChangeShippingInformation}
                      />
                      <div className="input-group-append rounded-end bg-custom-light-gray">
                        <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                          kg
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mt-5">
                    <label className="fs-5 fw-bold mb-2">Additional Information</label>
                    <textarea
                      className="form-control custom-form-control"
                      rows={4}
                      name={"additionalInformation"}
                      value={shippingInformation.additionalInformation}
                      onChange={this.handleChangeShippingInformation}
                    />
                  </div>
                </div>
              </div>
              {deliveryNote && (
                <>
                  <div className="separator mt-10 mb-5 border-bottom-dark-gray" />
                  <span className="row mt-5">
                    <div className="col-auto my-auto">
                      <label className="fs-5 fw-bold my-auto">Delivery Address</label>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4" style={{ zIndex: 5 }}>
                      <CustomSelect
                        options={order.company.address.map((addr) => {
                          return {
                            label: formatAddress(addr),
                            value: formatAddress(addr),
                            address: addr,
                          };
                        })}
                        placeholder={"Select Customer Address..."}
                        matchFormControl={true}
                        value={selectedAddress}
                        onChange={this.handleDeliveryAddressSelect}
                      />
                    </div>
                  </span>
                  <div className="col-12 mt-5">
                    <WysiwygEditor content={deliveryAddress} onChange={this.handleDeliveryAddressChange} />
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-text-light" onClick={this.handleHide}>
              Close
            </button>
            <Tooltip tooltipText="Save shipping groups and load information without generating a delivery note. Delivery address is not saved.">
              <div>
                <ErrorOverlayButton
                  errors={[]}
                  className={"btn btn-sm btn-outline btn-outline-light "}
                  saving={saving}
                  buttonText={saving ? "Saving" : "Save"}
                  onClick={this.handleSaveDeliveryInformation}
                />
              </div>
            </Tooltip>
            {deliveryNote && (
              <>
                <ErrorOverlayButton
                  errors={errors}
                  className={"btn btn-sm btn-outline btn-outline-light "}
                  saving={saving}
                  buttonText={saving ? "Generating" : "Preview"}
                  onClick={this.handleCreateDraft}
                />
                <ErrorOverlayButton
                  errors={errors}
                  className={"btn btn-sm btn-outline btn-outline-light "}
                  saving={saving}
                  buttonText={"Create Delivery Note"}
                  onClick={this.handleAddDeliveryInformation}
                />
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default WorkflowDeliveryInformationModal;
