import React, { useContext, useEffect, useState } from "react";
import userService from "../../services/userService";
import Tooltip from "../common/Tooltip";
import { DataContextInternal } from "../../context/dataContext";
import { UserData } from "../../model/userData.types";
import { CUSTOMER } from "../../utils/userUtils";

const getCustomerMails = (userData: Array<UserData>) => {
  return userData
    .reduce((res, user) => {
      if (user.type === CUSTOMER && user.userId && user.onboardingDone) {
        let mail;
        if (user.notifications.email?.trim()) mail = user.notifications.email?.trim().toLowerCase();
        else if (user.emails.length > 0) mail = user.emails[0].value.trim().toLowerCase();
        // Avoid duplicate and tester accounts
        if (
          mail &&
          !(
            mail.includes("rawbids") ||
            mail.includes("commodity-trading-systems") ||
            mail.includes("novacode") ||
            mail.includes("example")
          ) &&
          !res.includes(mail)
        )
          return `${res}${mail},`;
      }
      return res;
    }, "")
    .slice(0, -1); // remove , at the end
};

const CustomerMailsTool = () => {
  const context = useContext(DataContextInternal);
  const { userData } = context;

  const [customerMails, setCustomerMails] = useState<string>("");
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setCustomerMails(getCustomerMails(userData));
    setCopied(false);
  }, []);

  const handleResetData = () => {
    setCustomerMails(getCustomerMails(userData));
    setCopied(false);
  };

  const handleCopyToClipboard = async () => {
    if ("clipboard" in navigator) await navigator.clipboard.writeText(customerMails);
    else document.execCommand("copy", true, customerMails);
    setCopied(true);
  };

  if (!userService.isAdmin())
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title ">
                  <span className="card-label fw-bolder fs-3rem">Customer Mail Addresses</span>
                </h3>
                <h5 className="mt-20 text-center">
                  <span className="text-muted">You do not have access to this section</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl responsive-aside-container">
          <div className="card bg-white">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Customer Mail Addresses</span>
              </h3>
              <div className="text-white ">
                The text below contains all emails of active customers separated by comma.
              </div>
              <div className="text-white ">
                Note: These are not necessarily the account/login emails but the emails they or we provided via their
                user data. If a notification email is set, this will be preferred, otherwise the first mail from them
                will be used. All customers who have a linked user account and completed onboarding and are therefore
                theoretically active in the system are taken into account.
              </div>
              <br />
              <div className="text-white ">
                Copy the text, e.g., with the button below and paste it in your recipient field in, e.g., Outlook. It
                should work with the new Outlook desktop client and the Outlook web client.
              </div>
              <br />
              <br />
              <textarea rows={10} className="form-control custom-form-control" value={customerMails} />
              <div className="mt-5 text-right">
                <Tooltip tooltipText={copied ? "Copied!" : "Copy to clipboard!"}>
                  <button className="btn btn-sm btn-light" onClick={handleCopyToClipboard}>
                    <i className="flaticon2-copy pr-0 text-white" />
                  </button>
                </Tooltip>
                <button className="btn btn-sm btn-light ml-2" onClick={handleResetData}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerMailsTool;
