import _ from "lodash";
import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import DateInput from "../../../../common/DateInput";
import { Input } from "../../../../common/Input";
import Search from "../../../../common/Search";
import { Commodity, Price, SupplierPrices } from "../../../../../model/commodity.types";
import { callFunction, COMMODITY, FINISHEDPRODUCT } from "../../../../../services/dbService";
import userService from "../../../../../services/userService";
import {
  C_UNITS,
  D_MASTERSPECIFICATION,
  getCommoditySupplierDiff,
  getCommodityTimelineEntry,
  getDefaultPrice,
  T_SUPPLIERPRICEUPDATED,
  transformSuppliersForDiff,
  UPDATECOMMODITYSUPPLIERPRICES,
} from "../../../../../utils/commodityUtils";
import { doFuseSearch, formatCurrency, pluralize, toAbsoluteUrl } from "../../../../../utils/baseUtils";
import { getDaysBetween, getDaysUntil } from "../../../../../utils/dateUtils";
import { BASE_CURRENCY, convertCurrency, SUPPORTED_CURRENCIES } from "../../../../../utils/currencyUtils";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { PriceAdjustmentModal } from "../modals/PriceAdjustmentModal";
import PriceGraduationModal from "../modals/PriceGraduationModal";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { ArticlePriceRenewAllModal, SetLeadTimeModal } from "../../../../common/ArticlePriceTable";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../../services/slackService";
import { DataContextSupplier, DataContextSupplierType } from "../../../../../context/dataContext";
import Tooltip from "../../../../common/Tooltip";
import HoverPopover from "../../../../common/HoverPopover";
import { getSupplierTimelineEntry, updateSupplierTimeline } from "../../../../../utils/supplierUtils";
import { T_S_UPDATECOMMODITYPRICE } from "../../../../../model/supplier.types";
import { SupplierSupplier } from "../../../../../model/supplier/supplierSupplier.types";
import { FinishedProduct, PriceFinishedProduct } from "../../../../../model/finishedProduct.types";
import {
  formatArticleUnit,
  isAnyFinishedProduct,
  SupplierArticleExtended,
} from "../../../../../utils/productArticleUtils";
import { FP_UNITS, isFinishedProduct } from "../../../../../utils/finishedProductUtils";
import { SupplierSupplierPricesExtended } from "../../../../../model/supplier/supplierCommodity.types";
import { extendSupplierCommodity, extendSupplierFinishedProduct } from "../../../../../utils/dataTransformationUtils";

interface OverviewOffersProps {
  type: "all" | "active" | "expiring" | "inactive" | "noPrices" | "disabled";
  articles: Array<Commodity | FinishedProduct>;
  context: DataContextSupplierType;
  onSelectArticle?: (selectedArticle: SupplierArticleExtended) => void;
  single?: boolean;
}

interface OverviewOffersState {
  search: string;
}

class OverviewOffers extends PureComponent<OverviewOffersProps, OverviewOffersState> {
  constructor(props: OverviewOffersProps) {
    super(props);
    this.state = { search: "" };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  filterCommodities = () => {
    const { articles } = this.props;
    const { search } = this.state;
    if (search.trim()) return doFuseSearch(articles, search, ["title.en", "subtitle.en"]);
    return _.orderBy(articles, "title.en");
  };

  render() {
    const { onSelectArticle, single, context } = this.props;
    const { search } = this.state;
    const articles = this.filterCommodities();
    const amountPrices = articles.reduce((sum, c) => {
      const suppliers: Array<SupplierPrices> = c.suppliers;
      return sum + suppliers.reduce((pSum, p) => pSum + p.prices.length, 0);
    }, 0);

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Article Prices</span>
            <span className="text-muted fw-bold fs-7">
              {amountPrices} Prices for {articles.length} Ingredients
            </span>
          </h3>
          <div className="card-toolbar">
            {!single && <Search onSearch={this.handleSearch} value={search} placeholder="Search for ingredients..." />}
          </div>
        </div>
        <div className="card-body p-4 pt-4">
          <Accordion alwaysOpen={true}>
            {articles.map((c) => {
              let article: SupplierArticleExtended;
              if (isAnyFinishedProduct(c)) article = extendSupplierFinishedProduct(c, context);
              else article = extendSupplierCommodity(c, context);
              return (
                <div key={c._id.toString()} onClick={() => (onSelectArticle ? onSelectArticle(article) : undefined)}>
                  <OverviewCommodity article={article} eventKey={c._id.toString()} single={single} context={context} />
                </div>
              );
            })}
          </Accordion>
          <div className="pt-2">
            <Link className="btn w-100 fw-bolder btn-light" to="/articles">
              Offer New Commodity
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewOffers;

interface OverviewCommodityProps {
  article: SupplierArticleExtended;
  eventKey: string;
  context: DataContextSupplierType;
  single?: boolean;
}

interface OverviewCommodityState {
  article: SupplierArticleExtended;
  showPriceTable: boolean;
  saving: boolean;
}

class OverviewCommodity extends PureComponent<OverviewCommodityProps, OverviewCommodityState> {
  constructor(props: OverviewCommodityProps) {
    super(props);
    this.state = { article: _.cloneDeep(props.article), showPriceTable: false, saving: false };
  }

  componentDidMount() {
    this.props.single && this.handleTogglePriceTable(true);
  }

  handleTogglePriceTable = (value: boolean) => {
    const commodity = _.cloneDeep(this.props.article);
    const supplierPrice = commodity.suppliers[0];
    const prices = supplierPrice ? supplierPrice.prices : [];
    if (!prices.length) {
      prices.push(
        getDefaultPrice(undefined, supplierPrice.leadTime || supplierPrice.supplier.transport.preparationTime)
      );
    }
    this.setState({ showPriceTable: value, article: commodity });
  };

  handleNewPrice = () => {
    const commodity = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (entry) {
      entry.prices.push(
        getDefaultPrice(
          entry.prices[entry.prices.length - 1],
          entry.leadTime || entry.supplier.transport.preparationTime
        )
      );
      this.setState({ article: commodity });
    }
  };

  handleRemovePrice = (priceId: BSON.ObjectId | string) => {
    const commodity = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (entry) entry.prices = entry.prices.filter((p) => p._id.toString() !== priceId.toString());
    this.setState({ article: commodity });
  };

  handleUpdatePrice = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, priceId: BSON.ObjectId | string) => {
    const article = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = article.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!entry) return;
    const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
    if (price) _.set(price, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ article });
  };

  handleRenewPrices = (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => {
    const article = _.cloneDeep(this.state.article);
    // Sanity check that we want to update the correct commodity
    if (entryId.toString() !== article._id.toString()) return;
    const sup = userService.getCompany();
    const entry = article.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!entry) return;
    if (priceId) {
      const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
      if (price) {
        price.validUntil = date;
      }
    } else {
      for (let i = 0; i < entry.prices.length; i++) {
        entry.prices[i].validUntil = date;
      }
    }
    this.setState({ article });
  };

  handleDisable = (disable: boolean) => {
    const commodity = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!entry) return;
    entry.disabled = disable;
    this.setState(
      {
        article: commodity,
      },
      async () => {
        await this.handleSaveArticle(this.props.eventKey);
      }
    );
  };

  handleAdjust = (value: string) => {
    const commodity = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!entry) return;
    for (let i = 0; i < entry.prices.length; i++) {
      const val = entry.prices[i].price;
      entry.prices[i].price = Math.round(val * (1 + parseFloat(value) / 100) * 100) / 100;
    }
    this.setState({ article: commodity });
  };

  handleSetLeadTime = (entryId: BSON.ObjectId | string, value: string) => {
    const commodity = _.cloneDeep(this.state.article);
    // Sanity check that we want to update the correct commodity
    if (entryId.toString() !== commodity._id.toString()) return;
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!entry) return;
    const val = parseFloat(value);
    entry.leadTime = val; // Set lead time globally for offered commodity
    entry.prices = entry.prices.map((price) => ({ ...price, leadTime: val })); // Overwrite individual lead times if global lead time is set
    this.setState({ article: commodity });
  };

  handleUpdatePriceDate = (e: React.ChangeEvent<HTMLInputElement>, priceId: BSON.ObjectId | string) => {
    const commodity = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!entry) return;
    const price = entry.prices.find((p) => p._id.toString() === priceId.toString());
    if (price) {
      const val = new Date(e.target.value);
      if (isNaN(val.getTime())) return;
      _.set(price, e.target.name, val);
    }
    this.setState({ article: commodity });
  };

  handleCreateGraduation = (results: Array<{ amount: number; price: number }>) => {
    const commodity = _.cloneDeep(this.state.article);
    const sup = userService.getCompany();
    const entry = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    const validUntil = new Date(new Date().setDate(new Date().getDate() + 30));
    if (!entry) return;
    entry.prices = [];
    for (let i = 0; i < results.length; i++) {
      entry.prices.push({
        _id: new BSON.ObjectId(),
        currency: BASE_CURRENCY,
        date: new Date(),
        externalArticleNo: "",
        minOQ: results[i].amount,
        price: results[i].price,
        validUntil: validUntil,
        leadTime: entry.leadTime || entry.supplier.transport.preparationTime || 5,
      });
    }
    this.setState({ article: commodity });
  };

  handleSaveArticle = async (entryId: BSON.ObjectId | string) => {
    const { article: originArticle, context } = this.props;
    const commodity = _.cloneDeep(this.state.article);
    const { requestDocumentUpdate } = context;

    this.setState({ saving: true });
    const sup = userService.getCompany();
    const supplierUpdate = commodity.suppliers.find((s) => s.supplier._id.toString() === sup);
    const oldSupplier = originArticle.suppliers.find((s) => s.supplier._id.toString() === sup);
    if (!supplierUpdate || !oldSupplier) return;
    supplierUpdate.prices = supplierUpdate.prices.sort((p1, p2) => p1.minOQ - p2.minOQ);

    const commodityTimelineEntry = getCommodityTimelineEntry(T_SUPPLIERPRICEUPDATED, undefined, sup.toString());
    const supplierTimelineEntry = getSupplierTimelineEntry(T_S_UPDATECOMMODITYPRICE, {
      reference: commodity._id.toString(),
    });
    const [differentSuppliersPre, differentSuppliersPost] = getCommoditySupplierDiff(
      transformSuppliersForDiff([oldSupplier]),
      transformSuppliersForDiff([supplierUpdate])
    );
    if (differentSuppliersPre.length > 0) {
      commodityTimelineEntry.pre = { suppliers: differentSuppliersPre };
      supplierTimelineEntry.pre = differentSuppliersPre;
    }
    if (differentSuppliersPost.length > 0) {
      commodityTimelineEntry.post = { suppliers: differentSuppliersPost };
      supplierTimelineEntry.post = differentSuppliersPost;
    }
    try {
      const isFP = isAnyFinishedProduct(originArticle);
      const resCommodity: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false = await callFunction(
        UPDATECOMMODITYSUPPLIERPRICES,
        [
          entryId.toString(),
          { id: oldSupplier._id.toString(), supplier: supplierUpdate },
          false,
          commodityTimelineEntry,
          isFP,
        ]
      );
      const resSupplier = await updateSupplierTimeline(supplierUpdate.supplier._id, supplierTimelineEntry);
      if (resCommodity && resCommodity.modifiedCount > 0 && resSupplier) {
        const updateMessage = this.createPriceUpdateMessage(commodity, oldSupplier, supplierUpdate);
        if (updateMessage) sendMessage(getDefaultSlackChannel(false, NotificationType.PRICE), updateMessage); // fire and forget
        await requestDocumentUpdate(isFP ? FINISHEDPRODUCT : COMMODITY, commodity._id); // Ensure that article is properly updated
        toast.success("Commodity prices updated successfully");
        this.setState({ showPriceTable: false });
      } else {
        toast.error("Error updating commodity prices");
      }
    } catch (e) {
      console.log("ERROR:", e);
      toast.error("Error updating commodity prices");
    } finally {
      this.setState({ saving: false });
    }
  };

  getRemainingTime = () => {
    const { article: comProps } = this.props;
    const supPriceProps = comProps.suppliers[0];
    const prices: Array<Price | PriceFinishedProduct> = supPriceProps.prices;
    const min = prices.length
      ? prices.reduce((prev, cur) => (cur.validUntil < prev.validUntil ? cur : prev)).validUntil
      : new Date();
    return getDaysBetween(min, new Date(), true);
  };

  /**
   * Function to create update messages for price changes.
   * @param article The actual commodity.
   * @param oldPrices old prices.
   * @param newPrices new prices.
   * @returns {string} update message.
   */
  createPriceUpdateMessage = (
    article: SupplierArticleExtended,
    oldPrices: SupplierSupplierPricesExtended,
    newPrices: SupplierSupplierPricesExtended
  ): string | undefined => {
    // filtering diffs between price changes
    const diffOld: Array<Price> = this.findDiffBetweenPrices(oldPrices, newPrices);
    const diffNew: Array<Price> = this.findDiffBetweenPrices(newPrices, oldPrices);

    const removedPrices: Array<{ oldValue: Price | undefined; newValue: Price | undefined }> = [];
    const addedPrices: Array<{ oldValue: Price | undefined; newValue: Price | undefined }> = [];
    const changedPrices: Array<{ oldValue: Price | undefined; newValue: Price | undefined }> = [];

    for (let i = 0; i < diffOld.length; i++) {
      const pOld = diffOld[i];
      const pNew = diffNew.find((p) => p._id.toString() === pOld._id.toString());
      if (pNew) changedPrices.push({ oldValue: pOld, newValue: pNew });
      else removedPrices.push({ oldValue: pOld, newValue: undefined });
    }
    for (let i = 0; i < diffNew.length; i++) {
      const pNew = diffNew[i];
      if (!diffOld.some((p) => p._id.toString() === pNew._id.toString()))
        addedPrices.push({ oldValue: undefined, newValue: pNew });
    }

    // receive valid prices
    const [validPrices, relevantValidityValue] = this.getPriceValidity(newPrices.prices);

    const isFP = isAnyFinishedProduct(article);

    let message = `*PRICE CHANGE*
        ${newPrices.supplier.name} changed prices of <https://${process.env.REACT_APP_BASE_URL || ""}/${
      isFP ? "finishedProduct" : "commodity"
    }/${article._id.toString()}|*${article.title.en}*> \r\n`;
    if (removedPrices.length > 0) {
      message += "Removed prices: \r\n";
      removedPrices.forEach((p) => {
        if (p.oldValue)
          message += `${p.oldValue.minOQ}${formatArticleUnit(article.unit, article)} - ${formatCurrency(
            p.oldValue.price,
            p.oldValue.currency || BASE_CURRENCY
          )} \r\n`;
      });
    }
    if (changedPrices.length > 0) {
      message += "Changed prices: \r\n";
      changedPrices.forEach((p) => {
        if (p.oldValue && p.newValue) {
          // Check if price/moq changed or only validity
          if (p.oldValue.price === p.newValue.price && p.oldValue.minOQ === p.newValue.minOQ) {
            message += `${p.oldValue.minOQ}${article.unit} - ${formatCurrency(
              p.oldValue.price,
              p.oldValue.currency || BASE_CURRENCY
            )} was renewed until ${p.newValue.validUntil.toISOString().split("T")[0]}. \r\n`;
          } else {
            message += `from ${p.oldValue.minOQ}${formatArticleUnit(article.unit, article)} - ${formatCurrency(
              p.oldValue.price,
              p.oldValue.currency || BASE_CURRENCY
            )} to ${p.newValue.minOQ}${formatArticleUnit(article.unit, article)} - ${formatCurrency(
              p.newValue.price,
              p.newValue.currency || BASE_CURRENCY
            )} \r\n`;
          }
        }
      });
    }
    if (addedPrices.length > 0) {
      message += "New prices: \r\n";
      addedPrices.forEach((p) => {
        if (p.newValue)
          message += `${p.newValue.minOQ}${formatArticleUnit(article.unit, article)} - ${formatCurrency(
            p.newValue.price,
            p.newValue.currency || BASE_CURRENCY
          )} \r\n`;
      });
    }
    if (validPrices.length > 0)
      message += `${validPrices.length} of ${pluralize(
        newPrices.prices.length,
        `price`
      )} valid for at least ${pluralize(relevantValidityValue, "day")} \r\n`;
    else message += "No valid prices. \r\n";

    return message.replace(/  +/g, "");
  };

  /**
   * Function to find the difference between to SupplierPrices.
   * @param priceOne Price one to compare.
   * @param priceTwo Price tow to compare.
   * @returns {Array<Price>} Difference between price one and price two.
   */
  findDiffBetweenPrices = (
    priceOne: SupplierSupplierPricesExtended,
    priceTwo: SupplierSupplierPricesExtended
  ): Array<Price> => {
    const diff: Array<Price> = [];
    priceOne.prices.map((price) => {
      const comparePriceObject = priceTwo.prices.find(
        (referencePrice) => referencePrice._id.toString() === price._id.toString()
      );

      // Checking if price one differs from another
      if ((comparePriceObject && !this.comparePriceObjects(price, comparePriceObject)) || !comparePriceObject) {
        diff.push(price);
      }
    });
    return diff;
  };

  /**
   * Function to compare the equality of two given price object (to avoid compare with JSON.stringify).
   * Attention: Compares only values relevant for price changes.
   * @param priceOne Price one to compare.
   * @param priceTwo Price two to compare.
   * @returns {boolean} Shows if Price one is equals price two.
   */
  comparePriceObjects = (priceOne: Price, priceTwo: Price): boolean => {
    return (
      priceOne._id.toString() === priceTwo._id.toString() &&
      priceOne.minOQ === priceTwo.minOQ &&
      priceOne.price === priceTwo.price &&
      priceOne.validUntil.toString() === priceTwo.validUntil.toString()
    );
  };

  /**
   * Function to filter the relevant reference price. (First that will expire).
   * @param prices Prices to be checked.
   * @returns {[Array<Price>, number]} tuple with valid prices and minimum validity
   */
  getPriceValidity = (prices: Array<Price>): [Array<Price>, number] => {
    let relevantValidityValue = Infinity;
    const validPrices: Array<Price> = [];
    for (let i = 0; i < prices.length; i++) {
      const p = prices[i];
      const daysBetween = Math.ceil((p.validUntil.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
      if (daysBetween >= 0) {
        validPrices.push(p);
        if (daysBetween < relevantValidityValue) relevantValidityValue = daysBetween;
      }
    }

    return [validPrices, relevantValidityValue === Infinity ? 0 : relevantValidityValue] as [
      validPrices: Array<Price>,
      minimumValidity: number
    ];
  };

  render() {
    const { article: articleProps, eventKey, context } = this.props;
    const { article, showPriceTable, saving } = this.state;
    const supPriceProps = articleProps.suppliers[0];
    const supplierPrice = article.suppliers[0];
    const pricesProps = supPriceProps ? supPriceProps.prices : [];
    const prices = supplierPrice ? supplierPrice.prices : [];
    const invalidPrices = pricesProps.filter((p) => p.validUntil < new Date() || p.price <= 0).length;
    const remainingTime = this.getRemainingTime();
    const masterSpec = article.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    const emptyPrices = !prices.length || prices.some((price) => price.price === 0);
    const supplier = context.supplier.find((s) => s._id.toString() === userService.getCompany());
    const packagingDimensionAssigned = supplier?.packagingDimensions?.some(
      (pd) => pd.commodities === "all" || pd.commodities?.includes(articleProps._id.toString())
    );
    const isFP = isAnyFinishedProduct(articleProps);

    return (
      <div className="bg-light2 rounded p-5 mb-7">
        <div className="d-flex align-items-center ">
          <div className="flex-grow-1 me-2">
            <Link
              to={`/${isFP ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
              onClick={(e) => e.stopPropagation()}
              className={"text-white fs-5 custom-link " + (supplierPrice.disabled ? "opacity-50" : "")}
            >
              {article.title.en}
            </Link>
            {supplier && (
              <>
                <HoverPopover
                  popoverStyle={{ border: "none" }}
                  content={
                    <div className="px-4 py-3" style={{ background: "#3f3f3f" }}>
                      <div className="text-white">
                        {!packagingDimensionAssigned ? "No palette assigned" : "Adjust packaging information"}
                      </div>
                      <div className="text-center m-2">
                        <Link
                          className="btn btn-sm btn-outline btn-outline-light px-3 py-2"
                          to={"/packagingDimensions"}
                        >
                          {!packagingDimensionAssigned ? "Assign Palette" : "Adjust Packaging"}
                        </Link>
                      </div>
                    </div>
                  }
                >
                  {!packagingDimensionAssigned ? (
                    <i className="fas fa-exclamation-triangle text-warning ml-1" />
                  ) : (
                    <i className="fas fa-truck text-muted ml-1" />
                  )}
                </HoverPopover>
              </>
            )}
            <br />
            <span className={"text-muted " + (supplierPrice.disabled ? "opacity-50" : "")}>{article.subtitle.en}</span>
            <div>
              {supplierPrice.disabled ? (
                ""
              ) : pricesProps.length > 0 && invalidPrices === 0 ? (
                <small className="text-success">All prices active</small>
              ) : pricesProps.length > 0 && pricesProps.length > invalidPrices ? (
                <small className="text-warning">Price update required</small>
              ) : pricesProps.length > 0 ? (
                <small className="text-danger">Prices inactive</small>
              ) : (
                ""
              )}
            </div>
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            {supplierPrice.disabled ? (
              <>
                <span
                  className="text-warning fw-bolder cursor-pointer"
                  onClick={() => this.handleTogglePriceTable(true)}
                >
                  <i className="fa fa-exclamation-circle text-warning mr-2" />
                  DISABLED
                </span>
              </>
            ) : pricesProps.length === 0 ? (
              <>
                <span
                  className="text-danger fw-bolder cursor-pointer"
                  onClick={() => this.handleTogglePriceTable(true)}
                >
                  <i className="fa fa-exclamation-circle text-danger mr-2" />
                  NO PRICES
                </span>
              </>
            ) : (
              <>
                <span className="mb-1 fs-6 fw-bold text-nowrap">
                  <i className="fa fa-warning" />
                  {pluralize(pricesProps.length, "Price")}
                </span>
                <div className="text-muted text-nowrap">
                  {remainingTime > 0
                    ? remainingTime + " days left"
                    : remainingTime === 0
                    ? "prices expiring today"
                    : "some prices expired"}
                </div>
              </>
            )}
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            {masterSpec ? (
              <a href={resolveFilePath(masterSpec.path)} target="_blank" rel="noopener noreferrer">
                <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
              </a>
            ) : (
              <img
                alt="pdf"
                className="w-30px mr-2 opacity-25"
                style={{ cursor: "not-allowed" }}
                src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
              />
            )}
          </div>
          <div style={{ textAlign: "right", marginRight: 10, width: 80 }}>
            {supplierPrice.disabled ? (
              <button className="btn btn-warning btn-sm w-100 " onClick={() => this.handleDisable(false)}>
                Enable
              </button>
            ) : showPriceTable ? (
              <ErrorOverlayButton
                errors={emptyPrices ? ["All prices need to be over 0$ and at least one price has to be given"] : []}
                className="btn btn-success btn-sm w-100"
                buttonText="Save"
                saving={saving}
                onClick={() => this.handleSaveArticle(eventKey)}
              />
            ) : (
              <button
                className="btn btn-outline btn-outline-light btn-sm w-100"
                onClick={() => this.handleTogglePriceTable(true)}
              >
                Update
              </button>
            )}
          </div>
        </div>
        {showPriceTable && (
          <CommodityPriceTable
            article={article}
            supplier={supplierPrice.supplier}
            prices={prices}
            disabled={supplierPrice.disabled || saving}
            onNewPrice={this.handleNewPrice}
            onRemovePrice={this.handleRemovePrice}
            onUpdatePrice={this.handleUpdatePrice}
            onUpdatePriceDate={this.handleUpdatePriceDate}
            onTogglePrices={() => this.handleTogglePriceTable(false)}
            onRenewPrices={this.handleRenewPrices}
            onDisable={this.handleDisable}
            onAdjust={this.handleAdjust}
            onSetLeadTime={this.handleSetLeadTime}
            onCreateGraduation={this.handleCreateGraduation}
          />
        )}
      </div>
    );
  }
}

interface CommodityPriceTableProps {
  article: SupplierArticleExtended;
  supplier: SupplierSupplier;
  prices: Array<Price | PriceFinishedProduct>;
  disabled: boolean | undefined;
  onNewPrice: () => void;
  onRemovePrice: (priceId: BSON.ObjectId | string) => void;
  onUpdatePrice: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, priceId: BSON.ObjectId | string) => void;
  onUpdatePriceDate: (e: React.ChangeEvent<HTMLInputElement>, priceId: BSON.ObjectId | string) => void;
  onTogglePrices: () => void;
  onRenewPrices: (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => void;
  onCreateGraduation: (results: Array<{ amount: number; price: number }>) => void;
  onDisable: (disable: boolean) => void;
  onAdjust: (value: string) => void;
  onSetLeadTime: (entryId: BSON.ObjectId | string, value: string) => void;
}

interface CommodityPriceTableState {
  priceToRenew: Price | PriceFinishedProduct | undefined;
  renewAll: boolean;
  adjustAll: boolean;
  setLeadTime: boolean;
  showGraduationTool: boolean;
}

class CommodityPriceTable extends PureComponent<CommodityPriceTableProps, CommodityPriceTableState> {
  static contextType = DataContextSupplier;
  context!: DataContextSupplierType;

  constructor(props: CommodityPriceTableProps) {
    super(props);
    this.state = {
      priceToRenew: undefined,
      renewAll: false,
      setLeadTime: false,
      adjustAll: false,
      showGraduationTool: false,
    };
  }

  setPriceToRenew = (priceToRenew: Price | undefined) => this.setState({ priceToRenew });
  handlePriceToRenewClose = () => this.setState({ priceToRenew: undefined, renewAll: false });
  handleBulkAdjustmentClose = () => this.setState({ adjustAll: false });
  handleSetLeadTimeClose = () => this.setState({ setLeadTime: false });
  handleGraduationToolModalClose = () => this.setState({ showGraduationTool: false });

  getExpiryColor = (price: Price) => {
    const expires = getDaysUntil(price.validUntil, true);
    if (price.price === 0) {
      return "bg-secondary";
    } else if (expires > 7) {
      return "bg-success";
    } else if (expires > 0) {
      return "bg-warning";
    } else {
      return "bg-danger";
    }
  };

  getSalesForecast = () => {
    const { article, prices } = this.props;
    const avgPrice =
      prices.reduce(
        (sum, ap) => convertCurrency(ap.price, ap.currency, BASE_CURRENCY, this.context.currencies) + sum,
        0
      ) / prices.length;
    // Articles used in this component got filtered suppliers listing - thus we need to get the article from context
    const articleContext = isFinishedProduct(article)
      ? this.context.finishedProduct.find((fp) => fp._id.toString() === article._id.toString())
      : this.context.commodity.find((c) => c._id.toString() === article._id.toString());
    let avgPricesAll = 0;
    let amountPrices = 0;
    const now = new Date();
    if (articleContext) {
      for (let i = 0; i < articleContext.suppliers.length; i++) {
        const sup = articleContext.suppliers[i];
        if (sup?.supplier === userService.getCompany()) continue;
        avgPricesAll += sup.prices.reduce((sum, p) => {
          if (p.validUntil < now) return sum;
          amountPrices++;
          return convertCurrency(p.price, p.currency, BASE_CURRENCY, this.context.currencies);
        }, 0);
      }
    }
    avgPricesAll = amountPrices ? avgPricesAll / amountPrices : avgPrice * 2; // If there is no competitor volume should be high
    if (avgPrice <= avgPricesAll * 0.9) return <span className="text-success font-weight-bold">High Volume</span>;
    else if (avgPrice <= avgPricesAll) return <span className="text-warning font-weight-bold">Medium Volume</span>;
    else return <span className="text-danger font-weight-bold">Low Volume</span>;
  };

  render() {
    const {
      article,
      supplier,
      prices,
      disabled,
      onUpdatePrice,
      onNewPrice,
      onRemovePrice,
      onTogglePrices,
      onRenewPrices,
      onCreateGraduation,
      onDisable,
      onAdjust,
      onSetLeadTime,
    } = this.props;
    const { priceToRenew, renewAll, adjustAll, setLeadTime, showGraduationTool } = this.state;
    const expIn = (p: Price) => getDaysUntil(p.validUntil, true);

    return (
      <>
        <div className={"table-responsive mt-5 " + (disabled ? "disabled opacity-10" : "")}>
          <table className="table align-middle gs-0 gy-1 ">
            <thead>
              <tr className="fw-bolder text-muted">
                <th className="border-bottom-0"></th>
                <th className="border-bottom-0">MOQ</th>
                <th className="border-bottom-0">Price</th>
                <th className="border-bottom-0">
                  Preparation Time
                  <Tooltip
                    tooltipText={
                      <span className="text-white">
                        Preparation time can be set individually for different quantities or for the whole commodity by
                        using 'Set Preparation Time'.
                      </span>
                    }
                  >
                    <i className="fa fa-question-circle text-muted opacity-25 ml-2" />
                  </Tooltip>
                </th>
                <th className="border-bottom-0">Exp. in</th>
                <th className="border-bottom-0">Expiration</th>
                <th className="border-bottom-0 text-center"></th>
              </tr>
            </thead>
            <tbody>
              {prices.map((price) => (
                <tr key={price._id.toString()}>
                  <td className="align-middle">
                    <span className={"align-middle dot " + this.getExpiryColor(price)}>&nbsp;</span>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={price.minOQ}
                        className="form-control custom-form-control pt-0 pb-0 bg-dark"
                        name="minOQ"
                        onBlur={(e) => onUpdatePrice(e, price._id)}
                      />
                      <div className="input-group-append rounded-end">
                        <select
                          className="form-control custom-form-control bg-dark pt-0 pb-0 disabled"
                          value={article.unit}
                          name="unit"
                          disabled={true}
                        >
                          {isAnyFinishedProduct(article)
                            ? FP_UNITS.map((u) => (
                                <option key={u} value={u}>
                                  {u}
                                </option>
                              ))
                            : C_UNITS.map((u) => (
                                <option key={u} value={u}>
                                  {u}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <div className="input-group-prepend rounded-end">
                        <span className="input-group-text form-control custom-form-control bg-dark py-0">FOB</span>
                      </div>
                      <Input
                        type="number"
                        value={price.price}
                        name="price"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={(e) => onUpdatePrice(e, price._id)}
                      />
                      <div className="input-group-append rounded-end">
                        <select
                          className={
                            "form-control custom-form-control bg-dark py-0 " + (article.disabled && "disabled")
                          }
                          name={"currency"}
                          value={price.currency}
                          disabled={article.disabled}
                          onChange={article.disabled ? undefined : (e) => onUpdatePrice(e, price._id)}
                        >
                          {SUPPORTED_CURRENCIES.map((c) => (
                            <option key={c} value={c}>
                              {c}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={price.leadTime}
                        name="leadTime"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={(e) => onUpdatePrice(e, price._id)}
                      />
                      <div className="input-group-append rounded-end">
                        <div className="input-group-text form-control custom-form-control bg-dark">days</div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <span className="text-white text-nowrap">
                      {expIn(price) > 0 ? expIn(price) + " days" : expIn(price) === 0 ? "today" : "expired"}{" "}
                    </span>
                  </td>
                  <td className="align-middle">
                    <DateInput
                      value={price.validUntil}
                      onBlur={() => true}
                      name="validUntil"
                      onClick={(e) => {
                        e.preventDefault(); // prevent default date selection
                        this.setPriceToRenew(price);
                      }}
                      tabIndex={-1} // prevent focus via tab
                      classes="form-control custom-form-control bg-dark"
                    />
                  </td>
                  <td className="align-middle ">
                    <button
                      className="btn btn-text-danger btn-sm align-middle px-0"
                      onClick={() => onRemovePrice(price._id)}
                    >
                      <i className="fa fa-trash text-danger align-middle" />
                    </button>
                  </td>
                </tr>
              ))}
              <tr className={"mt-5"}>
                <td className="align-middle" colSpan={4}>
                  <div className="text-muted mt-5">
                    <span className="mr-2">Sales Forecast: {this.getSalesForecast()}</span>
                    <Tooltip
                      tooltipText={
                        <span className="text-white">
                          The system forecasts the expected sales volume depending on the price. If a low volume of
                          sales is expected, the system classifies the price as relatively high. Forecasts are only
                          generated if sufficient data is available.
                        </span>
                      }
                    >
                      <i className="fa fa-question-circle text-muted opacity-25" />
                    </Tooltip>
                  </div>
                </td>
                <td className="align-middle text-right" colSpan={2}>
                  <button className="btn btn-text btn-sm p-0 text-success font-weight-bold" onClick={onNewPrice}>
                    Add Price
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="col-12 align-self-center">
            <button className="btn btn-text btn-text-danger btn-sm float-right pr-0" onClick={onTogglePrices}>
              Cancel
            </button>
            <button
              className="btn btn-text btn-text-muted btn-sm float-right"
              onClick={() => this.setState({ renewAll: true })}
            >
              Renew All
            </button>
            <button
              className="btn btn-text btn-text-muted btn-sm float-right"
              onClick={() => this.setState({ adjustAll: true })}
            >
              Bulk Adjustment
            </button>
            <button
              className="btn btn-text btn-text-muted btn-sm float-right"
              onClick={() => this.setState({ showGraduationTool: true })}
            >
              Graduation Tool
            </button>
            <button
              className="btn btn-text btn-text-muted btn-sm float-right"
              onClick={() => this.setState({ setLeadTime: true })}
            >
              Set Preparation Time
            </button>
            <button className="btn btn-text btn-text-muted btn-sm float-right disabled">Limitations</button>
            <button className="btn btn-text btn-text-muted btn-sm float-right disabled">Settings</button>
            <button className="btn btn-text btn-text-muted btn-sm float-right" onClick={() => onDisable(true)}>
              Disable
            </button>
          </div>
        </div>
        <ArticlePriceRenewAllModal
          eventKey={article._id.toString()}
          onRenewPrices={onRenewPrices}
          price={priceToRenew}
          onClose={this.handlePriceToRenewClose}
          show={Boolean(renewAll || priceToRenew)}
        />
        <PriceAdjustmentModal show={adjustAll} onAdjust={onAdjust} onClose={() => this.handleBulkAdjustmentClose()} />
        <SetLeadTimeModal
          eventKey={article._id.toString()}
          article={article}
          supplier={supplier}
          onSetLeadTime={onSetLeadTime}
          onClose={this.handleSetLeadTimeClose}
          show={setLeadTime}
        />
        <PriceGraduationModal
          show={showGraduationTool}
          onClose={() => this.handleGraduationToolModalClose()}
          unit={article.unit}
          onSave={onCreateGraduation}
        />
      </>
    );
  }
}
