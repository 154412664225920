import { formatDate } from "../baseUtils";
import { standardTextShort } from "./invoiceGenerationUtils";

export interface StockListingRow {
  id: string;
  availableStock: number;
  isFinishedProduct: boolean;
  title: string;
  unit: string;
}

/**
 * Generates an HTML string representing a stock listing for commodities and finished products.
 *
 * @param {Array<StockListingRow>} articles - An array of objects representing stock items.
 * @returns {string} - The HTML string representing the stock listing.
 */
export function createStockListingHTML(articles: Array<StockListingRow>): string {
  const commodities = articles.filter((article) => !article.isFinishedProduct);
  commodities.sort((a, b) => a.title.localeCompare(b.title));

  const finishedProducts = articles.filter((article) => article.isFinishedProduct);
  finishedProducts.sort((a, b) => a.title.localeCompare(b.title));

  const stockListingHeader = `
    <table cellpadding='5' cellspacing='0' style='font-size:15px; width:100%; background-color:#fafafa; margin-top:20px'>
      <thead>
        <tr style='background-color:#333333; color: #ffffff;'>
          <th style='text-align: left; min-width: 250px'><b>Article</b></th>
          <th style='text-align: right'><b>Amount</b></th>
          <th style='text-align: right'><b>Unit</b></th>
        </tr>
      </thead>
      <tbody>
  `;

  return `
    <head>
      <link href='https://fonts.googleapis.com/css?family=Signika&display=swap' rel='stylesheet'/>
      <style>
        tr:nth-child(even) { background-color: white; }
      </style>
      <meta http-equiv='content-type' content='text/html; charset=utf-8'/>
    </head>
    <body style='font-family: Signika'>
      <img src='https://rawbids.com/images/logo-dark.png' alt="Rawbids Logo" width='180' style='float:right'/>
      <br/><br/><br/><br/>
      <span style='font-size: 30px; font-weight: bold'>Available Stock</span>
      <br/>
      <span style='font-size: 20px; font-weight: bold'>Created on ${formatDate(new Date())}</span>
      <br/><br/>
      <div style='width: 100%; margin-bottom: 5%'>
        <div style='float: right; max-width: 33%'>
          <span style='text-align: left'>
            <b>RAWBIDS GmbH</b>
            <br/>
            Willy-Brandt-Str. 23, DE - 20457 Hamburg
            <br/>
            Germany
            <br/>
            VAT-ID: DE354508196
          </span>
        </div>
      </div>
      <br/><br/><br/>
      <span style='font-size:22px; font-weight: bold;'>Commodities:</span>
      <br/>
      ${stockListingHeader}
      ${commodities
        .map(
          (commodity) => `
        <tr>
          <td style='width: 70%'>${commodity.title.trim()}</td>
          <td style='text-align: right; width: 15%'>${commodity.availableStock}</td>
          <td style='text-align: right; width: 15%'>${commodity.unit ?? "kg"}</td>
        </tr>
      `
        )
        .join("")}
      </tbody>
    </table>
    <br/><br/>
    <span style='font-size:22px; font-weight: bold;'>Finished Products:</span>
    <br/>
    ${stockListingHeader}
    ${finishedProducts
      .map(
        (finishedProduct) => `
        <tr>
          <td style='width: 70%'>${finishedProduct.title.trim()}</td>
          <td style='text-align: right; width: 15%'>${finishedProduct.availableStock}</td>
          <td style='text-align: right; width: 15%'>${finishedProduct.unit ?? "x 1000 pcs"}</td>
        </tr>
      `
      )
      .join("")}
      </tbody>
    </table>
    <br/><br/>
    ${standardTextShort}
  </body>
  `;
}
