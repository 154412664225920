import React from "react";
import CountryWidget from "../../common/CountryWidget";
import { CommodityExtended, CommoditySnapshot } from "../../../model/commodity.types";
import { CustomerCommodityExtended } from "../../../model/customer/customerCommodity.types";
import { Property } from "../../../model/property.types";
import { doFuseSearch, formatCurrency, round } from "../../../utils/baseUtils";
import { C_COMMODITY_PROPERTIES, getArticleProperty, isNonDefaultColor } from "../../../utils/commodityUtils";
import { PropertyType } from "../../../utils/propertyUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import {
  ArticleExtended,
  formatArticleUnit,
  getArticleProperties,
  isAnyFinishedProduct,
} from "../../../utils/productArticleUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";

export interface CommodityUpdateContent {
  label: string;
  property: C_COMMODITY_PROPERTIES | PropertyType;
  value: (
    commodity: CommoditySnapshot | CommodityExtended | CustomerCommodityExtended,
    context: DataContextInternalType
  ) => string | JSX.Element;
}

export const C_G_BASIC_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Title",
    property: "title",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => obj.title?.en,
  },
  {
    label: "Subtitle",
    property: "subtitle",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.subtitle?.en ? obj.subtitle.en : "-",
  },
  {
    label: "Category",
    property: PropertyType.CATEGORY,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const category = getArticleProperty(obj.properties, PropertyType.CATEGORY) as Property | null;
      return category ? category.name.en : "-";
    },
  },
  {
    label: "Article Number",
    property: "articleNo",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.articleNo ? obj.articleNo : "-",
  },
  {
    label: "HS-Code",
    property: "hsCode",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => (obj.hsCode ? obj.hsCode : "-"),
  },
  {
    label: "CAS Number",
    property: "casNumber",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const casNumbers = Array.isArray(obj.casNumber) ? obj.casNumber : [obj.casNumber];
      return casNumbers.length > 0 && casNumbers[0] !== undefined ? <div>{casNumbers.join(", ")}</div> : "-";
    },
  },
  {
    label: "Note",
    property: "note",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => (obj.note ? obj.note : "-"),
  },
  {
    label: "Botanical Name",
    property: "botanicalName",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.botanicalName ? obj.botanicalName : "-",
  },
  {
    label: "Carrier",
    property: PropertyType.CARRIER,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const carrier = getArticleProperty(obj.properties, PropertyType.CARRIER) as Property | null;
      return carrier ? carrier.name.en : "-";
    },
  },
  {
    label: "Shelf Life",
    property: "shelfLife",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.shelfLife ? obj.shelfLife + " months" : "-",
  },
  {
    label: "Duty",
    property: "duty",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.duty ? (
        <>
          {obj.duty.percentage !== undefined && obj.duty.percentage !== null && (
            <div>{obj.duty.percentage.toFixed(2) + "%"}</div>
          )}
          {obj.duty.dumpingFee && <div>Dumping Fee: {formatCurrency(obj.duty.dumpingFee, "EUR") + " per 100 KG"}</div>}
        </>
      ) : (
        "-"
      ),
  },
  {
    label: "Packaging",
    property: "packagingSizes",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.packagingSizes
        ? obj.packagingSizes
            .map((pS) => {
              return `${pS.packagingSize} kg ${pS.type}`;
            })
            .join(", ")
        : "-",
  },
  {
    label: "Composition",
    property: PropertyType.COMPOSITION,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const composition = getArticleProperty(obj.properties, PropertyType.COMPOSITION) as Property | null;
      return composition ? composition.name.en : "-";
    },
  },
  {
    label: "Organic",
    property: "organic",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.organic !== undefined ? (obj.organic ? "Yes" : "No") : "-",
  },
  {
    label: "Color",
    property: "color",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.color?.min && obj.color?.max ? "From " + obj.color.min + " to " + obj.color.max : "-",
  },
  {
    label: "Origin Country",
    property: "country",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.country ? <CountryWidget countryCode={obj.country.code} /> : "-",
  },
  {
    label: "Extraction Solvent",
    property: PropertyType.SOLVENT,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const solvent = getArticleProperty(obj.properties, PropertyType.SOLVENT) as Property | null;
      return solvent ? solvent.name.en : "-";
    },
  },
  {
    label: "Density",
    property: "density",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj && obj.density ? `${round(Number(obj.density.min), 2)} - ${round(Number(obj.density.max), 2)} kg/m³` : "-",
  },
  {
    label: "Used Part",
    property: "part",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.part?.en ? obj.part?.en : "-",
  },
  {
    label: "Analysis Method",
    property: PropertyType.ANALYSISMETHOD,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const analysisMethod = getArticleProperty(obj.properties, PropertyType.ANALYSISMETHOD) as Property | null;
      return analysisMethod ? analysisMethod.name.en : "-";
    },
  },
  {
    label: "Storage Conditions",
    property: "storageConditions",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.storageConditions?.en ? obj.storageConditions.en : "-",
  },
  {
    label: "Transport Conditions",
    property: PropertyType.TRANSPORTCONDITIONS,
    value: (
      obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot,
      context: DataContextInternalType
    ) => {
      return obj.transportConditions
        ? obj.transportConditions
            .map((tc) => {
              const condition = context.property.find((p) => tc.property === p._id.toString());
              return condition ? `${condition.name.en}: ${condition.description.en}` : "-";
            })
            .join(", ")
        : "-";
    },
  },
  {
    label: "BTI Reference No",
    property: "btiRefNo",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.btiRefNo ? obj.btiRefNo : "-",
  },
  {
    label: "Purpose of Use",
    property: PropertyType.PURPOSEOFUSE,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const purposeOfUse = getArticleProperty(
        obj.properties,
        PropertyType.PURPOSEOFUSE,
        true
      ) as Array<Property> | null;
      return purposeOfUse && purposeOfUse.length > 0
        ? purposeOfUse.map((pOU) => pOU.name.en).join(",")
        : "Not Specified";
    },
  },
  {
    label: "German VAT Rate",
    property: "vatPercentage",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.vatPercentage ? `${obj.vatPercentage}%` : "Not Specified",
  },
  {
    label: "Hide Supplier",
    property: "hiddenSupplier",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.hiddenSupplier ? "Yes" : "No",
  },
];

export const C_G_PHYSICALCHEMICAL_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Appearance",
    property: "appearance",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.appearance?.en ? obj.appearance.en : "-",
  },
  {
    label: "Odor",
    property: PropertyType.ODOR,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const odor = getArticleProperty(obj.properties, PropertyType.ODOR) as Property | null;
      return odor ? odor.name.en : "-";
    },
  },
  {
    label: "Loss on Drying",
    property: "lossOnDrying",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      (obj.lossOnDrying?.lessThan ? "≤" : "") + obj.lossOnDrying.amount + " %",
  },
  {
    label: "Ash",
    property: "ash",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      (obj.ash?.lessThan ? "≤" : "") + obj.ash.amount + " %",
  },
  {
    label: "Particle Size",
    property: "particleSize",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.particleSize?.en ? obj.particleSize.en : "-",
  },
  {
    label: "Specific Rotation",
    property: "specificRotation",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.specificRotation?.en ? obj.specificRotation.en : "-",
  },
  {
    label: "Purity",
    property: "purity",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.purity?.en ? obj.purity.en : "-",
  },
  {
    label: "pH",
    property: "ph",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.ph ? obj.ph.toString() : "Not specified",
  },
  {
    label: "Limits",
    property: "limits",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.limits?.en ? obj.limits.en : "-",
  },
];

export const C_G_PRODUCTINFORMATION_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Vegetarian",
    property: "vegetarian",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.vegetarian !== undefined ? (obj.vegetarian ? "Yes" : "No") : "-",
  },
  {
    label: "Vegan",
    property: "vegan",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.vegan !== undefined ? (obj.vegan ? "Yes" : "No") : "-",
  },
  {
    label: "Halal",
    property: "halal",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.halal !== undefined ? (obj.halal ? "Yes" : "No") : "-",
  },
  {
    label: "Kosher",
    property: "kosher",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.kosher !== undefined ? (obj.kosher ? "Yes" : "No") : "-",
  },
  {
    label: "Packaging",
    property: PropertyType.PACKAGING,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const packaging = getArticleProperty(obj.properties, PropertyType.PACKAGING) as Property | null;
      return packaging ? packaging.name.en : "-";
    },
  },
  {
    label: "Hazard Material",
    property: "hazardMaterial",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.hazardMaterial !== undefined ? (obj.hazardMaterial ? "Yes" : "No") : "-",
  },
  {
    label: "Novel Food",
    property: "novelFood",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.novelFood !== undefined ? (obj.novelFood ? "Yes" : "No") : "-",
  },
  {
    label: "CITES",
    property: "cites",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.cites !== undefined ? (obj.cites ? "Yes" : "No") : "-",
  },
  {
    label: "ECHA",
    property: "echa",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.echa !== undefined ? (obj.echa ? "Yes" : "No") : "-",
  },
  {
    label: "Ratio Extract",
    property: "ratioExtract",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.ratioExtract ? obj.ratioExtract : "-",
  },
];

export const C_G_GRADE_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Food Grade",
    property: "foodGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.foodGrade !== undefined ? (obj.foodGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Pharmaceutical Grade",
    property: "pharmaceuticalGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.pharmaceuticalGrade !== undefined ? (obj.pharmaceuticalGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Feed Grade",
    property: "feedGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.feedGrade !== undefined ? (obj.feedGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Cosmetic Grade",
    property: "cosmeticGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.cosmeticGrade !== undefined ? (obj.cosmeticGrade ? "Yes" : "No") : "-",
  },
  {
    label: "USP Grade",
    property: "uspGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.uspGrade !== undefined ? (obj.uspGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Medicine Grade",
    property: "medicineGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.medicineGrade !== undefined ? (obj.medicineGrade ? "Yes" : "No") : "-",
  },
  {
    label: "Industrial Grade",
    property: "industrialGrade",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.industrialGrade !== undefined ? (obj.industrialGrade ? "Yes" : "No") : "-",
  },
];

export const C_G_CONTAMINANTS_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Aflatoxins",
    property: "aflatoxins",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.aflatoxins?.en ? obj.aflatoxins.en : "-",
  },
  {
    label: "PAH 4",
    property: "pah4",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.pah4?.en ? obj.pah4.en : "-",
  },
  {
    label: "Benzopyrene",
    property: "benzoypyrene",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.benzoypyrene?.en ? obj.benzoypyrene.en : "-",
  },
  {
    label: "Max. Allowed ETO",
    property: "maxAllowedWETO",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.maxAllowedWETO?.en ? obj.maxAllowedWETO.en : "-",
  },
];

export const C_G_REGULATORY_STATEMENT_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Max. Allowed Heavy Metals",
    property: "maxAllowedHeavyMetals",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.maxAllowedHeavyMetals?.en ? obj.maxAllowedHeavyMetals.en : "-",
  },
  {
    label: "Total Residual Organic Solvents",
    property: "totalResidualOrganicSolvents",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.totalResidualOrganicSolvents?.en ? obj.totalResidualOrganicSolvents.en : "-",
  },
  {
    label: "Max. Allowed Microbiology",
    property: "maxAllowedMicrobiology",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.maxAllowedMicrobiology?.en ? obj.maxAllowedMicrobiology.en : "-",
  },
  {
    label: "Regulatory Data",
    property: "regulatoryData",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.regulatoryData?.en ? obj.regulatoryData.en : "-",
  },
  {
    label: "Possible Cross Contamination",
    property: "possibleCrossContamination",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.possibleCrossContamination?.en ? obj.possibleCrossContamination.en : "-",
  },
];

export const C_G_OTHER_CATEGORIES_UPDATE: Array<CommodityUpdateContent> = [
  {
    label: "Active Substances",
    property: "activeSubstances",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      return obj.activeSubstances
        ? obj.activeSubstances
            .map((as) => {
              return `${as.substance.name.en} with ${as.percentage}, NRV: ${as.substance.nrv}`;
            })
            .join(", ")
        : "-";
    },
  },
  {
    label: "Allergens",
    property: PropertyType.ALLERGEN,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const allergens = getArticleProperty(obj.properties, PropertyType.ALLERGEN, true) as Array<Property> | null;
      return allergens
        ? allergens
            .map((a) => {
              return a.name.en;
            })
            .join(", ")
        : "-";
    },
  },
  {
    label: "Commodity Tags",
    property: PropertyType.TAG,
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) => {
      const tags = getArticleProperty(obj.properties, PropertyType.TAG, true) as Array<Property> | null;
      return tags
        ? tags
            .map((t) => {
              return t.name.en;
            })
            .join(", ")
        : "-";
    },
  },
  {
    label: "Sample Sizes",
    property: "sampleSize",
    value: (obj: CommodityExtended | CustomerCommodityExtended | CommoditySnapshot) =>
      obj.sampleSize
        ? obj.sampleSize
            .map((s) => s.amount + formatArticleUnit(obj.unit) + " for " + s.price + BASE_CURRENCY)
            .join(", ")
        : "-",
  },
];

export const C_UPDATE_TABLE_DEFINITIONS: Array<{
  content: Array<CommodityUpdateContent>;
  mainTitle: string;
  subtitle: string;
}> = [
  { content: C_G_BASIC_UPDATE, mainTitle: "Basic Information", subtitle: "General Properties" },
  {
    content: C_G_PHYSICALCHEMICAL_UPDATE,
    mainTitle: "Advanced Properties",
    subtitle: "Physical & Chemical Parameters",
  },
  { content: C_G_PRODUCTINFORMATION_UPDATE, mainTitle: "Advanced Properties", subtitle: "General Product Information" },
  { content: C_G_GRADE_UPDATE, mainTitle: "Advanced Properties", subtitle: "Grade" },
  { content: C_G_CONTAMINANTS_UPDATE, mainTitle: "Advanced Properties", subtitle: "Contaminants" },
  { content: C_G_REGULATORY_STATEMENT_UPDATE, mainTitle: "Advanced Properties", subtitle: "Regulatory Statement" },
  { content: C_G_OTHER_CATEGORIES_UPDATE, mainTitle: "Advanced Properties", subtitle: "Other Categories" },
];

/**
 * Filters the basic properties of the given commodity.
 * @param article Article whose properties should be filtered
 * @param search Search query
 * @returns { Array<{ label: string; value: string | JSX.Element, className?: string }> } List of properties with their values
 */
export function filterBasicProperties(
  article: ArticleExtended,
  search: string
): Array<{ label: string; value: string | JSX.Element; className?: string }> {
  const articleProperties = getArticleProperties(article.properties);
  const category = articleProperties.category[0];
  const composition = articleProperties.composition[0];
  const solvent = articleProperties.solvent[0];
  const purposeOfUse = articleProperties.purposeOfUse;

  const properties: Array<{ label: string; value: string | JSX.Element; className?: string }> = [];
  if (!isAnyFinishedProduct(article))
    properties.push({
      label: "CAS Number",
      value: (
        <>
          {article.casNumber.map((c) => (
            <div key={c}>{c}</div>
          ))}
        </>
      ),
    });
  properties.push({ label: "Origin Country", value: <CountryWidget countryCode={article.country.code} /> });
  properties.push({ label: "Article Number", value: article.articleNo ? article.articleNo : "-" });
  properties.push({ label: "Organic", value: article?.organic ? "Yes" : "No" });
  properties.push({ label: "Category", value: category?.name.en ?? "-" });
  properties.push({ label: "HS-Code", value: article.hsCode ? article.hsCode : "-" });
  properties.push({ label: "Composition", value: composition?.name.en ?? "-" });
  properties.push({ label: "Extraction Solvent", value: solvent?.name.en ?? "-" });
  if (!isAnyFinishedProduct(article)) {
    properties.push({
      label: "Color",
      value: isNonDefaultColor(article.color) ? (
        <div>
          from
          <span className="badge align-middle mx-1" style={{ backgroundColor: article.color.min.toString() }}>
            &nbsp;
          </span>
          to
          <span className="badge align-middle ml-1 " style={{ backgroundColor: article.color.max.toString() }}>
            &nbsp;
          </span>
        </div>
      ) : (
        "-"
      ),
    });
  } else {
    properties.push({ label: "Color", value: article.color });
  }
  properties.push({ label: "Note", value: article.note ? article.note : "-" });
  if (!isAnyFinishedProduct(article)) {
    properties.push({
      label: "Density",
      value: article.density ? `${article.density.min} - ${article.density.max} kg/m³` : "-",
    });
  } else {
    properties.push({ label: "Volume per Unit", value: `${article.volumePerUnit} m³` });
    properties.push({ label: "Weight per Unit", value: `${article.weightPerUnit} kg` });
  }
  properties.push({
    label: "Purpose of Use",
    value: purposeOfUse.length > 0 ? purposeOfUse.map((pOU) => pOU.name.en).join(",") : "Not Specified",
  });
  properties.push({
    label: "German VAT Rate",
    value: article.vatPercentage ? `${article.vatPercentage}%` : "Not Specified",
  });
  return search.trim() ? doFuseSearch(properties, search, ["label"]) : properties;
}

/**
 * Generates the table elements to properly render the properties table rows.
 * @param propertiesList List of properties that should be rendered
 * @returns { Array<JSX.Element> } List of table rows
 */
export function generateCommodityPropertyTableRows(
  propertiesList: Array<{ label: string; value: string | JSX.Element; className?: string }>
): Array<JSX.Element> {
  const elements: Array<JSX.Element> = [];
  for (let i = 0; i < propertiesList.length; i += 2) {
    const e1 = propertiesList[i];
    const e2 = propertiesList[i + 1];

    elements.push(
      <tr key={i}>
        <td className="text-white w-25 min-w-125px">{e1.label}</td>
        <td className="text-muted w-25">
          <div className={"overflow-auto text-break " + (e1?.className || "")} style={{ maxHeight: "75px" }}>
            {e1.value}
          </div>
        </td>
        <td className="text-white w-25 min-w-125px">{e2 ? e2.label : ""}</td>
        <td className="text-muted w-25">
          <div className={"overflow-auto text-break " + (e2?.className || "")} style={{ maxHeight: "75px" }}>
            {e2 ? e2.value : ""}
          </div>
        </td>
      </tr>
    );
  }
  return elements;
}
