import { ApexOptions } from "apexcharts";
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { TurnoverCurrentYear } from "../../../../model/statistics/controllingStatistics.types";
import { formatCurrency } from "../../../../utils/baseUtils";
import { EURO } from "../../../../utils/currencyUtils";
import { getDateFromDayOfYear, getDayOfYear, getMonthString } from "../../../../utils/dateUtils";

interface TurnoverDevelopmentGraphProps {
  turnoverStatistics: Array<{ date: Date; turnover: TurnoverCurrentYear }>;
}

interface TurnoverDevelopmentSeries {
  name: string;
  data: Array<{ x: number; y: number }>;
}

/**
 * Prepare the data for the apex charts graph
 * @param turnoverStatistics Turnover statistics that should be used to generate data
 * @returns {Array<TurnoverDevelopmentSeries>} Prepared series
 */
const prepareData = (
  turnoverStatistics: Array<{ date: Date; turnover: TurnoverCurrentYear }>
): Array<TurnoverDevelopmentSeries> => {
  // 367 since element 0 should stay 0, and we might have a leap year
  const turnoverData = new Array(366).fill(0) as Array<number>;
  let latestDay = 0;
  for (let i = 0; i < turnoverStatistics.length; i++) {
    const tS = turnoverStatistics[i];
    const day = getDayOfYear(tS.date);
    if (day > latestDay) latestDay = day;
    turnoverData[day] = tS.turnover.turnoverAmount;
  }
  const actualData = turnoverData.slice(0, latestDay + 1);

  for (let i = 0; i < actualData.length - 1; i++) {
    const a1 = actualData[i];
    const a2 = actualData[i + 1];
    if (a1 > 0 && a2 === 0) actualData[i + 1] = a1;
  }

  const forecastData = new Array(367 - latestDay).fill(null) as Array<number | null>;

  const lastValue = actualData[actualData.length - 1];
  for (let i = 0; i < forecastData.length; i++) {
    const increment = lastValue / latestDay;
    forecastData[i] = Math.round(lastValue + increment * (i + 1));
  }
  return [
    {
      name: "Actual",
      data: actualData.map((a, idx) => {
        return { x: idx, y: a };
      }),
    },
    {
      name: "Forecast",
      data: forecastData.map((a, idx) => {
        return { x: idx + latestDay + 1, y: a || 0 };
      }),
    },
  ];
};

const TurnoverDevelopmentGraph: React.FC<TurnoverDevelopmentGraphProps> = ({ turnoverStatistics }) => {
  const [series, setSeries] = useState<Array<TurnoverDevelopmentSeries>>(prepareData(turnoverStatistics));

  useEffect(() => {
    setSeries(prepareData(turnoverStatistics));
  }, [turnoverStatistics]);

  const options: ApexOptions = useMemo(() => {
    return {
      stroke: {
        dashArray: [0, 6],
      },
      grid: { strokeDashArray: 5, borderColor: "#777" },
      colors: ["#50cd89", "#777"],
      tooltip: {
        enabled: true,
        theme: "dark",
        x: {
          formatter(val: number) {
            return getDateFromDayOfYear(val).toDateString();
          },
          show: false,
        },
        y: {
          formatter: function (value: number) {
            return formatCurrency(value, EURO);
          },
          title: {
            formatter: () => "",
          },
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: "#9aa0ac",
            fontSize: "12px",
          },
          // 2 extra days due to having 367 data points
          formatter: (value) => getMonthString(getDateFromDayOfYear(Number(value) + 2).getMonth()),
        },
        axisBorder: {
          show: true,
          color: "#78909c",
        },
        axisTicks: {
          show: true,
          color: "#78909c",
        },
        tickAmount: 12,
      },
      yaxis: [
        {
          labels: {
            show: true,
            style: {
              colors: "#9aa0ac",
              fontSize: "12px",
            },
            formatter: (value) => `${value / 1000000} Mio.`,
          },
          axisBorder: {
            show: true,
            color: "#78909c",
          },
          axisTicks: {
            show: true,
            color: "#78909c",
          },
        },
      ],
      legend: {
        show: false,
      },
      chart: {
        type: "line",
        animations: { enabled: true },
        zoom: { enabled: true },
        toolbar: { show: false },
        sparkline: { enabled: false },
      },
      fill: {
        colors: ["#333333"],
        opacity: 1,
        type: "solid",
      },
    };
  }, []);

  const actualLast = useMemo(() => {
    const actual = Object.values(series[0].data);
    if (actual.length === 0) return 0;
    return actual[actual.length - 1].y;
  }, [series]);

  return (
    <div className="card bg-white rounded h-400px">
      <div className="card-header border-0">
        <div className="card-title d-flex justify-content-between align-items-center w-100">
          <h2 className="mb-0">Turnover Development</h2>
          <h1 className="mb-0 font-weight-bold">{formatCurrency(actualLast, EURO)}</h1>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-column px-0 pb-0">
        <div>
          <ReactApexChart options={options} series={series} height="320px" />
        </div>
      </div>
    </div>
  );
};

export default TurnoverDevelopmentGraph;
