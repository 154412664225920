import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextCustomer } from "../../context/dataContext";
import ShoppingCartCheckout from "./ShoppingCartCheckout";

const ShoppingCartCheckoutWrapper = (props: RouteComponentProps) => {
  const context = useContext(DataContextCustomer);
  return <ShoppingCartCheckout context={context} {...props} />;
};

export default ShoppingCartCheckoutWrapper;
