import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PackagingDimension, SupplierExtended } from "../../../model/supplier.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import { Commodity } from "../../../model/commodity.types";
import { getArticlesForSupplier } from "../../../utils/commodityUtils";
import BaseListing from "../../common/BaseListing";
import { updateSupplier } from "../../../utils/supplierUtils";
import {
  calculateCBM,
  isSelectionDisabled,
  PD_TRANSPORT_TYPE_OPTIONS,
  updatePackagingDimension,
} from "../../../utils/packagingDimensionUtils";
import { SupplierSupplierExtended } from "../../../model/supplier/supplierSupplier.types";
import Tooltip from "../../common/Tooltip";
import { round } from "../../../utils/baseUtils";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { COMMODITY, FINISHEDPRODUCT } from "../../../services/dbService";
import { DataContextInternalType, DataContextSupplierType } from "../../../context/dataContext";

interface PackagingDimensionRowProps {
  packagingDimension: PackagingDimension;
  supplier: SupplierExtended | SupplierSupplierExtended;
  packagingDimensionUpdating?: string;
  context: DataContextInternalType | DataContextSupplierType;
  onSetPackagingDimensionUpdating: (packagingDimensionUpdating?: string) => void;
}

interface PackagingDimensionRowState {
  packagingDimensionEdit: PackagingDimension;
  commodities: Array<Commodity>;
  commoditiesSelected: Array<string>;
  commoditiesUsageMap: { [id: string]: Array<string> };
  finishedProducts: Array<FinishedProduct>;
  finishedProductsSelected: Array<string>;
  finishedProductsUsageMap: { [id: string]: Array<string> };
  articleType: SelectOption;
  transportTypes: Array<SelectOption>;
  all: boolean;
  saving: boolean;
}

class PackagingDimensionEntry extends PureComponent<PackagingDimensionRowProps, PackagingDimensionRowState> {
  constructor(props: PackagingDimensionRowProps) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  componentDidUpdate(prevProps: Readonly<PackagingDimensionRowProps>) {
    if (
      !_.isEqual(prevProps.packagingDimension, this.props.packagingDimension) ||
      !_.isEqual(prevProps.packagingDimensionUpdating, this.props.packagingDimensionUpdating) ||
      !_.isEqual(prevProps.supplier.packagingDimensions, this.props.supplier.packagingDimensions)
    ) {
      this.setState(this.getDefaultState());
    }
  }

  handleChangePackagingDimension = (e: React.ChangeEvent<HTMLInputElement>) => {
    const packagingDimensionEdit = _.cloneDeep(this.state.packagingDimensionEdit);
    const { type, name, value } = e.target;
    // @ts-ignore
    packagingDimensionEdit[name] = type === "number" ? Number(value) : value;
    packagingDimensionEdit.cbm = calculateCBM(
      packagingDimensionEdit.length,
      packagingDimensionEdit.width,
      packagingDimensionEdit.height
    );
    this.setState({ packagingDimensionEdit });
  };

  handleChangeStackable = (e: SelectOption) => {
    const packagingDimensionEdit = _.cloneDeep(this.state.packagingDimensionEdit);
    packagingDimensionEdit.stackable = e.value === "yes";
    this.setState({ packagingDimensionEdit });
  };

  handleChangeArticleType = (e: SelectOption) => {
    const { packagingDimensionEdit } = _.cloneDeep(this.state);
    packagingDimensionEdit.commodities = [];
    packagingDimensionEdit.finishedProducts = [];
    this.setState({
      articleType: e,
      finishedProductsSelected: [],
      commoditiesSelected: [],
      packagingDimensionEdit,
      all: false,
    });
  };

  handleSelectCommodity = (commodity: string) => {
    const { commodities, commoditiesSelected, commoditiesUsageMap } = this.state;
    const packagingDimensionEdit = _.cloneDeep(this.state.packagingDimensionEdit);
    let selected: Array<string> = [];
    if (commodity === "all") {
      selected = commodities
        .filter((com) => !isSelectionDisabled(com, packagingDimensionEdit, commoditiesUsageMap))
        .map((c) => c._id.toString());
      packagingDimensionEdit.commodities = selected;
    } else if (commodity === "none") {
      packagingDimensionEdit.commodities = [];
    } else {
      if (commoditiesSelected.some((c) => c === commodity))
        selected = commoditiesSelected.filter((c) => c !== commodity);
      else selected = commoditiesSelected.concat(commodity);
      packagingDimensionEdit.commodities = selected;
    }
    this.setState({ commoditiesSelected: selected, packagingDimensionEdit });
  };

  handleSelectFinishedProduct = (finishedProduct: string) => {
    const { finishedProducts, finishedProductsSelected, finishedProductsUsageMap } = this.state;
    const packagingDimensionEdit = _.cloneDeep(this.state.packagingDimensionEdit);
    let selected: Array<string> = [];
    if (finishedProduct === "all") {
      selected = finishedProducts
        .filter((com) => !isSelectionDisabled(com, packagingDimensionEdit, finishedProductsUsageMap))
        .map((c) => c._id.toString());
      packagingDimensionEdit.finishedProducts = selected;
    } else if (finishedProduct === "none") {
      packagingDimensionEdit.finishedProducts = [];
    } else {
      if (finishedProductsSelected.some((c) => c === finishedProduct))
        selected = finishedProductsSelected.filter((c) => c !== finishedProduct);
      else selected = finishedProductsSelected.concat(finishedProduct);
      packagingDimensionEdit.finishedProducts = selected;
    }
    this.setState({ finishedProductsSelected: selected, packagingDimensionEdit });
  };

  handleSelectAll = () => {
    const { commodities, finishedProducts, articleType } = this.state;
    let commoditiesSelected: Array<string>;
    let finishedProductsSelected: Array<string>;
    const packagingDimensionEdit = _.cloneDeep(this.state.packagingDimensionEdit);
    if (packagingDimensionEdit.commodities === "all" || packagingDimensionEdit.finishedProducts === "all") {
      packagingDimensionEdit.commodities = [];
      commoditiesSelected = [];
      packagingDimensionEdit.finishedProducts = [];
      finishedProductsSelected = [];
    } else {
      if (articleType.value === "both") {
        packagingDimensionEdit.commodities = "all";
        commoditiesSelected = commodities.map((c) => c._id.toString());
        packagingDimensionEdit.finishedProducts = "all";
        finishedProductsSelected = finishedProducts.map((fp) => fp._id.toString());
      } else if (articleType.value === FINISHEDPRODUCT) {
        packagingDimensionEdit.finishedProducts = "all";
        finishedProductsSelected = finishedProducts.map((fp) => fp._id.toString());
        packagingDimensionEdit.commodities = [];
        commoditiesSelected = [];
      } else {
        packagingDimensionEdit.commodities = "all";
        commoditiesSelected = commodities.map((c) => c._id.toString());
        packagingDimensionEdit.finishedProducts = [];
        finishedProductsSelected = [];
      }
    }
    this.setState({ packagingDimensionEdit, all: !this.state.all, commoditiesSelected, finishedProductsSelected });
  };

  handleSelectTransportTyp = (types: Array<SelectOption>) => {
    this.setState({ transportTypes: types });
  };

  handleSavePackagingDimension = async () => {
    const { packagingDimension, supplier, onSetPackagingDimensionUpdating } = this.props;
    const { all, transportTypes, articleType } = this.state;
    this.setState({ saving: true });
    // Set transport types
    const packagingDimensionUpdated = _.cloneDeep(this.state.packagingDimensionEdit);
    packagingDimensionUpdated.transportTypes = transportTypes.map((t) => t.value);

    try {
      if (all) {
        const dimensions = _.cloneDeep(supplier.packagingDimensions) || [];
        for (let i = 0; i < dimensions.length; i++) {
          const d = dimensions[i];
          if (d._id.toString() === packagingDimension._id.toString()) dimensions[i] = packagingDimensionUpdated;
          // Reset commodities if others have overlapping transport types
          else if (d.transportTypes?.some((t) => packagingDimensionUpdated.transportTypes!.includes(t)))
            if (articleType.value === COMMODITY) d.commodities = [];
            else if (articleType.value === FINISHEDPRODUCT) d.finishedProducts = [];
            else {
              d.commodities = [];
              d.finishedProducts = [];
            }
        }
        const res = await updateSupplier({ packagingDimensions: dimensions }, supplier._id);
        if (res && res.modifiedCount > 0) {
          toast.success("Palette successfully marked as used for every ingredient");
          onSetPackagingDimensionUpdating();
        } else {
          toast.error("Error marking palette as used for every ingredient");
        }
      } else {
        const res = await updatePackagingDimension(packagingDimensionUpdated, supplier._id);
        if (res) {
          toast.success("Palette data updated successfully");
          onSetPackagingDimensionUpdating();
        } else {
          toast.error("Error updating palette data");
        }
      }
    } catch (e) {
      console.error("Error updating palette data:", e);
      toast.error("Error updating palette data");
    } finally {
      this.setState({ saving: false });
    }
  };

  getRelevantCommodities = (props?: PackagingDimensionRowProps) => {
    const { packagingDimension, context, supplier } = props ? props : this.props;
    const { commodity } = context;
    const supCommodities = getArticlesForSupplier(supplier._id, commodity) as Array<Commodity>;
    const commoditiesSelected =
      packagingDimension.commodities === "all"
        ? supCommodities.map((c) => c._id.toString())
        : packagingDimension.commodities;
    const commoditiesUsageMap: { [id: string]: Array<string> } = {};
    supplier.packagingDimensions
      ?.filter((pd) => pd._id.toString() !== packagingDimension._id.toString())
      .forEach((pd) => {
        if (pd.commodities === "all")
          supCommodities.forEach((c) =>
            c._id.toString() in commoditiesUsageMap
              ? commoditiesUsageMap[c._id.toString()].push(...(pd.transportTypes || []))
              : (commoditiesUsageMap[c._id.toString()] = _.cloneDeep(pd.transportTypes) || [])
          );
        else
          pd.commodities
            ? pd.commodities.forEach((c) =>
                c in commoditiesUsageMap
                  ? commoditiesUsageMap[c].push(...(pd.transportTypes || []))
                  : (commoditiesUsageMap[c] = _.cloneDeep(pd.transportTypes) || [])
              )
            : [];
      });
    return { commodities: supCommodities, commoditiesSelected: commoditiesSelected ?? [], commoditiesUsageMap };
  };

  getRelevantFinishedProducts = (props?: PackagingDimensionRowProps) => {
    const { packagingDimension, context, supplier } = props ? props : this.props;
    const { finishedProduct } = context;
    const supFinishedProducts = getArticlesForSupplier(supplier._id, finishedProduct) as Array<FinishedProduct>;
    const finishedProductsSelected =
      packagingDimension.finishedProducts === "all"
        ? supFinishedProducts.map((fp) => fp._id.toString())
        : packagingDimension.finishedProducts;
    const finishedProductsUsageMap: { [id: string]: Array<string> } = {};
    supplier.packagingDimensions
      ?.filter((pd) => pd._id.toString() !== packagingDimension._id.toString())
      .forEach((pd) => {
        if (pd.finishedProducts === "all")
          supFinishedProducts.forEach((fp) =>
            fp._id.toString() in finishedProductsUsageMap
              ? finishedProductsUsageMap[fp._id.toString()].push(...(pd.transportTypes || []))
              : (finishedProductsUsageMap[fp._id.toString()] = _.cloneDeep(pd.transportTypes) || [])
          );
        else
          pd.finishedProducts
            ? pd.finishedProducts.forEach((fp) =>
                fp in finishedProductsUsageMap
                  ? finishedProductsUsageMap[fp].push(...(pd.transportTypes || []))
                  : (finishedProductsUsageMap[fp] = _.cloneDeep(pd.transportTypes) || [])
              )
            : [];
      });
    return {
      finishedProducts: supFinishedProducts,
      finishedProductsSelected: finishedProductsSelected ?? [],
      finishedProductsUsageMap,
    };
  };

  getDefaultState = (props?: PackagingDimensionRowProps): PackagingDimensionRowState => {
    const { packagingDimension } = props ? props : this.props;
    const relevantCommodities = this.getRelevantCommodities(props);
    const relevantFinishedProducts = this.getRelevantFinishedProducts(props);
    return {
      saving: false,
      packagingDimensionEdit: _.cloneDeep(packagingDimension),
      ...relevantCommodities,
      ...relevantFinishedProducts,
      all: packagingDimension?.commodities === "all" || packagingDimension?.finishedProducts === "all",
      transportTypes: packagingDimension.transportTypes
        ? PD_TRANSPORT_TYPE_OPTIONS.filter((t) => packagingDimension.transportTypes?.includes(t.value))
        : PD_TRANSPORT_TYPE_OPTIONS,
      articleType:
        (relevantFinishedProducts.finishedProductsSelected.length > 0 ||
          packagingDimension.finishedProducts === "all") &&
        (relevantCommodities.commoditiesSelected.length > 0 || packagingDimension.commodities === "all")
          ? { value: "both", label: "Both" }
          : relevantFinishedProducts.finishedProductsSelected.length > 0 ||
            packagingDimension.finishedProducts === "all"
          ? { value: FINISHEDPRODUCT, label: "Finished Product" }
          : { value: COMMODITY, label: "Commodities" },
    };
  };

  validateData = () => {
    const { length, height, width, netWeight, grossWeight } = this.state.packagingDimensionEdit;
    const errors: Array<string> = [];
    if (length <= 0) errors.push("Length has to be positive");
    if (height <= 0) errors.push("Height has to be positive");
    if (width <= 0) errors.push("Width has to be positive");
    if (netWeight <= 0) errors.push("Net weight has to be positive");
    if (grossWeight <= 0) errors.push("Gross weight has to be positive");
    return errors;
  };

  render() {
    const {
      packagingDimension: pd,
      supplier,
      packagingDimensionUpdating,
      onSetPackagingDimensionUpdating,
    } = this.props;
    const {
      packagingDimensionEdit,
      saving,
      commodities,
      commoditiesSelected,
      all,
      commoditiesUsageMap,
      finishedProducts,
      finishedProductsSelected,
      finishedProductsUsageMap,
      transportTypes,
      articleType,
    } = this.state;
    const errors = this.validateData();

    const updating = Boolean(
      packagingDimensionUpdating && packagingDimensionUpdating === packagingDimensionEdit._id.toString()
    );
    const saveConflictsCommodity =
      (articleType.value === COMMODITY || articleType.value === "both") &&
      all &&
      supplier.packagingDimensions?.some(
        (d) =>
          d._id.toString() !== packagingDimensionEdit._id.toString() &&
          (d.commodities === "all" || d.commodities.length > 0) &&
          d.transportTypes?.some((t) => transportTypes.some((tt) => tt.value === t))
      );
    const saveConflictsFinishedProduct =
      (articleType.value === FINISHEDPRODUCT || articleType.value === "both") &&
      all &&
      supplier.packagingDimensions?.some(
        (d) =>
          d._id.toString() !== packagingDimensionEdit._id.toString() &&
          (d.finishedProducts === "all" || (d.finishedProducts && d.finishedProducts.length > 0)) &&
          d.transportTypes?.some((t) => transportTypes.some((tt) => tt.value === t))
      );
    const saveConflictsBoth = articleType.value === "both" && (saveConflictsCommodity || saveConflictsFinishedProduct);

    return (
      <div className="bg-light2 rounded p-5 mb-7">
        <div className="d-flex align-items-center ">
          <div className="flex-grow-1 me-2 text-white fs-5">
            {pd.description} {pd.length} x {pd.width} x {pd.height} ({pd.cbm}m³)
          </div>
          <div style={{ textAlign: "right", marginRight: 25 }}>
            <div className="mb-1 fs-6 fw-bold text-nowrap">
              Used for{" "}
              {articleType.value === COMMODITY
                ? `${pd.commodities === "all" ? "all commodities" : pd.commodities.length + " commodities"}`
                : articleType.value === FINISHEDPRODUCT
                ? `${
                    pd.finishedProducts === "all"
                      ? "all finished products"
                      : (pd.finishedProducts?.length || 0) + " finished products"
                  }`
                : `${
                    pd.commodities === "all" && pd.finishedProducts === "all"
                      ? "all articles"
                      : pd.commodities === "all" && pd.finishedProducts !== "all"
                      ? "all commodities and " + (pd.finishedProducts?.length || 0) + " finished products"
                      : pd.commodities !== "all" && pd.finishedProducts === "all"
                      ? "all finished products and " + pd.commodities.length + " commodities"
                      : (pd.finishedProducts?.length || 0) + pd.commodities.length + " articles"
                  }`}
            </div>
            {pd.transportTypes?.map((tt) => (
              <span className="badge badge-sm badge-gray mr-1 mt-1" key={tt}>
                {_.upperFirst(tt)}
              </span>
            ))}
          </div>
          <div style={{ textAlign: "right", marginRight: 10, width: 80 }}>
            {updating ? (
              <ErrorOverlayButton
                warnings={
                  saveConflictsBoth
                    ? [
                        "Commodity or finished product selection for other palette data will be overwritten due to conflicting usages",
                      ]
                    : saveConflictsCommodity
                    ? ["Commodity selection for other palette data will be overwritten due to conflicting usage"]
                    : saveConflictsFinishedProduct
                    ? ["Finished product selection for other palette data will be overwritten due to conflicting usage"]
                    : []
                }
                errors={errors}
                className={
                  "btn btn-sm w-100 " +
                  (saveConflictsCommodity || saveConflictsFinishedProduct || saveConflictsBoth
                    ? "btn-warning"
                    : "btn-success")
                }
                saving={saving}
                buttonText="Save"
                onClick={this.handleSavePackagingDimension}
              />
            ) : (
              <button
                className="btn btn-outline btn-outline-light btn-sm w-100"
                onClick={() => onSetPackagingDimensionUpdating(packagingDimensionEdit._id.toString())}
              >
                Update
              </button>
            )}
          </div>
        </div>
        {updating && (
          <div className="table-responsive mt-5">
            <table className="table align-middle gs-0 gy-1 ">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0 w-50">Description</th>
                  <th className="border-bottom-0 w-50 text-ellipsis">Used For</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <Input
                      type="text"
                      value={packagingDimensionEdit.description}
                      className="form-control custom-form-control pt-0 pb-0 bg-dark"
                      name="description"
                      onBlur={this.handleChangePackagingDimension}
                    />
                  </td>
                  <td className="align-middle">
                    <CustomSelect
                      additionalClasses={"select-default-dark"}
                      isMulti={true}
                      options={PD_TRANSPORT_TYPE_OPTIONS}
                      matchFormControl={true}
                      value={transportTypes}
                      onChange={this.handleSelectTransportTyp}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table align-middle gs-0 gy-1 ">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0 w-25">Length</th>
                  <th className="border-bottom-0 w-25">Width</th>
                  <th className="border-bottom-0 w-25">Height</th>
                  <th className="border-bottom-0 w-25">CBM (calculated)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={packagingDimensionEdit.length}
                        name="length"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={this.handleChangePackagingDimension}
                      />
                      <div className="input-group-append rounded-end">
                        <div className="form-control custom-form-control bg-dark" style={{ padding: ".375rem .75rem" }}>
                          cm
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={packagingDimensionEdit.width}
                        name="width"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={this.handleChangePackagingDimension}
                      />
                      <div className="input-group-append rounded-end">
                        <div className="form-control custom-form-control bg-dark" style={{ padding: ".375rem .75rem" }}>
                          cm
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={packagingDimensionEdit.height}
                        name="height"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={this.handleChangePackagingDimension}
                      />
                      <div className="input-group-append rounded-end">
                        <div className="form-control custom-form-control bg-dark" style={{ padding: ".375rem .75rem" }}>
                          cm
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <input
                        type="text"
                        value={packagingDimensionEdit.cbm}
                        disabled={true}
                        className="form-control custom-form-control py-0 bg-dark"
                      />
                      <div className="input-group-append rounded-end">
                        <div className="form-control custom-form-control bg-dark" style={{ padding: ".375rem .75rem" }}>
                          m³
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table align-middle gs-0 gy-1 ">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0 w-25">Ingredient per palette</th>
                  <th className="border-bottom-0 w-25">Total palette weight</th>
                  <th className="border-bottom-0 w-25">Stackable</th>
                  <th className="border-bottom-0 w-25">Article type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={packagingDimensionEdit.netWeight}
                        name="netWeight"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={this.handleChangePackagingDimension}
                      />
                      <div className="input-group-append rounded-end">
                        <div className="form-control custom-form-control bg-dark" style={{ padding: ".375rem .75rem" }}>
                          kg
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <div className="input-group">
                      <Input
                        type="number"
                        value={packagingDimensionEdit.grossWeight}
                        name="grossWeight"
                        className="form-control custom-form-control py-0 bg-dark"
                        onBlur={this.handleChangePackagingDimension}
                      />
                      <div className="input-group-append rounded-end">
                        <div className="form-control custom-form-control bg-dark" style={{ padding: ".375rem .75rem" }}>
                          kg
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle">
                    <CustomSelect
                      additionalClasses={"select-default-dark"}
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      value={
                        packagingDimensionEdit.stackable ? { value: "yes", label: "Yes" } : { value: "no", label: "No" }
                      }
                      matchFormControl={true}
                      onChange={this.handleChangeStackable}
                    />
                  </td>
                  <td className="align-middle">
                    <CustomSelect
                      additionalClasses={"select-default-dark"}
                      options={[
                        { value: COMMODITY, label: "Commodities" },
                        { value: FINISHEDPRODUCT, label: "Finished products" },
                        { value: "both", label: "Both" },
                      ]}
                      value={articleType}
                      matchFormControl={true}
                      onChange={this.handleChangeArticleType}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <>
              <div className="row mx-1">
                <div className="col-12 col-md-6 text-white align-self-center">
                  <div className="text-white">Please select for which ingredients the palette is used</div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-check form-switch form-check-custom form-check-solid float-right">
                    <label className="form-check-label text-white mr-1 ml-0">
                      Use this palette for all ingredients
                    </label>
                    <Input
                      className="form-check-input position-static"
                      checked={all}
                      type="checkbox"
                      onClick={this.handleSelectAll}
                    />
                  </div>
                </div>
              </div>
              {articleType.value !== FINISHEDPRODUCT && (
                <CommoditySelectionTable
                  commodities={commodities}
                  commoditiesSelected={commoditiesSelected}
                  commoditiesUsageMap={commoditiesUsageMap}
                  all={all}
                  packagingDimensionEdit={packagingDimensionEdit}
                  supplier={supplier}
                  onSelectCommodity={this.handleSelectCommodity}
                />
              )}
              {articleType.value !== COMMODITY && (
                <FinishedProductSelectionTable
                  packagingDimensionEdit={packagingDimensionEdit}
                  finishedProducts={finishedProducts}
                  finishedProductsSelected={finishedProductsSelected}
                  finishedProductsUsageMap={finishedProductsUsageMap}
                  all={all}
                  supplier={supplier}
                  onSelectFinishedProducts={this.handleSelectFinishedProduct}
                />
              )}
            </>
            <div className="col-12 align-self-center">
              <button
                className="btn btn-text btn-text-muted btn-sm float-right false pr-0"
                onClick={() => onSetPackagingDimensionUpdating()}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

interface CommoditySelectionTableProps {
  packagingDimensionEdit: PackagingDimension;
  commodities: Array<Commodity>;
  commoditiesSelected: Array<string>;
  commoditiesUsageMap: { [id: string]: Array<string> };
  all: boolean;
  supplier: SupplierExtended | SupplierSupplierExtended;
  onSelectCommodity: (commodity: string) => void;
}

class CommoditySelectionTable extends PureComponent<CommoditySelectionTableProps> {
  isDisabled = (commodity: Commodity) => {
    const { packagingDimensionEdit, commoditiesUsageMap } = this.props;
    return isSelectionDisabled(commodity, packagingDimensionEdit, commoditiesUsageMap);
  };

  render() {
    const { commodities, commoditiesSelected, all, onSelectCommodity } = this.props;

    const disabledCommodities = commodities.filter((c) => this.isDisabled(c)).map((c) => c._id.toString());
    const allChecked =
      all ||
      (commodities.length - disabledCommodities.length === commoditiesSelected.length &&
        commoditiesSelected.length !== 0);

    const headerDefinition = [
      {
        title: (
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <Input
              type="checkbox"
              className={"form-check-input " + (all && "disabled")}
              onClick={all ? undefined : () => onSelectCommodity(allChecked ? "none" : "all")}
              checked={allChecked}
              disabled={all || disabledCommodities.length === commodities.length}
            />
          </div>
        ),
        style: { width: "5%" },
      },
      { title: "Commodity", style: { width: "50%" } },
      { title: "HS-Code", style: { width: "15%" } },
      { title: "CAS Number", style: { width: "15%" } },
      { title: "Density", style: { width: "15%" } },
    ];

    return (
      <BaseListing
        headerDefinition={headerDefinition}
        documents={commodities}
        bodyContent={
          <>
            {commodities.map((c) => {
              const disabled = this.isDisabled(c);
              return (
                <tr key={c._id.toString()}>
                  <td className="align-middle">
                    {!all && disabled ? (
                      <Tooltip tooltipText={disabled.toString()}>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <Input type="checkbox" className="form-check-input disabled" checked={false} />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <Input
                          type="checkbox"
                          className={"form-check-input " + (all && "disabled")}
                          onClick={() => (all ? undefined : onSelectCommodity(c._id.toString()))}
                          checked={all || commoditiesSelected.some((cs) => cs === c._id.toString())}
                          disabled={all}
                        />
                      </div>
                    )}
                  </td>
                  <td className="align-middle">
                    <Link
                      className="text-white fs-5 mb-1 custom-link"
                      onClick={(e) => e.stopPropagation()}
                      to={`/commodity/${c._id.toString()}`}
                    >
                      {c.title.en}
                      {c.organic && <i title={"organic"} className="fas fa-leaf text-success ml-2" />}
                    </Link>
                    <div className="text-muted">{c.subtitle.en}</div>
                  </td>
                  <td className="align-middle">
                    <div className="text-muted">{c.hsCode || "Not Available"}</div>
                  </td>
                  <td className="align-middle">
                    <div className="text-muted">{c.casNumber.join(" / ") || "Not Available"}</div>
                  </td>
                  <td className="align-middle">
                    <div className="text-muted">
                      {round(Number(c.density.min), 2)} - {round(Number(c.density.max), 2)} kg/m³
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        }
      />
    );
  }
}

interface FinishedProductSelectionTableProps {
  packagingDimensionEdit: PackagingDimension;
  finishedProducts: Array<FinishedProduct>;
  finishedProductsSelected: Array<string>;
  finishedProductsUsageMap: { [id: string]: Array<string> };
  all: boolean;
  supplier: SupplierExtended | SupplierSupplierExtended;
  onSelectFinishedProducts: (finishedProducts: string) => void;
}

class FinishedProductSelectionTable extends PureComponent<FinishedProductSelectionTableProps> {
  isDisabled = (finishedProduct: FinishedProduct) => {
    const { packagingDimensionEdit, finishedProductsUsageMap } = this.props;
    return isSelectionDisabled(finishedProduct, packagingDimensionEdit, finishedProductsUsageMap);
  };

  render() {
    const { finishedProducts, finishedProductsSelected, all, onSelectFinishedProducts } = this.props;

    const disabledFinishedProducts = finishedProducts
      .filter((fp) => this.isDisabled(fp))
      .map((fp) => fp._id.toString());
    const allChecked =
      all ||
      (finishedProducts.length - disabledFinishedProducts.length === finishedProductsSelected.length &&
        finishedProductsSelected.length !== 0);

    const headerDefinition = [
      {
        title: (
          <div className="form-check form-check-sm form-check-custom form-check-solid">
            <Input
              type="checkbox"
              className={"form-check-input " + (all && "disabled")}
              onClick={all ? undefined : () => onSelectFinishedProducts(allChecked ? "none" : "all")}
              checked={allChecked}
              disabled={all || disabledFinishedProducts.length === finishedProducts.length}
            />
          </div>
        ),
        style: { width: "5%" },
      },
      { title: "Finished Product", style: { width: "50%" } },
      { title: "HS-Code", style: { width: "45%" } },
    ];

    return (
      <BaseListing
        headerDefinition={headerDefinition}
        documents={finishedProducts}
        bodyContent={
          <>
            {finishedProducts.map((fp) => {
              const disabled = this.isDisabled(fp);
              return (
                <tr key={fp._id.toString()}>
                  <td className="align-middle">
                    {!all && disabled ? (
                      <Tooltip tooltipText={disabled.toString()}>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <Input type="checkbox" className="form-check-input disabled" checked={false} />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <Input
                          type="checkbox"
                          className={"form-check-input " + (all && "disabled")}
                          onClick={() => (all ? undefined : onSelectFinishedProducts(fp._id.toString()))}
                          checked={all || finishedProductsSelected.some((fps) => fps === fp._id.toString())}
                          disabled={all}
                        />
                      </div>
                    )}
                  </td>
                  <td className="align-middle">
                    <Link
                      className="text-white fs-5 mb-1 custom-link"
                      onClick={(e) => e.stopPropagation()}
                      to={`/finishedProduct/${fp._id.toString()}`}
                    >
                      {fp.title.en}
                      {fp.organic && <i title={"organic"} className="fas fa-leaf text-success ml-2" />}
                    </Link>
                    <div className="text-muted">{fp.subtitle.en}</div>
                  </td>
                  <td className="align-middle">
                    <div className="text-muted">{fp.hsCode || "Not Available"}</div>
                  </td>
                </tr>
              );
            })}
          </>
        }
      />
    );
  }
}

export default PackagingDimensionEntry;
