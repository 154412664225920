import React from "react";
import { WarehouseValues } from "../../../../model/configuration/calculationConfiguration.types";
import { Input } from "../../../common/Input";

interface WarehouseValuesConfigurationProps {
  warehouseValues: WarehouseValues;
  onChangeWarehouseValues: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const WarehouseValuesConfiguration: React.FunctionComponent<WarehouseValuesConfigurationProps> = ({
  warehouseValues,
  onChangeWarehouseValues,
}) => {
  const { minimumMargin, euStockMinimumMargin } = warehouseValues;
  return (
    <>
      <h3 className="mb-3 mt-7">Base Values</h3>
      <div className="row">
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Minimum Total Margin</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type={"number"}
              name={"minimumMargin.value"}
              min={0}
              value={minimumMargin.value}
              onChange={onChangeWarehouseValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {minimumMargin.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <label className="fs-5 fw-bold mb-2">Minimum Total Margin EU Stock</label>
          <div className="input-group">
            <Input
              className="form-control custom-form-control"
              type="number"
              name="euStockMinimumMargin.value"
              min={0}
              value={euStockMinimumMargin.value}
              onChange={onChangeWarehouseValues}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {euStockMinimumMargin.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseValuesConfiguration;
